const { get, merge, set } = require("lodash");
const {
  ANNUALLY,
  BI_WEEKLY,
  BI_WEEKLY_IOS_KEY,
  HALF_YEARLY,
  INTEREST_CALCULATION_STRATEGY: IC_STRAT,
  MONTHLY,
  QUARTERLY,
  WEEKLY,
  SIMPLE_INTEREST,
} = require("./LoanConstants");

export const INTEREST_DAYS_PER_YEAR = "interestDaysPerYear";
export const ADD_INTEREST_FOR_PARTIAL_DAYS = "addInterestForPartialDays";
export const CALCULATION_STRATEGY_OPTIONS_KEY = "calculationStrategyOptions";
export const CALCULATION_STRATEGY_KEY = "calculationStrategy";
export const CALCULATION_STRATEGY_INNER_KEY = "strategy";
export const ROUND_PARTIAL_DAYS_INTEREST = "roundPartialDaysInterest";

const CSO_KEY = CALCULATION_STRATEGY_OPTIONS_KEY;
const CS_KEY = CALCULATION_STRATEGY_KEY;

const CI_STRAT_365d_WITH_PARTIAL_DAYS = () => ({
  [INTEREST_DAYS_PER_YEAR]: 365,
  [ADD_INTEREST_FOR_PARTIAL_DAYS]: true,
});

const CI_STRAT_365d_WITHOUT_PARTIAL_DAYS = () => ({
  [INTEREST_DAYS_PER_YEAR]: 365,
  [ADD_INTEREST_FOR_PARTIAL_DAYS]: false,
});

const CI_STRAT_360d_WITH_PARTIAL_DAYS = () => ({
  [INTEREST_DAYS_PER_YEAR]: 360,
  [ADD_INTEREST_FOR_PARTIAL_DAYS]: true,
});

const CI_STRAT_360d_WITHOUT_PARTIAL_DAYS = () => ({
  [INTEREST_DAYS_PER_YEAR]: 360,
  [ADD_INTEREST_FOR_PARTIAL_DAYS]: false,
});

export const DEFAULT_CALCULATION_OPTIONS = {
  simpleInterest: {
    [CS_KEY]: IC_STRAT.MONTH_END,
    [CSO_KEY]: {
      [IC_STRAT.DAY_END]: {
        [INTEREST_DAYS_PER_YEAR]: 360,
      },
      [IC_STRAT.MONTH_END]: {
        [INTEREST_DAYS_PER_YEAR]: 360,
        [ADD_INTEREST_FOR_PARTIAL_DAYS]: true,
        [ROUND_PARTIAL_DAYS_INTEREST]: true,
      },
    },
  },
  compoundInterest: {
    [ANNUALLY]: {
      [CS_KEY]: IC_STRAT.INTERVAL_END,
      [CSO_KEY]: {
        [IC_STRAT.INTERVAL_END]: CI_STRAT_360d_WITH_PARTIAL_DAYS(),
        [IC_STRAT.DAY_END]: CI_STRAT_365d_WITH_PARTIAL_DAYS(),
      },
    },

    [HALF_YEARLY]: {
      [CS_KEY]: IC_STRAT.INTERVAL_END,
      [CSO_KEY]: {
        [IC_STRAT.INTERVAL_END]: CI_STRAT_360d_WITH_PARTIAL_DAYS(),
        [IC_STRAT.DAY_END]: CI_STRAT_365d_WITH_PARTIAL_DAYS(),
      },
    },

    [QUARTERLY]: {
      [CS_KEY]: IC_STRAT.INTERVAL_END,
      [CSO_KEY]: {
        [IC_STRAT.INTERVAL_END]: CI_STRAT_360d_WITH_PARTIAL_DAYS(),
        [IC_STRAT.DAY_END]: CI_STRAT_365d_WITH_PARTIAL_DAYS(),
      },
    },

    [MONTHLY]: {
      [CS_KEY]: IC_STRAT.INTERVAL_END,
      [CSO_KEY]: {
        [IC_STRAT.INTERVAL_END]: CI_STRAT_360d_WITH_PARTIAL_DAYS(),
        [IC_STRAT.DAY_END]: CI_STRAT_365d_WITHOUT_PARTIAL_DAYS(),
      },
    },

    //IMPORTANT: Change both the keys below for bi-weekly if we need to change
    // Use without partial as default for bi-weekly & below
    [BI_WEEKLY]: {
      [CS_KEY]: IC_STRAT.INTERVAL_END,
      [CSO_KEY]: {
        [IC_STRAT.INTERVAL_END]: CI_STRAT_360d_WITHOUT_PARTIAL_DAYS(),
        [IC_STRAT.DAY_END]: CI_STRAT_365d_WITHOUT_PARTIAL_DAYS(),
      },
    },
    [BI_WEEKLY_IOS_KEY]: {
      [CS_KEY]: IC_STRAT.INTERVAL_END,
      [CSO_KEY]: {
        [IC_STRAT.INTERVAL_END]: CI_STRAT_360d_WITHOUT_PARTIAL_DAYS(),
        [IC_STRAT.DAY_END]: CI_STRAT_365d_WITHOUT_PARTIAL_DAYS(),
      },
    },

    [WEEKLY]: {
      [CS_KEY]: IC_STRAT.INTERVAL_END,
      [CSO_KEY]: {
        [IC_STRAT.INTERVAL_END]: CI_STRAT_360d_WITHOUT_PARTIAL_DAYS(),
        [IC_STRAT.DAY_END]: CI_STRAT_365d_WITHOUT_PARTIAL_DAYS(),
      },
    },
  },
};

/**
 * Compound interest calculation options before supporting the
 * options based on compounding interval
 */
export const COMPOUND_INTEREST_V1_CALCULATION_OPTIONS = () => ({
  [CS_KEY]: IC_STRAT.DAY_END,
  [CSO_KEY]: {
    [IC_STRAT.DAY_END]: CI_STRAT_365d_WITHOUT_PARTIAL_DAYS(),
  },
});

/**
 * Default v2 compound interest calculation options for the given compounding interval
 */
export const COMPOUND_INTEREST_V2_DEFAULT_CALCULATION_OPTIONS = ({
  compoundingInterval,
}) =>
  get(DEFAULT_CALCULATION_OPTIONS, `compoundInterest.${compoundingInterval}`);

const interestCalculationStrategyNames = {
  [IC_STRAT.DAY_END]: "Days based interest",
  [IC_STRAT.MONTH_END]: "Month based interest",
};

export const interestCalculationStrategyName = (key) =>
  interestCalculationStrategyNames[key];

const COMPOUNDING_INTERVAL_TEXTS_BI_WEEKLY = () => ({
  title: "Bi-Weekly",
  calculationOptionKeys: [IC_STRAT.INTERVAL_END],
});

const COMPOUNDING_INTERVAL_TEXTS_YEARLY = () => ({
  title: "Annually",
  calculationOptionKeys: compoundingInterestCalculationOptionsBothKeys,
});

const compoundingInterestCalculationOptionsBothKeys = [
  IC_STRAT.INTERVAL_END,
  IC_STRAT.DAY_END,
];

/**
 * Map of supported calculation option keys & title for all the supported
 * compounding intervals
 */
export const COMPOUNDING_INTERVAL_TEXTS = () => ({
  [WEEKLY]: {
    title: "Weekly",
    calculationOptionKeys: [IC_STRAT.INTERVAL_END],
  },

  [BI_WEEKLY]: COMPOUNDING_INTERVAL_TEXTS_BI_WEEKLY(),
  [BI_WEEKLY_IOS_KEY]: COMPOUNDING_INTERVAL_TEXTS_BI_WEEKLY(),

  [MONTHLY]: {
    title: "Monthly",
    calculationOptionKeys: compoundingInterestCalculationOptionsBothKeys,
  },

  [QUARTERLY]: {
    title: "Quarterly",
    calculationOptionKeys: compoundingInterestCalculationOptionsBothKeys,
  },

  [HALF_YEARLY]: {
    title: "Half Yearly",
    calculationOptionKeys: compoundingInterestCalculationOptionsBothKeys,
  },

  [ANNUALLY]: COMPOUNDING_INTERVAL_TEXTS_YEARLY(),
});

export const SIMPLE_INTEREST_CALCULATION_OPTIONS = () => [
  {
    title: "Simple Interest Calculation Strategy",
    calculationOptions: [
      {
        title: "Month based interest",
        subtitle:
          "Interest is calculated at the end of the month considering month as a unit i.e. 2% monthly interest on 1000 results in 20",
        value: IC_STRAT.MONTH_END,
      },
      {
        title: "Days based interest",
        subtitle:
          "Interest is calculated based on the number of days between the payment date and the last payment date i.e. month with lesser number of days ends up accruing lesser interest than expected",
        value: IC_STRAT.DAY_END,
      },
    ],
  },
];

const compoundIntervalDescribersData = () => ({
  // The common options to be used alongside all the interval based options
  common: {
    calcOptionDayEndTitle: "Day wise",
    calcOptionDayEndSubtitle: "Day wise",
  },

  [WEEKLY]: {
    calcOptionIntervalEndTitle: "End of week",
    calcOptionIntervalEndSubtitle: "End of week",
  },

  [BI_WEEKLY]: {
    calcOptionIntervalEndTitle: "End of every 2 weeks",
    calcOptionIntervalEndSubtitle: "End of every 2 weeks",
  },
  [BI_WEEKLY_IOS_KEY]: {
    calcOptionIntervalEndTitle: "End of every 2 weeks",
    calcOptionIntervalEndSubtitle: "End of every 2 weeks",
  },

  [MONTHLY]: {
    calcOptionIntervalEndTitle: "End of month",
    calcOptionIntervalEndSubtitle: "End of month",
  },
  [QUARTERLY]: {
    calcOptionIntervalEndTitle: "End of every 3 months",
    calcOptionIntervalEndSubtitle: "End of every 3 months",
  },
  [HALF_YEARLY]: {
    calcOptionIntervalEndTitle: "End of every 6 months",
    calcOptionIntervalEndSubtitle: "End of every 6 months",
  },

  [ANNUALLY]: {
    calcOptionIntervalEndTitle: "End of year",
    calcOptionIntervalEndSubtitle: "End of year",
  },
});

export const compoundIntervalDescriber = ({ compoundingInterval }) => {
  const describersData = compoundIntervalDescribersData();

  return merge({}, describersData.common, describersData[compoundingInterval]);
};

const roundPartialDaysInterestPath = ({ interestType, interval, tenure }) =>
  `${interestType}.${
    tenure ? `${tenure}.` : ""
  }${CSO_KEY}.${interval}.${ROUND_PARTIAL_DAYS_INTEREST}`;

const interestDaysPerYearPath = ({ interestType, interval, tenure }) =>
  `${interestType}.${
    tenure ? `${tenure}.` : ""
  }${CSO_KEY}.${interval}.${INTEREST_DAYS_PER_YEAR}`;

export const mergeCalculationOptions = ({
  oldOptions = {},
  newOptions = {},
}) => {
  let mergedOptions = merge({}, oldOptions, newOptions);

  /**
   * Forcefully set interestDaysPerYear to 360, when user didn't have roundPartialDaysInterest key in DB
   */

  const roundInterestPath = roundPartialDaysInterestPath({
    interestType: SIMPLE_INTEREST,
    interval: IC_STRAT.MONTH_END,
  });

  if (
    get(newOptions, roundInterestPath) === undefined ||
    get(newOptions, roundInterestPath)
  ) {
    const daysPerYearPath = interestDaysPerYearPath({
      interestType: SIMPLE_INTEREST,
      interval: IC_STRAT.MONTH_END,
    });

    set(mergedOptions, daysPerYearPath, 360);
  }

  return mergedOptions;
};
