import { floor } from "lodash";
import React from "react";
import "./index.css";

export default ({ loan }) => {
	const clearedPercentage =
		loan.loanStatus === "Closed"
			? 100
			: floor(
					(loan.amountPaid * 100) / (loan.amountPaid + loan.totalRemaining)
			  );

	return (
		<div className="repayment-progress">
			<div className="title">Debt Repayment Progress</div>
			<div
				className={
					loan.transectionType === "Lent"
						? "progress-bar-background-lent"
						: "progress-bar-background-owes"
				}
			></div>
			<div
				className={"progress-bar"}
				style={{ width: `${clearedPercentage}%` }}
			></div>
			<div className="literal-percentages">
				<div>
					<span className="description">Cleared</span> {clearedPercentage}%
				</div>
				<div>
					<span className="description">Remaining</span>{" "}
					{100 - clearedPercentage}%
				</div>
			</div>
		</div>
	);
};
