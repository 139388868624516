export const STATISTICS = "/statistics";
export const MIGRATION = "/migration";
export const LANDING = "/landing";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/login";
export const HOME = "/home";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const GENERATE_PDF = "/generate-pdf";
export const V3_GENERATE_PDF = "/v3/generate-pdf";
export const SHARED_LOAN = "/i";
export const V3_SHARED_LOAN = "/v3/i";

export const GENERATE_PAYOFF_PLANNER_PDF = "/generate-payoff-planner-pdf";
export const GENERATE_COLLECTION_LIST = "/collection-list";

export const V3_GENERATE_PAYOFF_PLANNER_PDF = "/v3/generate-payoff-planner-pdf";
export const V3_GENERATE_COLLECTION_LIST = "/v3/collection-list";
