import { get, isEmpty, values } from "lodash";

import React from "react";
import moment from "moment";

const hasProfessionalSubscription = ({ userDetails }) => {
  /**
   * premium_subscription_1_monthly
   * premium_subscription_1_yearly
   */
  const hasValidData = (productId) => {
    const productInfo = get(userDetails, `iaps.products.${productId}`);
    return (
      !isEmpty(productInfo) &&
      moment(get(productInfo, "expiresAt", 0)).isSameOrAfter(moment())
    );
  };

  const result =
    hasValidData("premium_subscription_1_monthly") ||
    hasValidData("premium_subscription_1_yearly") ||
    hasValidData("prod_professional_yearly_team_micro") ||
    hasValidData("prod_professional_yearly_team_small") ||
    hasValidData("prod_professional_yearly_team_medium") ||
    hasValidData("prod_professional_yearly_team_octave") ||
    hasValidData("prod_professional_yearly_team_large") ||
    hasValidData("rc_promo_professional_yearly") ||
    hasValidData("rc_promo_professional_weekly");

  return result;
};

const ContentField = ({ image, href, title, isShownInRow }) => {
  return (
    <div className="org-mobile-number-container">
      <a
        className={
          isShownInRow ? "org-mobile-number" : "org-mobile-number-column"
        }
        href={href}
      >
        <img className="org-mobile-number-img" src={image} alt="" />
        {title}
      </a>
    </div>
  );
};

export const ReportHeader = ({ user, organization }) => {
  const shouldShowCustomHeader =
    hasProfessionalSubscription({ userDetails: user }) &&
    !isEmpty(organization?.name);

  const headerData = (() => {
    const name = organization?.name || user?.name;
    const logoURL = organization?.logoURL;
    const address = values(organization?.addresses)?.[0]?.addressString;
    const email = values(organization?.emails)?.[0]?.email;
    const mobileNumber = values(organization?.mobileNumbers)?.[0]?.mobileNumber;

    return {
      name,
      logoURL,
      address,
      email,
      mobileNumber,
    };
  })();

  const { name, logoURL, address = "", email, mobileNumber } = headerData;
  const shouldShowSecondRow = !!address || !!email || !!mobileNumber;

  return (
    <div className="header">
      {!shouldShowCustomHeader && (
        <div
          className={!shouldShowCustomHeader ? "logo-freebird" : "logo"}
        ></div>
      )}
      {shouldShowCustomHeader && (
        <div className="custom-header-container">
          <div className="row">
            {!!logoURL && <img className="org-logo" src={logoURL} alt="" />}
            {!!name && <div className="org-name">{name}</div>}
          </div>
          {shouldShowSecondRow && (
            <div className="spaced-out-row">
              {!!address && <div className="org-address">{address}</div>}
              <div
                className={
                  !address
                    ? "org-email-mobile-container-row"
                    : "org-email-mobile-container-column"
                }
              >
                {!!mobileNumber && (
                  <ContentField
                    image={require("../../assets/mobile-number.png")}
                    href={`tel:${mobileNumber}`}
                    title={mobileNumber}
                    isShownInRow={!address}
                  />
                )}
                {!!email && (
                  <ContentField
                    image={require("../../assets/email-open.png")}
                    href={`mailto:${email}`}
                    title={email}
                    isShownInRow={!address}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
