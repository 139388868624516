import "../../styles/payoffPlanner/index.css";

import React, { useEffect, useState } from "react";

// import Cookies from "js-cookie";
import { PayoffPlanMonthTable } from "./PayoffPlanMonthTable";
import { ReportHeader } from "../ReportHeader";
import axios from "axios";
import { getPayoffPlannerExportDataURLv3 } from "../../env";
import { map } from "lodash";
import qs from "query-string";

export const V3PayoffPlannerPDF = () => {
  const [data, setData] = useState({
    isLoaded: false,
    result: {},
    error: null,
  });

  const params = qs.parseUrl(window.location.search).query;

  useEffect(() => {
    const userId = params.userId;
    const organizationId = params.organizationId;
    let timeZone = params.timeZone;
    let format = params.format;

    const url = `${getPayoffPlannerExportDataURLv3({
      organizationId,
      format,
    })}`;

    axios
      .get(url, {
        params: {
          organizationId,
          format,
          timeZone,
        },
      })
      .then((r) => {
        setData({
          isLoaded: true,
          result: r.data?.data || {},
          error: null,
        });
      })
      .catch((err) => {
        setData({
          isLoaded: true,
          error: err.message,
          result: {},
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data.isLoaded) {
    return <div></div>;
  }

  const { plan, payments = [] } = data.result;
  //JSON.parse(Cookies.get("localData") || {});

  const ContentView = () => {
    return plan ? (
      <>
        <ReportHeader />
        <div>
          <h2 className="payoffPlanDate">
            You will be debt free by {plan.payoffPlan.payoffDateString}
          </h2>
          {map(payments, (section) => (
            <PayoffPlanMonthTable section={section} />
          ))}
        </div>
      </>
    ) : null;
  };

  const ErrorView = () => <div className="errorText">{data.error}</div>;

  return (
    <div id="export-content">
      {data.error ? <ErrorView /> : <ContentView />}
    </div>
  );
};
