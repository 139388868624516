import "./index.css";

import React from "react";
import { floor } from "lodash";

export default ({ loan, userId }) => {
  const clearedPercentage =
    loan.loanStatus === "Closed"
      ? 100
      : floor(
          (loan.amountPaid * 100) / (loan.amountPaid + loan.totalRemaining)
        );

  console.log({ userId });

  return (
    <div className="repayment-progress">
      <div className="title">Debt Repayment Progress</div>
      <div
        className={
          loan.transactionType === "Lent"
            ? "progress-bar-background-lent"
            : "progress-bar-background-owes"
        }
      ></div>
      <div
        className={"progress-bar"}
        style={{ width: `${clearedPercentage}%` }}
      ></div>
      <div className="literal-percentages">
        <div>
          <span className="description">Cleared</span> {clearedPercentage}%
        </div>
        {userId === "c5UpjiokQNSMgcJUMCnBo8luhfz1" ? null : (
          <div>
            <span className="description">Remaining</span>{" "}
            {100 - clearedPercentage}%
          </div>
        )}
      </div>
    </div>
  );
};
