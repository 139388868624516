import "./index.css";

import { BOLD, REGULAR } from "../../constants/fonts";
import {
  DynamicStyleSheet,
  DynamicValue,
  Image,
  Text,
  View,
  useDarkMode,
  useDynamicStyleSheet,
} from "../react-native/imports";
import {
  LENT,
  LIGHT_GREY_SUBTEXT_COLOR,
  LIGHT_GREY_TEXT_COLOR,
  MAIN_TEXT_DARK,
  OWES,
  SHADOW_BLACK50,
  VERMILLION_COLOR,
  WHITE,
} from "../../constants/colors";
import LoansScreenStyles, {
  default as generalStyles,
} from "../../styles/LoansScreenStyles";
import { find, get, isEmpty, round, values } from "lodash";

import { COMPOUND_INTEREST } from "../../utils/LoanUtils";
import { DD_SP_MMM_SP_YYYY } from "../../constants/dateFormats";
import PlaceholderView from "./PlaceholderView";
import { ProgressBar } from "./ProgressBar";
import React from "react";
import { format } from "../../utils/CurrencyFormatter";
import moment from "moment";
import transactionStyles from "../../styles/transactionStyle";

export const MONTHLY_INTEREST_MODE = "MONTHLY_INT_MODE";

const dynamicStyles = new DynamicStyleSheet({
  ...generalStyles,
  ...transactionStyles,
  ...LoansScreenStyles,
  notes: {
    fontSize: 12,
    fontFamily: REGULAR,
    color: LIGHT_GREY_SUBTEXT_COLOR,
    marginTop: 5,
    marginBottom: 5,
  },
  principalBalanceStyle: {
    letterSpacing: 0.82,
    fontSize: 17,
    fontFamily: REGULAR,
    fontWeight: 500,
    marginLeft: 4,
  },
  pinView: {
    marginRight: 5,
    height: 30,
    width: 30,
    marginLeft: 10,
  },
  subTitle: {
    fontSize: 11,
    fontFamily: REGULAR,
    color: new DynamicValue(LIGHT_GREY_TEXT_COLOR, MAIN_TEXT_DARK),
    padding: 2,
  },
  textView: {
    justifyContent: "center",
    flex: 1,
  },
  imagePlaceholderText: {
    color: WHITE,
    fontFamily: REGULAR,
    fontWeight: 500,
    fontSize: 25,
  },
  shareePlaceholderText: {
    color: WHITE,
    fontFamily: REGULAR,
    fontWeight: 500,
    fontSize: 15,
  },
  shareDownArrowIcon: {
    width: 14,
    height: 11,
    marginTop: 7,
    marginLeft: 16,
  },
  button: {
    padding: 10,
    paddingBottom: 11,
  },
  badLoanTextStyles: {
    fontFamily: REGULAR,
    fontSize: 13,
    color: SHADOW_BLACK50,
  },
});

const getTimePeriod = (loan) => {
  let dueDate = loan.dueDate;
  if (loan.recurringPaymentInfo && loan.recurringPaymentInfo.schedule) {
    let firstItem = find(
      loan.recurringPaymentInfo.schedule,
      (schedule) => schedule.status === "None"
    );
    if (firstItem) {
      dueDate = firstItem.paidOn;
    }
  }

  let noDays = moment(dueDate * 1000)
    .startOf("day")
    .diff(moment().startOf("day"), "days");
  let noOfDaysText = "";
  if (noDays === 0) {
    noOfDaysText = "today";
  } else if (Math.abs(noDays) === 1) {
    noOfDaysText = noDays < 0 ? "yesterday" : "tomorrow";
  } else if (noDays <= 30) {
    if (noDays < 0) {
      if (Math.abs(noDays) > 30) {
        noOfDaysText = `on ${moment(dueDate * 1000).format(DD_SP_MMM_SP_YYYY)}`;
      } else {
        noOfDaysText = `${Math.abs(noDays)} days ago`;
      }
    } else {
      noOfDaysText =
        noDays < 0 ? `${Math.abs(noDays)} days ago` : `in ${noDays} days`;
    }
  } else {
    noOfDaysText = `on ${moment(dueDate * 1000).format(DD_SP_MMM_SP_YYYY)}`;
  }
  return { noOfDaysText, overDued: noDays < 0 };
};

export default ({ loan, buddy, numberOfLines = 0, mode, globalCurrency }) => {
  const styles = useDynamicStyleSheet(dynamicStyles);
  const { transaction, itemType } = loan;
  const isSharedLoan = transaction.isSharedLoan;
  const isDarkMode = useDarkMode();
  const date = moment(loan.date * 1000).format("Do MMM");
  const year = moment(loan.date * 1000).format(isSharedLoan ? "YY" : "YYYY");

  const {
    preparedData: {
      loanProgressPercent,
      loanTotalOutstandingAmount,
      isLoanTransactionTypeOwe,
      nearestLoanAmount,
    } = {},
  } = transaction;

  let transactionTypeOWE = isLoanTransactionTypeOwe;
  const fromLoanDetails = true;
  const fromShareeModal = true;
  const isThirdParty = true;

  const shouldSwapNames =
    transactionTypeOWE && (isThirdParty || fromShareeModal);

  const friendName = isSharedLoan
    ? get(transaction, "owner.name", "Unknown Friend")
    : get(transaction, "friend.name", "Unknown Friend");
  // (transaction && transaction.friend && transaction.friend.name) ||
  // 'Unknown Friend';
  const shareeName = get(
    buddy,
    "displayName",
    get(transaction, "friend.name", "Unknown Friend")
  );

  const fromMonthlyInterest = mode === MONTHLY_INTEREST_MODE;
  const headerText =
    itemType === "loans"
      ? transactionTypeOWE
        ? "You took a loan "
        : "You gave a loan"
      : shouldSwapNames
      ? shareeName
      : friendName;
  const subTitleText =
    transaction.loanStatus === "Closed"
      ? "Settled on "
      : isSharedLoan
      ? "Loan duration: "
      : transactionTypeOWE
      ? "Taken on "
      : "Given on ";

  const hasSchedules =
    transaction.loanStatus === "Pending" &&
    transaction.recurringPaymentInfo &&
    transaction.recurringPaymentInfo.schedule &&
    transaction.recurringPaymentInfo.schedule.length > 0 &&
    find(
      transaction.recurringPaymentInfo.schedule,
      (item) => item.status === "None"
    ) !== undefined;

  const getFirstitem = () => {
    return hasSchedules
      ? find(
          transaction.recurringPaymentInfo.schedule,
          (item) => item.status === "None"
        )
      : undefined;
  };
  const transactionTypeText = () => {
    return getTimePeriod(transaction).overDued ? "went past due" : "is due";
  };

  const subText = () => {
    return fromMonthlyInterest
      ? `${format(round(reminderToCollectAmount, 2), transaction.currency)} ${
          transaction.interestMode === COMPOUND_INTEREST && !hasSchedules
            ? "(approx.)"
            : ""
        }`
      : hasSchedules
      ? `${format(
          reminderToCollectAmount,
          transaction.dueDateLoansAggregationCurrency
        )} ${transactionTypeText()}`
      : `${format(
          reminderToCollectAmount,
          transaction.dueDateLoansAggregationCurrency
        )} ${transactionTypeText()}`;
  };

  const reminderToCollectText = fromMonthlyInterest
    ? "This Month Interest: "
    : hasSchedules
    ? "EMI of"
    : transaction.dueDate
    ? !transactionTypeOWE
      ? "Collecting of"
      : "Paying of"
    : undefined;

  const reminderToCollectAmount = fromMonthlyInterest
    ? transaction.monthlyInterest
    : hasSchedules
    ? getFirstitem().amount
    : transaction.dueDate
    ? nearestLoanAmount
    : undefined;

  const isBadLoan = !isEmpty(transaction.badLoanInfo);

  const userData = (
    <View style={{ ...styles.userDataBaseStyle }}>
      {fromShareeModal ? (
        <View style={{ marginRight: 28 }}>
          <PlaceholderView
            containerStyle={styles.shareeImageStyle}
            friendName={shouldSwapNames ? shareeName : friendName}
            placeholderTextStyle={styles.shareePlaceholderText}
            transactionTypeOWE={transactionTypeOWE}
          />
          <Image
            source={require("../../assets/shareeDownArrow.png")}
            style={styles.shareDownArrowIcon}
          />
          <PlaceholderView
            containerStyle={{
              ...styles.shareeImageStyle,
              ...{ marginLeft: 32, marginTop: -10 },
            }}
            friendName={shouldSwapNames ? friendName : shareeName}
            placeholderTextStyle={styles.shareePlaceholderText}
            transactionTypeOWE={transactionTypeOWE}
          />
        </View>
      ) : (
        <PlaceholderView
          containerStyle={
            itemType === "loans" ? styles.imageView : styles.imageBaseViewStyle
          }
          friendName={friendName}
          placeholderTextStyle={styles.imagePlaceholderText}
          image={
            itemType === "loans"
              ? transactionTypeOWE
                ? require("../../assets/loanTaking.png")
                : require("../../assets/loanGiving.png")
              : loan.profileImageUrl !== undefined
              ? undefined //need to pass url
              : undefined
          }
          isBackgroundTransparent={itemType === "loans"}
          transactionTypeOWE={transactionTypeOWE}
        />
      )}

      <View
        style={{
          ...styles.dataViewStyle,
          ...{
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: itemType !== "loans" ? -15 : 15,
          },
        }}
      >
        <View style={styles.textView}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={
                itemType !== "loans"
                  ? styles.nameTextStyles
                  : styles.loanNameTextStyles
              }
              numberOfLines={1}
            >
              {itemType === "loans"
                ? `${headerText}`
                : `${shouldSwapNames ? shareeName : friendName}`}
            </Text>
            {fromShareeModal && (
              <>
                <Image
                  style={{
                    transform: [{ rotate: "180deg" }],
                    marginLeft: 12,
                    marginRight: 12,
                    width: 15,
                    height: 13,
                    resizeMode: "stretch",
                  }}
                  className="rotatedImage"
                  source={
                    isDarkMode
                      ? require("../../assets/back-white.png")
                      : require("../../assets/back-black.png")
                  }
                />
                <Text style={styles.nameTextStyles}>
                  {shouldSwapNames ? friendName : shareeName}
                </Text>
              </>
            )}
          </View>
          {subTitleText !== null ? (
            <Text
              style={styles.subTitle}
              numberOfLines={1}
            >{`${subTitleText} ${date}, ${year}${
              isSharedLoan && transaction.loanStatus !== "Closed"
                ? ` - ${
                    !!transaction.dueDate
                      ? `${moment(transaction.dueDate * 1000).format(
                          "Do MMM"
                        )}, ${moment(transaction.dueDate * 1000).format("YY")}`
                      : "NA"
                  }`
                : ""
            }`}</Text>
          ) : null}
        </View>
        {isBadLoan && (
          <View style={styles.badLoanView}>
            <Text style={styles.badLoanText}>Bad Loan</Text>
          </View>
        )}
        {!fromLoanDetails && !fromShareeModal && transaction.isSharedLoan && (
          <Image
            source={
              isDarkMode
                ? require("../../assets/shareLoan-white.png")
                : require("../../assets/shareLoan-black.png")
            }
          />
        )}
      </View>
    </View>
  );
  const progressbar =
    //NOTE: % is calculating on cumulative amounts.
    transaction.loanStatus === "Pending" && mode !== "fromLoanDetails" ? (
      <ProgressBar
        width={Math.max(loanProgressPercent, 2)}
        isTintOwes={transactionTypeOWE}
        isBadLoan={isBadLoan}
      />
    ) : null;

  const priceData = (
    <View
      style={{
        ...styles.priceDataBaseStyle,
        ...{ marginTop: 5, marginBottom: 5 },
      }}
    >
      <View
        style={{
          ...styles.principleBaseViewStyle,
          ...{ marginTop: 5, marginBottom: 5 },
        }}
      >
        <Text style={styles.principleTextStyle}>Amount Due :</Text>
        {transaction.principalAmount !== loanTotalOutstandingAmount ||
        transaction.loanStatus === "Closed" ? (
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                ...styles.loanPrincipleAmtTextStyle,
                ...{
                  color:
                    isBadLoan &&
                    values(get(transaction, "paidInfo", {})).length === 0
                      ? VERMILLION_COLOR
                      : !transactionTypeOWE
                      ? LENT
                      : OWES,
                },
                fontSize: isBadLoan ? 15 : 17,
                fontFamily: isBadLoan ? REGULAR : BOLD,
                alignSelf: "center",
              }}
            >
              {isBadLoan &&
              values(get(transaction, "paidInfo", [])).length === 0
                ? format(
                    transaction.badLoanInfo.amount,
                    transaction.currency || globalCurrency
                  )
                : format(transaction.principalAmount, transaction.currency)}
            </Text>
            {isBadLoan && (
              <Text
                style={{
                  ...styles.loanPrincipleAmtTextStyle,
                  color: VERMILLION_COLOR,
                  textDecorationLine:
                    values(get(transaction, "paidInfo", {})).length === 0
                      ? "none"
                      : "line-through",
                }}
              >
                {values(get(transaction, "paidInfo", {})).length === 0
                  ? "BAD LOAN"
                  : format(
                      transaction.badLoanInfo.amount,
                      transaction.currency || globalCurrency
                    )}
              </Text>
            )}
            {!isBadLoan && (
              <Text
                style={{
                  ...styles.principalBalanceStyle,
                  ...{
                    color: !transactionTypeOWE ? LENT : OWES,
                  },
                }}
              >
                {transaction.loanStatus === "Closed"
                  ? "SETTLED"
                  : format(
                      round(loanTotalOutstandingAmount, 2),
                      transaction.currency || globalCurrency
                    )}
              </Text>
            )}
          </View>
        ) : (
          <Text
            style={{
              ...styles.principleAmtTextStyle,
              color: !transactionTypeOWE ? LENT : OWES,
            }}
          >
            {format(
              round(loanTotalOutstandingAmount, 2),
              transaction.currency || globalCurrency
            )}
          </Text>
        )}
      </View>
      {isSharedLoan && fromLoanDetails && (
        <Text style={styles.remainderTextStyle}>
          <Image
            source={
              isDarkMode
                ? require("../../assets/shareLoan-white.png")
                : require("../../assets/shareLoan-black.png")
            }
          />
          {"  "}
          You have view access to this loan
        </Text>
      )}
    </View>
  );
  return (
    <View style={styles.containerStyle}>
      {userData}
      {priceData}
      {progressbar}
    </View>
  );
};
