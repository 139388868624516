import {
  filter,
  find,
  forEach,
  get,
  groupBy,
  join,
  keys,
  map,
  min,
  orderBy,
  reduce,
  sum,
  sumBy,
  trim,
  values,
  isEmpty,
} from "lodash";
import moment from "moment";
import * as LF from "../api/firebase/loanFields";
import {
  TRANSACTION_TYPE_LENT,
  TRANSACTION_TYPE_OWES,
  SECOND_PARTY,
} from "../constants/LoanConstants";
import { getPercent, totalOutstandingAmount } from "../utils/LoanUtils";
import {
  convertToAppLevelCurrency,
  getConversionConfig,
} from "./CurrencyConverter";
import { format } from "./CurrencyFormatter";
import { calculateInterest } from "./InterestCalculators";

const nextPaymentDate = (loan, condition) => {
  if (loan.recurringPaymentInfo && loan.recurringPaymentInfo.schedule) {
    const schedules = filter(
      loan.recurringPaymentInfo.schedule,
      (sch) => sch.status === "None" && condition(sch.paidOn)
    );

    if (schedules.length > 0) {
      return schedules[0].paidOn;
    }
  } else {
    return condition(loan.dueDate) ? loan.dueDate : undefined;
  }

  return;
};

export const getIsBadLoan = (badLoanInfo, isSharedLoan) =>
  !isSharedLoan && !isEmpty(badLoanInfo);
export const getLoanStatus = (loanStatus, badLoanInfo, isSharedLoan) =>
  loanStatus === LF.LOAN_STATUS.DELETED
    ? LF.LOAN_STATUS.DELETED
    : isSharedLoan && !isEmpty(badLoanInfo)
    ? LF.LOAN_STATUS.PENDING
    : loanStatus;

export const getTotalBadLoanAmount = (loans, shouldConvert) => {
  return reduce(
    loans,
    (total, loan) => {
      if (!!loan.badLoanInfo) {
        return (
          total +
          (!shouldConvert
            ? loan.badLoanInfo.amount
            : convertToAppLevelCurrency(loan.badLoanInfo.amount, loan.currency))
        );
      }
      return total;
    },
    0
  );
};

export const isTransactionTypeOwe = (transactionType, isSharedLoan, mode) => {
  return isSharedLoan && mode === SECOND_PARTY
    ? transactionType !== TRANSACTION_TYPE_OWES
    : transactionType === TRANSACTION_TYPE_OWES;
};

export const prepareGroupedData = (loans) => {
  let groupedLoans = groupBy(loans, (loan) =>
    trim(
      loan.isSharedLoan
        ? get(loan, "owner.name", "Unknown")
        : get(loan, "friend.name", "Unknown")
    )
  );

  let data = {};

  forEach(keys(groupedLoans), (key) => {
    let userLoans = groupedLoans[key];

    const dueDateLoansByCondition = (condition) => {
      return orderBy(
        filter(userLoans, (loan) => {
          return !!nextPaymentDate(loan, condition);
        }),
        (loan) => nextPaymentDate(loan, condition),
        "asc"
      );
    };

    let condition = (date) =>
      !!date && moment(date * 1000).isSameOrAfter(moment(), "day");
    let dueDateLoans = dueDateLoansByCondition(condition);

    // If future loans empty, use the past loans' first due date item
    if (dueDateLoans.length === 0) {
      condition = (date) =>
        !!date && moment(date * 1000).isBefore(moment(), "day");
      dueDateLoans = dueDateLoansByCondition(condition);
    }

    const { shouldConvert, aggregationCurrency } = getConversionConfig(
      userLoans
    );

    let nearestLoanAmount = 0;
    let dueDate;
    let nearestLoan = {};
    let pendingLoans = [];
    let totalLentLoans = [];
    let totalOwesLoans = [];

    const netAmount = sumBy(userLoans, (loan) => {
      let amount =
        loan.loanStatus === LF.LOAN_STATUS.CLOSED
          ? 0
          : totalOutstandingAmount(loan);
      let multiplier = !isTransactionTypeOwe(
        loan.transectionType,
        loan.isSharedLoan
      )
        ? 1
        : -1;
      amount = amount * multiplier;
      return !shouldConvert
        ? amount
        : convertToAppLevelCurrency(amount, loan.currency);
    });

    let dueDateLoansAggregationCurrency;

    if (dueDateLoans.length !== 0) {
      let sameDayLoans = filter(
        dueDateLoans,
        (loan) =>
          nextPaymentDate(loan, condition) ===
          nextPaymentDate(dueDateLoans[0], condition)
      );

      // Give precedence to taken loans
      let sameDayDueDateLoans = filter(sameDayLoans, (loan) =>
        isTransactionTypeOwe(loan.transectionType, loan.isSharedLoan)
      );

      if (sameDayDueDateLoans.length === 0) {
        sameDayDueDateLoans = sameDayLoans;
      }

      nearestLoan = sameDayDueDateLoans[0];

      const {
        shouldConvert: shouldConvertDueDateLoans,
        aggregationCurrency: duesAggregatedCurrency,
      } = getConversionConfig(sameDayDueDateLoans);

      dueDateLoansAggregationCurrency = duesAggregatedCurrency;

      forEach(sameDayDueDateLoans, (loan) => {
        if (
          !loan.recurringPaymentInfo ||
          !loan.recurringPaymentInfo.schedule ||
          loan.recurringPaymentInfo.schedule.length === 0
        ) {
          const calculatedInterest = calculateInterest(loan);
          nearestLoanAmount += !shouldConvertDueDateLoans
            ? calculatedInterest || 0.0
            : convertToAppLevelCurrency(
                calculatedInterest || 0.0,
                loan.currency
              );
        } else {
          // Add the nearest payment amount in case of RFL
          const nearestPaymentDate = nextPaymentDate(loan, condition);
          const desiredPayment = find(
            loan.recurringPaymentInfo.schedule,
            (sch) => sch.paidOn === nearestPaymentDate
          );

          nearestLoanAmount += desiredPayment ? desiredPayment.amount || 0 : 0;
        }
      });

      dueDate = min(
        map(
          dueDateLoans,
          (loan) => nextPaymentDate(loan, condition) || Number.MAX_SAFE_INTEGER
        )
      );
      dueDate = dueDate === Number.MAX_SAFE_INTEGER ? undefined : `${dueDate}`;
    }

    totalLentLoans = filter(
      userLoans,
      (loan) => !isTransactionTypeOwe(loan.transectionType, loan.isSharedLoan)
    );
    totalOwesLoans = filter(userLoans, (loan) =>
      isTransactionTypeOwe(loan.transectionType, loan.isSharedLoan)
    );

    pendingLoans = filter(
      userLoans,
      (loan) => loan.loanStatus === LF.LOAN_STATUS.PENDING
    );

    const { settleUps = {} } = {};

    const totalAmountPaid =
      pendingLoans.length === 0
        ? reduce(
            userLoans,
            (total, loan) => {
              const perLoanTotal =
                total +
                reduce(
                  loan.paidInfo || [],
                  (totalPaid, payment) => {
                    return totalPaid + payment.amount;
                  },
                  0
                ) +
                (loan.settleUpId && settleUps[loan.settleUpId]
                  ? settleUps[loan.settleUpId].transactions[loan.transactionId]
                      .principleBalance +
                    settleUps[loan.settleUpId].transactions[loan.transactionId]
                      .interestRemaining
                  : 0);
              return !shouldConvert
                ? perLoanTotal
                : convertToAppLevelCurrency(perLoanTotal, loan.currency);
            },
            0
          )
        : 0;

    const {
      shouldConvert: shouldConvertForBadLoan,
      aggregationCurrency: aggregationCurrencyForBadLoan,
    } = getConversionConfig(filter(userLoans, (loan) => !!loan.badLoanInfo));
    const totalBadLoanAmount = getTotalBadLoanAmount(
      userLoans,
      shouldConvertForBadLoan
    );

    data[key] = {
      name: key,
      loans: userLoans,
      createdDate: min(map(userLoans, (loan) => loan.createDate)),
      dueDate: dueDate,
      aggregationCurrency,
      progressPercentage:
        sumBy(pendingLoans, (l) => getPercent(l)) / pendingLoans.length,
      minAmount: sum(
        map(userLoans, (loan) =>
          !shouldConvert
            ? loan.principleBalance
            : convertToAppLevelCurrency(loan.principleBalance, loan.currency)
        )
      ),
      maxAmount: sum(
        map(userLoans, (loan) =>
          !shouldConvert
            ? loan.principleBalance
            : convertToAppLevelCurrency(loan.principleBalance, loan.currency)
        )
      ),
      notes: join(
        map(userLoans, (loan) => loan.notes),
        ", "
      ),
      amount: netAmount,
      principleBalance: sum(
        map(userLoans, (loan) =>
          !shouldConvert
            ? loan.principleBalance
            : convertToAppLevelCurrency(loan.principleBalance, loan.currency)
        )
      ),
      principleAmount: sum(
        map(userLoans, (loan) =>
          !shouldConvert
            ? loan.principleAmount
            : convertToAppLevelCurrency(loan.principleAmount, loan.currency)
        )
      ),
      totalBadLoanAmount:
        totalBadLoanAmount > 0
          ? format(totalBadLoanAmount, aggregationCurrencyForBadLoan)
          : undefined,
      nearestLoan: nearestLoan,
      nearestLoanAmount: nearestLoanAmount,
      dueDateLoansAggregationCurrency,
      totalAmountPaid,
      allUserLoansSettled: pendingLoans.length === 0,
      settledLoansTint:
        totalOwesLoans <= totalLentLoans
          ? TRANSACTION_TYPE_LENT
          : TRANSACTION_TYPE_OWES,
      mostLoansType:
        filter(userLoans, (loan) => loan.loanStatus === "Closed").length ===
        userLoans.length
          ? "Settled"
          : sumBy(totalLentLoans, "principleBalance") >
            sumBy(totalOwesLoans, "principleBalance")
          ? TRANSACTION_TYPE_LENT
          : TRANSACTION_TYPE_OWES,
    };
  });

  return values(data);
};
