import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import _ from "lodash";
import * as ROUTES from "../../constants/routes";

const firebaseui = require("firebaseui");

function getAuthUI(firebase) {
  if (!window.firebaseAuthUI) {
    window.firebaseAuthUI = new firebaseui.auth.AuthUI(firebase);
  }

  return window.firebaseAuthUI;
}

export default compose(
  withRouter,
  withFirebase
)(function Login({ firebase, loginSuccessful, history }) {
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const ui = getAuthUI(firebase.auth());

    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          firebase
            .signInWithMobile(authResult.user)
            .then((resp) => {
              console.log("AuthResult: ", authResult);

              setErrorMessage(null);
              if (_.isFunction(loginSuccessful)) {
                loginSuccessful();
              } else {
                history.push(ROUTES.STATISTICS);
              }
            })
            .catch((error) => {
              setErrorMessage(
                (error && error.message) ||
                  "Unauthorized access or network issue. Please refresh the page and try again"
              );
            });

          return false;
        },
      },
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          defaultCountry: "IN",
        },
      ],
    };

    ui.start("#firebaseui-auth-container", uiConfig);
  }, [firebase, history, loginSuccessful]);

  return (
    <>
      <link
        type='text/css'
        rel='stylesheet'
        href='https://cdn.firebase.com/libs/firebaseui/3.5.2/firebaseui.css'
      />
      {errorMessage && <div>{errorMessage}</div>}
      <div id='firebaseui-auth-container'></div>
    </>
  );
});
