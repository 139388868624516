import {
  BI_MONTHLY,
  BI_WEEKLY,
  HALF_YEARLY,
  INTEREST_CALCULATION_STRATEGY,
  MONTHLY,
  QUARTERLY,
  RFL_MAX_INSTALLMENTS,
  WEEKLY,
  YEARLY,
} from "../constants/LoanConstants";
import {
  COMPOUND_INTEREST,
  SIMPLE_INTEREST,
  compoundInterestValues,
  latestReferenceDate,
} from "./LoanUtils";
import {
  FIXED_PAYMENT,
  FIXED_TENURE,
  MANUAL_ENTRY,
} from "../constants/InstalmentPaymentMode";
import {
  calculateCompoundInterest as cci,
  calculateSimpleInterest as csi,
  calculateSimpleInterestIntervalEnd as csiIntervalEnd,
} from "./InterestCalculators";
import { filter, find, first, last, map, orderBy, round } from "lodash";

import { DD_SP_MMM_SP_YYYY } from "../constants/dateFormats";
import moment from "moment";

const generateFormalEMIData = (
  transaction,
  calculateEMI = true,
  setTenure = false
) => {
  let instalmetPaymentMode = FIXED_TENURE;
  let tenure = 0;
  let installmentEMI = 0;

  let firstEMIDate;

  if (
    transaction.recurringPaymentInfo.schedule &&
    transaction.recurringPaymentInfo.schedule.length > 0
  ) {
    const firstitem = find(
      transaction.recurringPaymentInfo.schedule,
      (schedule) => schedule.status === "None"
    );
    if (firstitem) {
      firstEMIDate = moment(firstitem.paidOn * 1000);
    } else {
      const lastItem = last(
        transaction.recurringPaymentInfo.schedule,
        (schedule) => schedule.status !== "None"
      );
      if (lastItem) {
        firstEMIDate = generateNextEmiDate(
          lastItem.paidOn * 1000,
          transaction.recurringPaymentInfo
        );
      }
    }
  } else {
    firstEMIDate =
      transaction.recurringPaymentInfo &&
      transaction.recurringPaymentInfo.startOfDueDate &&
      moment(transaction.recurringPaymentInfo.startOfDueDate * 1000);
  }

  /* Invalidate the payment plan if the loan created date is after the first EMI date */
  if (!!firstEMIDate && moment(transaction.createDate * 1000) > firstEMIDate) {
    throw new Error(
      `Loan created date "${moment(transaction.createDate * 1000).format(
        DD_SP_MMM_SP_YYYY
      )}" is after the first EMI date "${firstEMIDate.format(
        DD_SP_MMM_SP_YYYY
      )}"`
    );
  }

  if (transaction.recurringPaymentInfo && firstEMIDate) {
    let info = transaction.recurringPaymentInfo;
    if (info.recurringType === FIXED_TENURE) {
      tenure = info.installments || 5;
      instalmetPaymentMode = FIXED_TENURE;
      let frequency = transaction.recurringPaymentInfo.frequency;
      installmentEMI = calculateEMI
        ? calculateRFLEMI(
            transaction,
            frequency,
            tenure,
            firstEMIDate.valueOf() / 1000
          )
        : info.calculatedEMI ||
          calculateRFLEMI(
            transaction,
            frequency,
            tenure,
            firstEMIDate.valueOf() / 1000
          );
    } else if (info.recurringType === FIXED_PAYMENT) {
      installmentEMI = info.monthlyPayableAmount;
      instalmetPaymentMode = FIXED_PAYMENT;
    } else {
      instalmetPaymentMode = MANUAL_ENTRY;
    }
  }

  let formalData = {
    createDate: transaction.createDate ?? 0,
    principalBalance: transaction.principleBalance ?? 0,
    principalAmount: transaction.principleAmount ?? 0,
    tenure: tenure,
    installmentEMI,
    interestRate: transaction.interestRate ?? 0,
    firstEMIDay: firstEMIDate,
    loanName: transaction.friend?.name ?? "",
    instalmentPaymentMode: instalmetPaymentMode,
  };

  return formalData;
};

const interestTillFirstEMIDate = (loan, firstEMIDate) => {
  const lastRemainingInterest = loan.lastRemainingInterest || 0;
  const principal = loan.principleBalance;
  const startDate = latestReferenceDate(loan);
  const interest = calculateInterestBetweenDates(
    startDate,
    firstEMIDate,
    principal,
    loan.interestRate || 0,
    loan.interestMode,
    loan.interestType
  );

  return lastRemainingInterest + interest;
};

export const calculateRFLEMI = (
  loan,
  frequencyString,
  tenure,
  firstEMIDate
) => {
  if (!frequencyString || !tenure || !firstEMIDate) {
    return;
  }

  const frequency = getFrequency(frequencyString);
  let interest = loan.interestRate || 0;
  const principal = loan.principleBalance;

  if (!interest) {
    return principal / tenure;
  }
  const R = interestTillFirstEMIDate(loan, firstEMIDate);
  let r = interest / (frequency * 100);
  const interestInterval = interestForEMIInterval(loan, frequencyString);
  let x = 1;

  const runEMIFormula = () => {
    let p = principal;
    const numerator =
      r * Math.pow(1 + r, tenure - x) * (p * Math.pow(1 + r, x) + R);
    const denominator = Math.pow(1 + r, tenure - x) * (1 + x * r) - 1;

    return numerator / denominator;
  };

  let emi;
  let interestX = 0;
  do {
    emi = runEMIFormula();
    interestX = interestInterval * x + R - x * emi;
    x = x + 1;
  } while (interestX > 0);

  // Old formula
  // emi =
  //   (principal * r * Math.pow(1 + r, tenure)) / (Math.pow(1 + r, tenure) - 1);
  // return round(emi * (1 - 0.015 * (tenure / 150)), 2);
  return round(emi, 2);
};

const calculateInterestBetweenDates = (
  fromDate,
  toDate,
  amount,
  interestRate,
  interestMode,
  interestType
) => {
  if (interestRate > 0) {
    let numberOfDaysInterest;
    if (interestMode === SIMPLE_INTEREST) {
      numberOfDaysInterest = csi(amount, interestRate, fromDate, toDate);
    } else {
      numberOfDaysInterest = cci(
        amount,
        interestRate,
        fromDate,
        toDate,
        compoundInterestValues(interestType)
      );
    }

    return Math.abs(numberOfDaysInterest);
  } else {
    return 0;
  }
};

const getFrequency = (frequencyString) => {
  let frequency = 1;
  switch (frequencyString) {
    case WEEKLY:
      frequency = 52;
      break;
    case BI_WEEKLY:
      frequency = 26;
      break;
    case MONTHLY:
      frequency = 12;
      break;
    case BI_MONTHLY:
      frequency = 6;
      break;
    case QUARTERLY:
      frequency = 4;
      break;
    case HALF_YEARLY:
      frequency = 2;
      break;
    case YEARLY:
      frequency = 1;
      break;
    default:
      break;
  }
  return frequency;
};

/**
 *
 * Generates next EMI date.
 * @param {*} previousDate
 * @param {*} frequency
 * @param {*} backward optional flag, returns the previous EMI date if set
 */
export const generateNextEmiDateFromFrequency = (
  previousDate,
  frequency,
  backward = false
) => {
  let date = moment(previousDate);
  let nextDate = date;
  let method = date.add.bind(date);
  if (backward) {
    method = date.subtract.bind(date);
  }

  switch (frequency) {
    case WEEKLY:
      nextDate = method(1, "week");
      break;
    case BI_WEEKLY:
      nextDate = method(2, "weeks");
      break;
    case MONTHLY:
      nextDate = method(1, "month");
      break;
    case BI_MONTHLY:
      nextDate = method(2, "months");
      break;
    case QUARTERLY:
      nextDate = method(3, "months");
      break;
    case HALF_YEARLY:
      nextDate = method(6, "months");
      break;
    case YEARLY:
    case "Annually":
      nextDate = method(1, "year");
      break;
    default:
      break;
  }
  return nextDate;
};

const generateNextEmiDate = (previousDate, recurringInfo, backward = false) => {
  return generateNextEmiDateFromFrequency(
    previousDate,
    recurringInfo.frequency,
    backward
  );
};

/**
 * Calculates interest for EMI interval e.g. week or quarter to use as min value for fixed amount scenario
 *                OR
 * the amount taken from last payment date to first EMI date (if exists)
 */
export const interestForEMIInterval = (loan, frequency, firstEMIDate) => {
  const startDate = latestReferenceDate(loan);
  const endDate =
    generateNextEmiDate(startDate * 1000, {
      frequency,
    }).valueOf() / 1000;

  return calculateInterestBetweenDates(
    startDate,
    endDate,
    loan.principleBalance,
    loan.interestRate,
    loan.interestMode,
    loan.interestType
  );
};

const generateEMIDatesBetweenIntervalDates = (
  startEMIDate,
  frequency,
  intervalStartDate,
  intervalEndDate
) => {
  let dates = [];
  let intervalStartDateMoment = intervalStartDate,
    intervalEndDateMoment = intervalEndDate;
  let EMIDateMoment = startEMIDate;

  do {
    if (
      EMIDateMoment.isBetween(
        intervalStartDateMoment,
        intervalEndDateMoment,
        "day",
        "[]"
      )
    ) {
      dates.push(EMIDateMoment);
    } else if (EMIDateMoment.isAfter(intervalEndDateMoment)) {
      break;
    }
    EMIDateMoment = generateNextEmiDateFromFrequency(EMIDateMoment, frequency);
  } while (true);

  return dates;
};

const scheduleCompoundedLoanEMISchedules = (
  transaction,
  setTenure,
  recurringInfo,
  data
) => {
  let {
    interestMode = SIMPLE_INTEREST,
    createDate: createdAt,
    compoundInterestReferenceIntervalDate = createdAt,
    simpleInterestReferenceIntervalDate = createdAt,
    interestRate = 0,
    principleAmount: principalAmount,
    principleBalance: principalBalance = principalAmount,
    lastRemainingInterest = 0,
    interestType,
  } = transaction;

  const isCompounded = interestMode === COMPOUND_INTEREST;
  interestType = !isCompounded ? MONTHLY : interestType;

  const { firstEMIDay, installmentEMI: calculatedEMI } = data;

  let remainingInterest = lastRemainingInterest;
  let remainingPrincipal = principalBalance,
    nextEMIDate = firstEMIDay;
  let { frequency } = recurringInfo;

  let paymentsSchedule = [];
  let intervalStartDate = moment(
      (isCompounded
        ? compoundInterestReferenceIntervalDate
        : simpleInterestReferenceIntervalDate) * 1000
    ),
    intervalEndDate;
  let firstTime = true;
  const numberOfParts = compoundInterestValues(interestType || "Monthly");

  function processPaymentDates(dates) {
    let amount = 0;
    let towardsPrincipal = 0;
    let towardsInterest = 0;

    for (let date of dates) {
      if (calculatedEMI < remainingInterest) {
        towardsInterest = calculatedEMI;
        towardsPrincipal = 0;
      } else {
        towardsInterest = remainingInterest;
        towardsPrincipal =
          calculatedEMI - towardsInterest > remainingPrincipal
            ? remainingPrincipal
            : calculatedEMI - towardsInterest;
      }
      amount = towardsInterest + towardsPrincipal;

      remainingInterest = remainingInterest - towardsInterest;
      remainingPrincipal = remainingPrincipal - towardsPrincipal;

      let schedule = {
        paidOn: moment(date).valueOf() / 1000,
        amount: amount,
        interestAfter: remainingInterest,
        interestBefore: remainingInterest + towardsInterest,
        status: "None",
        principleBalAfter: remainingPrincipal,
        principleBalBefore: remainingPrincipal + towardsPrincipal,
        totalAmount: remainingPrincipal,
      };

      paymentsSchedule.push(schedule);

      if (remainingPrincipal <= 1) {
        break;
      }

      if (paymentsSchedule.length > RFL_MAX_INSTALLMENTS) {
        throw new Error(
          "Generated too many installments. Please choose a higher installment amount"
        );
      }
    }
  }

  /**
   * Process the schedules that fall before the interest start date
   * This is possible in case of interestStartDate is ahead of all the payments made,
   * and the first EMI start date
   */
  const scheduleDatesBeforeIntervalStartDate =
    generateEMIDatesBetweenIntervalDates(
      nextEMIDate,
      frequency,
      nextEMIDate,
      moment(intervalStartDate).subtract(1, "day") // Exclude this interval's start date
    );

  processPaymentDates(scheduleDatesBeforeIntervalStartDate);

  while (remainingPrincipal >= 1) {
    intervalEndDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );

    let interest = 0;

    if (!firstTime) {
      // Calculate the interest for the previous interval with the remaining principal

      if (isCompounded) {
        interest = csi(
          remainingPrincipal,
          interestRate,
          generateNextEmiDateFromFrequency(
            intervalStartDate,
            interestType,
            true
          ).valueOf() / 1000,
          intervalStartDate.valueOf() / 1000,
          numberOfParts
        );
      } else {
        interest = csiIntervalEnd(remainingPrincipal, interestRate);
      }
    }
    firstTime = false;

    remainingInterest += interest;

    const intervalScheduleDates = generateEMIDatesBetweenIntervalDates(
      nextEMIDate,
      frequency,
      intervalStartDate,
      moment(intervalEndDate).subtract(1, "day") // Exclude this interval's end date (it will be accommodated in the next iteration)
    );

    const intervalStartDateScheduleDates = filter(
      intervalScheduleDates,
      (date) => date.isSame(intervalStartDate, "day")
    );

    processPaymentDates(intervalStartDateScheduleDates);

    if (isCompounded) {
      // Add the remaining interest into the remaining principal after processing the start date schedules
      remainingPrincipal += remainingInterest;
      remainingInterest = 0;
    }

    if (remainingPrincipal + remainingInterest < 1) {
      break;
    }

    const otherScheduleDates = filter(
      intervalScheduleDates,
      (date) => date > intervalStartDate
    );

    processPaymentDates(otherScheduleDates);

    // Interval hops based on interestType whereas next EMI date hops on recurringInfo.frequency
    intervalStartDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );
    nextEMIDate =
      intervalScheduleDates.length > 0
        ? generateNextEmiDateFromFrequency(
            last(intervalScheduleDates),
            frequency
          )
        : nextEMIDate; // Set to old nextEMIDate if this interval doesn't have any payments
  }

  return {
    schedule: orderBy(paymentsSchedule, "paidOn", "asc"),
    calculatedEMI: data.installmentEMI,
  };
};

const scheduleCompoundedLoanManualEMISchedules = (transaction, schedule) => {
  let {
    interestMode = SIMPLE_INTEREST,
    createDate: createdAt,
    compoundInterestReferenceIntervalDate = createdAt,
    simpleInterestReferenceIntervalDate = createdAt,
    interestRate = 0,
    principleAmount: principalAmount,
    principleBalance: principalBalance = principalAmount,
    lastRemainingInterest = 0,
    interestType,
  } = transaction;

  if (schedule.length === 0) {
    return [];
  }

  const isCompounded = interestMode === COMPOUND_INTEREST;
  interestType = !isCompounded ? MONTHLY : interestType;
  schedule = orderBy(schedule, ["paidOn", "amount"], ["asc", "asc"]);

  let remainingInterest = lastRemainingInterest;
  let remainingPrincipal = principalBalance;

  let paymentsSchedule = [];
  let intervalStartDate = moment(
      (isCompounded
        ? compoundInterestReferenceIntervalDate
        : simpleInterestReferenceIntervalDate) * 1000
    ),
    intervalEndDate;
  let firstTime = true;
  const numberOfParts = compoundInterestValues(interestType || "Monthly");
  let processedPaymentsLength = 0;

  function processPayments(payments) {
    let amount = 0;
    let towardsPrincipal = 0;
    let towardsInterest = 0;

    for (let payment of payments) {
      const paymentAmount = payment.amount || 0;
      if (paymentAmount < remainingInterest) {
        towardsInterest = paymentAmount;
        towardsPrincipal = 0;
      } else {
        towardsInterest = remainingInterest;
        towardsPrincipal =
          paymentAmount - towardsInterest > remainingPrincipal
            ? remainingPrincipal
            : paymentAmount - towardsInterest;
      }
      amount = towardsInterest + towardsPrincipal;

      remainingInterest = remainingInterest - towardsInterest;
      remainingPrincipal = remainingPrincipal - towardsPrincipal;

      let scheduleItem = {
        paidOn: payment.paidOn,
        amount: amount,
        interestAfter: remainingInterest,
        interestBefore: remainingInterest + towardsInterest,
        status: "None",
        principleBalAfter: remainingPrincipal,
        principleBalBefore: remainingPrincipal + towardsPrincipal,
        totalAmount: remainingPrincipal,
      };

      paymentsSchedule.push(scheduleItem);
      processedPaymentsLength++;

      if (
        remainingPrincipal <= 1 ||
        processedPaymentsLength === schedule.length
      ) {
        break;
      }

      if (paymentsSchedule.length > RFL_MAX_INSTALLMENTS) {
        throw new Error(
          "Generated too many installments. Please choose a higher installment amount"
        );
      }
    }
  }

  /**
   * Process the schedules that fall before the interest start date
   * This is possible in case of interestStartDate is ahead of all the payments made,
   * and the first EMI start date
   */
  const schedulesBeforeInterestStartDate = filter(schedule, (item) =>
    moment(item.paidOn * 1000).isBefore(intervalStartDate)
  );

  processPayments(schedulesBeforeInterestStartDate);

  while (
    remainingPrincipal + remainingInterest >= 1 &&
    processedPaymentsLength < schedule.length
  ) {
    intervalEndDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );

    let interest = 0;

    if (!firstTime) {
      // Calculate the interest for the previous interval with the remaining principal

      if (isCompounded) {
        interest = csi(
          remainingPrincipal,
          interestRate,
          generateNextEmiDateFromFrequency(
            intervalStartDate,
            interestType,
            true
          ).valueOf() / 1000,
          intervalStartDate.valueOf() / 1000,
          numberOfParts
        );
      } else {
        interest = csiIntervalEnd(remainingPrincipal, interestRate);
      }
    } else {
      firstTime = false;
    }

    remainingInterest += interest;

    /** Items that are falling in the current interval */
    const intervalScheduleItems = filter(schedule, (item) =>
      moment(item.paidOn * 1000).isBetween(
        intervalStartDate,
        moment(intervalEndDate).subtract(1, "day"),
        "day",
        "[]"
      )
    );

    const intervalStartDateScheduleItems = filter(
      intervalScheduleItems,
      (item) => moment(item.paidOn * 1000).isSame(intervalStartDate, "day")
    );

    processPayments(intervalStartDateScheduleItems);

    if (isCompounded) {
      // Add the remaining interest into the remaining principal after processing the start date schedules
      remainingPrincipal += remainingInterest;
      remainingInterest = 0;
    }

    if (
      remainingPrincipal + remainingInterest < 1 ||
      processedPaymentsLength === schedule.length
    ) {
      break;
    }

    const otherScheduleItems = filter(intervalScheduleItems, (item) =>
      moment(item.paidOn * 1000).isAfter(intervalStartDate)
    );

    processPayments(otherScheduleItems);

    // Interval hops based on interestType whereas next EMI date hops on recurringInfo.frequency
    intervalStartDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );
  }

  return paymentsSchedule;
};

export const scheduleLoanEMISchedules = (
  transaction,
  setTenure,
  calculateEMI
) => {
  let paymentsSchedule = [];
  let {
    interestRate = 0,
    interestMode,
    recurringPaymentInfo: recurringInfo,
    interestCalculationStrategy = INTEREST_CALCULATION_STRATEGY.DAY_END,
  } = transaction;

  const isCompounded = interestMode === COMPOUND_INTEREST;
  let data = generateFormalEMIData(transaction, calculateEMI, setTenure);

  if (!recurringInfo) {
    return {
      schedule: [],
    };
  }

  if (
    recurringInfo.recurringType === FIXED_TENURE &&
    (!recurringInfo.installments ||
      !data.firstEMIDay ||
      !recurringInfo.frequency)
  ) {
    return {
      schedule: [],
    };
  } else if (
    recurringInfo.recurringType === FIXED_PAYMENT &&
    (!recurringInfo.monthlyPayableAmount ||
      !data.firstEMIDay ||
      !recurringInfo.frequency)
  ) {
    return {
      schedule: [],
    };
  } else if (recurringInfo.recurringType === MANUAL_ENTRY) {
    return {
      schedule: [],
    };
  }
  if (!data.firstEMIDay) {
    return transaction.recurringPaymentInfo.schedule;
  }

  if (
    isCompounded ||
    interestCalculationStrategy === INTEREST_CALCULATION_STRATEGY.MONTH_END
  ) {
    return scheduleCompoundedLoanEMISchedules(
      transaction,
      setTenure,
      recurringInfo,
      data
    );
  }

  let remainingPrinciple = transaction.principleBalance;
  let remainingInterest = transaction.lastRemainingInterest || 0;
  let nextEMIDate = data.firstEMIDay;

  const referenceDate = latestReferenceDate(transaction);
  const referenceDateMoment = moment(referenceDate * 1000);

  remainingInterest += calculateInterestBetweenDates(
    referenceDate,
    data.firstEMIDay.valueOf() / 1000,
    remainingPrinciple,
    interestRate,
    transaction.interestMode,
    transaction.interestType
  );

  let calculatedEMI = data.installmentEMI;

  /* Ignore payment less than a rupee */
  while (remainingPrinciple >= 1) {
    let amount = 0;
    let towardsPrinciple = 0;
    let towardsInterest = 0;
    if (calculatedEMI < remainingInterest) {
      towardsInterest = calculatedEMI;
      towardsPrinciple = 0;
    } else {
      towardsInterest = remainingInterest;
      towardsPrinciple =
        calculatedEMI - towardsInterest > remainingPrinciple
          ? remainingPrinciple
          : calculatedEMI - towardsInterest;
    }
    amount = towardsInterest + towardsPrinciple;

    remainingInterest = remainingInterest - towardsInterest;
    remainingPrinciple = remainingPrinciple - towardsPrinciple;

    let schedule = {
      paidOn: moment(nextEMIDate).valueOf() / 1000,
      amount: amount,
      interestAfter: remainingInterest,
      interestBefore: remainingInterest + towardsInterest,
      status: "None",
      principleBalAfter: remainingPrinciple,
      principleBalBefore: remainingPrinciple + towardsPrinciple,
      totalAmount: remainingPrinciple,
    };

    remainingInterest =
      remainingInterest +
      calculateInterestBetweenDates(
        /**
         * We are taking the maximum of reference date and nextEMIDate as from date here because
         * the nextEMIDate can fall before interestStartDate. This ensures that the interest is calculated appropriately
         */
        moment.max([nextEMIDate, referenceDateMoment]).valueOf() / 1000,
        generateNextEmiDate(nextEMIDate, recurringInfo).valueOf() / 1000,
        remainingPrinciple,
        interestRate,
        transaction.interestMode,
        transaction.interestType
      );

    nextEMIDate = generateNextEmiDate(nextEMIDate, recurringInfo);
    paymentsSchedule.push(schedule);

    if (paymentsSchedule.length > RFL_MAX_INSTALLMENTS) {
      throw new Error(
        "Generated too many installments. Please choose a higher installment amount"
      );
    }
  }

  return {
    schedule: orderBy(paymentsSchedule, "paidOn", "asc"),
    calculatedEMI: data.installmentEMI,
  };
};

export const scheduleLoanEMIS = (
  transaction,
  setTenure = false,
  calculateEMI = true
) => {
  let schedules = scheduleLoanEMISchedules(
    transaction,
    setTenure,
    calculateEMI
  );
  return schedules;
};

export const scheduleManualLoans = (transaction, manualSchedules) => {
  let loanSchedules = [];
  if (!manualSchedules) {
    loanSchedules =
      transaction.recurringPaymentInfo &&
      transaction.recurringPaymentInfo.schedule &&
      transaction.recurringPaymentInfo.schedule.length > 0
        ? filter(
            transaction.recurringPaymentInfo.schedule,
            (schedule) => schedule.status === "None"
          )
        : [];
    if (loanSchedules.length === 0) {
      return [];
    }
  }
  let firstEMIDate =
    first(manualSchedules || loanSchedules) &&
    moment(first(manualSchedules || loanSchedules).paidOn * 1000);
  /* Invalidate the payment plan if the loan created date is after the first EMI date */
  if (!!firstEMIDate && moment(transaction.createDate * 1000) > firstEMIDate) {
    throw new Error(
      `Loan created date "${moment(transaction.createDate * 1000).format(
        DD_SP_MMM_SP_YYYY
      )}" is after the first EMI date "${firstEMIDate.format(
        DD_SP_MMM_SP_YYYY
      )}"`
    );
  }

  const {
    interestMode = SIMPLE_INTEREST,
    interestCalculationStrategy = INTEREST_CALCULATION_STRATEGY.DAY_END,
  } = transaction;
  const isCompounded = interestMode === COMPOUND_INTEREST;

  if (
    isCompounded ||
    interestCalculationStrategy === INTEREST_CALCULATION_STRATEGY.MONTH_END
  ) {
    return scheduleCompoundedLoanManualEMISchedules(
      transaction,
      manualSchedules || loanSchedules
    );
  }

  let sortedSchedules = orderBy(
    manualSchedules || loanSchedules,
    "paidOn",
    "asc"
  );
  let schedules = [];
  let remainingPrinciple = transaction.principleBalance;
  let remainingInterest = transaction.lastRemainingInterest || 0;
  let referenceDate = latestReferenceDate(transaction);

  map(sortedSchedules, (schedule) => {
    remainingInterest =
      remainingInterest +
      calculateInterestBetweenDates(
        referenceDate,
        schedule.paidOn,
        remainingPrinciple,
        transaction.interestRate,
        transaction.interestMode,
        transaction.interestType
      );

    let towardsPrinciple = 0;
    let towardsInterest = 0;
    if (schedule.amount < remainingInterest) {
      towardsInterest = schedule.amount;
      towardsPrinciple = 0;
    } else {
      towardsInterest = remainingInterest;
      towardsPrinciple =
        schedule.amount - towardsInterest > remainingPrinciple
          ? remainingPrinciple
          : schedule.amount - towardsInterest;
    }

    remainingInterest = remainingInterest - towardsInterest;
    remainingPrinciple = remainingPrinciple - towardsPrinciple;

    let newSchedule = {
      paidOn: schedule.paidOn,
      amount: schedule.amount,
      interestAfter: remainingInterest,
      interestBefore: remainingInterest + towardsInterest,
      principleBalAfter: remainingPrinciple,
      principleBalBefore: remainingPrinciple + towardsPrinciple,
      status: schedule.status || "None",
    };

    /**
     * Update the reference date only if it crosses the previous.
     * Happens if the interest start date is after the payment(s)
     */
    if (schedule.paidOn > referenceDate) {
      referenceDate = schedule.paidOn;
    }

    if (towardsInterest === 0 && towardsPrinciple === 0) {
      return schedules;
    } else {
      schedules.push(newSchedule);
    }
  });
  return schedules;
};
