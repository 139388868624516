import { PDF } from "./constants/payoffPlanner";

const DEV = "DEV";
const PROD = "PROD";
const RNDEV = "RNDEV";
const LOCAL = "LOCAL";

const ENV = PROD;

export const getPayoffPlannerExportDataURL = ({ userId, format = PDF }) =>
  ({
    [DEV]: `https://us-central1-devtlloanapp-3d093.cloudfunctions.net/payoffPlanner/${userId}/export/${format}/data`,
    [PROD]: `https://us-central1-tlloanapp-d0571.cloudfunctions.net/payoffPlanner/${userId}/export/${format}/data`,
    [LOCAL]: `http://localhost:5001/devtlloanapp-3d093/us-central1/payoffPlanner/${userId}/export/${format}/data`,
  }[ENV]);

export const cloudFunctionUrl = () =>
  ({
    [DEV]:
      "https://asia-south1-devtlloanapp-3d093.cloudfunctions.net/loansExport",
    [RNDEV]:
      "https://asia-south1-react-native-firebase-7aef1.cloudfunctions.net/loansExport",
    [PROD]:
      "https://asia-south1-tlloanapp-d0571.cloudfunctions.net/loansExport",
    [LOCAL]: "http://localhost:3010/exportLoans",
  }[ENV]);

export const collectionListUrl = () =>
  ({
    [DEV]:
      "https://us-central1-devtlloanapp-3d093.cloudfunctions.net/api/collectionList/data",
    [PROD]:
      "https://us-central1-tlloanapp-d0571.cloudfunctions.net/api/collectionList/data",
    [LOCAL]:
      "http://localhost:5001/devtlloanapp-3d093/us-central1/api/collectionList/data",
  }[ENV]);

export const fetchSharedLoanDataUrl = () =>
  ({
    [DEV]:
      "https://us-central1-devtlloanapp-3d093.cloudfunctions.net/api/fetchSharedLoan",
    [PROD]:
      "https://us-central1-tlloanapp-d0571.cloudfunctions.net/api/fetchSharedLoan",
    [LOCAL]:
      "http://localhost:5001/devtlloanapp-3d093/us-central1/api/fetchSharedLoan",
  }[ENV]);

export const deepLinkURL = ({ invitationId, utmCampaign }) => {
  return {
    [PROD]: `https://freebird.page.link/?link=http://sharing.getfreebird.app/i/${invitationId}&apn=com.tilicho.lendpal&isi=1244075045&ibi=com.tilicho.TLLoanApp&st=Freebird&sd=Track+and+export+your+shared+loan&${
      utmCampaign
        ? `utm_campaign=${utmCampaign}&utm_source=virility_loop_AL&utm_medium=sms`
        : "utm_source=loan_share"
    }`,
    [DEV]: `https://devfreebird.page.link/?link=http://devsharing.getfreebird.app/i/${invitationId}&apn=com.tilicho.freebirdDev&isi=1536121860&ibi=com.tilicho.freebird-Dev&st=Freebird&sd=Track+and+export+your+shared+loan&${
      utmCampaign
        ? `utm_campaign=${utmCampaign}&utm_source=virility_loop_AL&utm_medium=sms`
        : "utm_source=loan_share"
    }`,
    [LOCAL]: `https://devfreebird.page.link/?link=http://devsharing.getfreebird.app/i/${invitationId}&apn=com.tilicho.freebirdDev&isi=1536121860&ibi=com.tilicho.freebird-Dev&st=Freebird&sd=Track+and+export+your+shared+loan&${
      utmCampaign
        ? `utm_campaign=${utmCampaign}&utm_source=virility_loop_AL&utm_medium=sms`
        : "utm_source=loan_share"
    }`,
  }[ENV || DEV];
};
