import "./index.css";

import React, { useEffect, useState } from "react";
import { filter, get, isEmpty, map, values } from "lodash";

import AllLoansSummary from "./AllLoansSummary";
import SingleLoanView from "./SingleLoanView";
import UserLoansSummary from "./UserLoansSummary";
import axios from "axios";
import { cloudFunctionUrlV3 } from "../../env";
import moment from "moment";
import qs from "query-string";

const MODE_USER_LOANS = "USER_LOANS";
const MODE_SINGLE_LOAN = "SINGLE_LOAN";
const MODE_ALL_LOANS = "ALL_LOANS";

export const GeneratePdfV3 = (props) => {
  const [data, setData] = useState({
    isLoaded: false,
    result: {},
  });

  const params = qs.parseUrl(window.location.search).query;
  const mode = !!params.friendName
    ? MODE_USER_LOANS
    : !!params.transactionId
    ? MODE_SINGLE_LOAN
    : MODE_ALL_LOANS;

  useEffect(() => {
    axios
      .get(cloudFunctionUrlV3(), {
        params: { ...params, mode: "data" },
      })
      .then((r) => {
        setData({
          isLoaded: true,
          result: r.data || {},
          error: null,
        });
      })
      .catch((err) => {
        setData({
          isLoaded: true,
          result: {},
          error: new Error(err?.response?.data?.message),
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    friendName,
    summary,
    // givenLoans = [],
    // takenLoans = [],
    loans = [],
    formalLoans = [],
    options,
    userDetails,
    userOrganization,
  } = data.result;

  if (!data.isLoaded) {
    return <></>;
  }

  const allLoans = [
    // ...orderBy([...givenLoans, ...takenLoans], "createDate", "asc"),
    ...loans,
    ...formalLoans,
  ];
  let userId = userDetails?.id ? userDetails?.id : null;

  const normalLoans = filter(allLoans, (loan) => !loan.isFormalLoan);
  const shouldShowTermsAndConditions =
    hasProfessionalSubscription({
      userDetails,
    }) && normalLoans.length > 0;

  return (
    <div id="export-content">
      <ReportHeader {...{ userOrganization, userDetails }} />

      {data.error && <h2 className="center">{data.error.message}</h2>}

      {!data.error && (
        <>
          {mode === MODE_USER_LOANS && (
            <div className="summary">
              <UserLoansSummary summary={summary} friendName={friendName} />

              {/* TODO: Insights row here */}
            </div>
          )}
          {mode === MODE_ALL_LOANS && <AllLoansSummary summary={summary} />}
          <div className="content">
            {mode === MODE_SINGLE_LOAN &&
              (allLoans && allLoans.length > 0 ? (
                <SingleLoanView
                  loan={allLoans[0]}
                  options={options}
                  organization={userOrganization}
                  userId={userId}
                />
              ) : (
                <h2 className="center">Invalid loan</h2>
              ))}
            {mode !== MODE_SINGLE_LOAN && (
              <div className="loans">
                {allLoans && allLoans.length > 0 ? (
                  <>
                    <ol className="loadInfo">
                      {map(allLoans, (loan) => (
                        <li key={loan.transactionId}>
                          <SingleLoanView
                            loan={loan}
                            options={options}
                            organization={userOrganization}
                          />
                        </li>
                      ))}
                    </ol>
                  </>
                ) : (
                  <h2 className="center">No Loans</h2>
                )}
              </div>
            )}
            {shouldShowTermsAndConditions && (
              <TermsAndConditions {...{ userOrganization }} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const hasProfessionalSubscription = ({ userDetails }) => {
  /**
   * premium_subscription_1_monthly
   * premium_subscription_1_yearly
   */
  const hasValidData = (productId) => {
    const productInfo = get(userDetails, `iaps.products.${productId}`);
    return (
      !isEmpty(productInfo) &&
      moment(get(productInfo, "expiresAt", 0)).isSameOrAfter(moment())
    );
  };

  const result =
    hasValidData("premium_subscription_1_monthly") ||
    hasValidData("premium_subscription_1_yearly") ||
    hasValidData("prod_professional_yearly_team_micro") ||
    hasValidData("prod_professional_yearly_team_small") ||
    hasValidData("prod_professional_yearly_team_medium") ||
    hasValidData("prod_professional_yearly_team_octave") ||
    hasValidData("prod_professional_yearly_team_large") ||
    hasValidData("rc_promo_professional_yearly") ||
    hasValidData("rc_promo_professional_weekly");

  return result;
};

const TermsAndConditions = ({ userOrganization = {} }) => {
  const { termsAndConditions: termsArray = [], name } = userOrganization;

  return (
    termsArray.length !== 0 && (
      <div className="terms-and-conditions-container">
        <div className="terms-and-conditions-title">Terms and Conditions</div>
        <div className="terms-and-conditions-subtitle">
          Financial terms & conditions levied by "{<strong>{name}</strong>}"
        </div>
        <ul>
          {map(termsArray, (term) => (
            <li>{term}</li>
          ))}
        </ul>
      </div>
    )
  );
};

const ReportHeader = ({ userDetails, userOrganization }) => {
  const shouldShowCustomHeader =
    hasProfessionalSubscription({ userDetails }) &&
    !isEmpty(userOrganization?.name);
  const headerData = (() => {
    const name = userOrganization?.name || userDetails?.name;
    const logoURL = userOrganization?.logoUrl || userOrganization?.logoURL;
    const address = userOrganization?.addresses?.[0];
    const email = userOrganization?.emails?.[0];
    const mobileNumber = userOrganization?.mobileNumbers?.[0];

    return {
      name,
      logoURL,
      address,
      email,
      mobileNumber,
    };
  })();

  const { name, logoURL, address = "", email, mobileNumber } = headerData;
  const shouldShowSecondRow = !!address || !!email || !!mobileNumber;

  return (
    <div className="header">
      {!shouldShowCustomHeader && (
        <div
          className={!shouldShowCustomHeader ? "logo-freebird" : "logo"}
        ></div>
      )}
      {shouldShowCustomHeader && (
        <div className="custom-header-container">
          <div className="row">
            {!!logoURL && <img className="org-logo" src={logoURL} alt="" />}
            {!!name && <div className="org-name">{name}</div>}
          </div>
          {shouldShowSecondRow && (
            <div className="spaced-out-row">
              {!!address && <div className="org-address">{address}</div>}
              <div
                className={
                  !address
                    ? "org-email-mobile-container-row"
                    : "org-email-mobile-container-column"
                }
              >
                {!!mobileNumber && (
                  <ContentField
                    image={require("../../assets/mobile-number.png")}
                    href={`tel:${mobileNumber}`}
                    title={mobileNumber}
                    isShownInRow={!address}
                  />
                )}
                {!!email && (
                  <ContentField
                    image={require("../../assets/email-open.png")}
                    href={`mailto:${email}`}
                    title={email}
                    isShownInRow={!address}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ContentField = ({ image, href, title, isShownInRow }) => {
  return (
    <div className="org-mobile-number-container">
      <a
        className={
          isShownInRow ? "org-mobile-number" : "org-mobile-number-column"
        }
        href={href}
      >
        <img className="org-mobile-number-img" src={image} alt="" />
        {title}
      </a>
    </div>
  );
};
