import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import OutlinedCard from "./dataCard";
import { fetchAllStats } from "../../utils/api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const Statistics = () => {
  const classes = useStyles();

  const [stats, setStats] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const getData = () => {
      setIsFetching(true);

      fetchAllStats()
        .then((res) => {
          prepareData(res.data);
          setIsFetching(false);
          setErrorMessage(null);
        })
        .catch((error) => {
          setIsFetching(false);
          setErrorMessage(
            "Unauthorized access or network issue. Please refresh the page and try again"
          );
        });
    };

    getData();
  }, []);

  const prepareData = (data) => {
    var i,
      j,
      temp = [],
      chunk = 3;
    for (i = 0, j = data.length; i < j; i += chunk) {
      temp.push(data.slice(i, i + chunk));
    }
    setStats(temp);
  };

  return (
    <div className={classes.root}>
      {errorMessage && <div>{errorMessage}</div>}

      {!isFetching ? (
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={3}>
            {stats.map((items, index) => {
              return <OutlinedCard key={index} items={items} />;
            })}
          </Grid>
        </Grid>
      ) : (
        <LinearProgress style={{ marginTop: 20 }} />
      )}
    </div>
  );
};
export default Statistics;
