import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Button from "@material-ui/core/Button";
import OutlinedCard from "../Migration/userComponent";
import { withFirebase } from "../Firebase";
import FriendsExpansionPanels from "../Migration/FriendsComponent";
import LinearProgress from "@material-ui/core/LinearProgress";
import { fetchUserData } from "../../utils/api";

class Migrationbase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionType: "",
      id: "",
      userDetails: {},
      searchCompleted: false,
      loading: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      newUser:
        event.target.value === "phone_number" ||
        event.target.value === "newUniqueId"
          ? "true"
          : "false",
      auth:
        event.target.value === "phone_number" ||
        event.target.value === "newUniqueId"
          ? "auth"
          : "",
      selectionType: event.target.value,
    });
  };

  handleTextChange = (event) => {
    this.setState({ id: event.target.value });
  };

  onClickSearch = () => {
    let selectionType = this.state.selectionType;
    const params = {
      [selectionType]: this.state.id,
      newUser: this.state.newUser,
      auth: this.state.auth,
    };
    this.setState({ loading: true });

    fetchUserData(params)
      .then((res) => {
        const personDetails = res.data;
        this.setState({
          errorMessage: null,
          loading: false,
          searchCompleted: true,
          userDetails: personDetails,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage:
            "Unauthorized access or network issue. Please refresh the page and try again",
        });
        console.log("error", error);
      });
  };

  render() {
    return (
      <div>
        <h1>Migration</h1>
        <FormControl style={{ marginLeft: 20, marginRight: 10, minWidth: 150 }}>
          <InputLabel htmlFor='grouped-native-select'>Search with</InputLabel>
          <Select native defaultValue='' onChange={this.handleChange}>
            <option aria-label='None' value='' />
            <optgroup label='Old users'>
              <option value={"uniqueId"}>Unique Id</option>
              <option value={"customId"}>Custom Id</option>
            </optgroup>
            <optgroup label='New users'>
              <option value={"phone_number"}>Phone Number</option>
              <option value={"newUniqueId"}>New Unique Id</option>
            </optgroup>
          </Select>
        </FormControl>
        <FormControl style={{ marginLeft: 10, marginRight: 20, minWidth: 150 }}>
          <TextField
            id='standard-basic'
            label='Please type ID'
            onChange={this.handleTextChange}
          />
        </FormControl>
        <Button
          variant='contained'
          color='primary'
          style={{
            marginLeft: 20,
            marginTop: 10,
            marginRight: 20,
          }}
          onClick={this.onClickSearch}
        >
          Search
        </Button>
        {this.state.loading ? (
          <LinearProgress style={{ marginTop: 20 }} />
        ) : null}
        {this.state.errorMessage && <div>{this.state.errorMessage}</div>}
        {this.state.userDetails.user ? (
          <OutlinedCard user={this.state.userDetails.user} />
        ) : this.state.searchCompleted ? (
          <h4>No user exists</h4>
        ) : null}
        {this.state.userDetails.transactions ? (
          <FriendsExpansionPanels
            transactions={this.state.userDetails.transactions}
            user={this.state.userDetails.user}
          />
        ) : null}
      </div>
    );
  }
}

const Migration = compose(withRouter, withFirebase)(Migrationbase);
export default Migration;
