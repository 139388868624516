export const SAME_USER = "same user";
export const LINK_EXPIRED = "Loan invitation expired or removed";
export const INVALID_LOAN = "invalid loan";
export const ALREADY_ADDED = "already added";
export const VIEWER_ROLE = "Viewer";
export const UPDATE_ERROR = "update error";
export const INVALID_INVITATION = "Invalid Invitation";
export const DEFAULT_FIELDS_FOR_SHAREE_LOAN = [
	"owner",
	"transactionId",
	"isPinned",
	"shouldExcludeFromSummary",
	"role",
	"joinedOn",
];
export const EXCLUDE_FIELDS_FOR_SHAREE_LOAN = [
	"isPinned",
	"shouldExcludeFromSummary",
];
