export const TRANSACTION_TYPE_LENT = "Lent";
export const TRANSACTION_TYPE_OWES = "Owes";

export const SECOND_PARTY = "second_party";
export const THIRD_PARTY = "third_party";

export const WEEKLY = "Weekly";
export const BI_WEEKLY = "Bi_Weekly";
export const MONTHLY = "Monthly";
export const BI_MONTHLY = "Bi_Monthly";
export const QUARTERLY = "Quarterly";
export const HALF_YEARLY = "Half_yearly";
export const YEARLY = "Yearly";

export const RFL_MAX_INSTALLMENTS = 1560; /* This corresponds to 30 years of weekly installments */

export const DEFAULT_PAYMENT = "default payment";
export const RECORD_NEW_PAYMENT = "record new payment";
export const PAY_REPAYMENT = "REPAID";
export const EDIT_AND_PAY_REPAYMENT = "PARTIAL";
export const SKIP_REPAYMENT = "SKIPPED";

export const DISPATCH = "Dispatch";
export const EDIT = "Edit";
export const EXPORT = "Export";
export const SHARE = "Share";
export const BADLOAN = "Bad Loan";

export const INTEREST_CALCULATION_STRATEGY = Object.freeze({
  DAY_END: "DAY_END",
  MONTH_END: "MONTH_END",
});

export const INTEREST_BREAKUP_END_OFFSET_INTERVALS = 2;

export const ADD_PAYMENT = "ADD_PAYMENT";
export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const REMOVE_PAYMENT = "REMOVE_PAYMENT";
export const SHAREE_ACCESS_SELF_REVOKE = "SHAREE_ACCESS_SELF_REVOKE";
export const SHAREE_ADDED = "SHAREE_ADDED";
export const SHARER_ACCESS_REVOKE = "SHARER_ACCESS_REVOKE";
export const LOAN_DELETE = "LOAN_DELETE";
