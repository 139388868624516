import React, { memo } from "react";
import {
	APP_BACKGROUND_COLOUR_DARK,
	BACKGROUND_LIGHT,
	BUTTON_OWES_LIGHT_GRADIENT_END,
	BUTTON_OWES_LIGHT_GRADIENT_START,
	MAIN_TEXT_DARK,
	PROGRESS_BAR_LENT_END,
	PROGRESS_BAR_LENT_START,
	VERMILLION_COLOR,
} from "../../constants/colors";
import {
	DynamicStyleSheet,
	DynamicValue,
	useDynamicStyleSheet,
	View,
} from "../react-native/imports";
import LinearGradient from "./LinearGradient";

export const ProgressBar = memo((props) => {
	const {
		width,
		isTintOwes,
		style = {},
		progressStyle = {},
		mode,
		isBadLoan,
		containerStyle = {},
	} = props;
	const styles = useDynamicStyleSheet(dstyles);

	return (
		<LinearGradient
			start={{ x: 0, y: 0 }}
			end={{ x: 1, y: 0 }}
			style={{ ...styles.container, ...style, ...containerStyle }}
			colors={
				isBadLoan
					? [VERMILLION_COLOR, VERMILLION_COLOR]
					: isTintOwes
					? [BUTTON_OWES_LIGHT_GRADIENT_START, BUTTON_OWES_LIGHT_GRADIENT_END]
					: [MAIN_TEXT_DARK, MAIN_TEXT_DARK]
			}
		>
			<View
				style={{
					flexDirection: "row",
				}}
			>
				<LinearGradient
					start={{ x: 0, y: 0 }}
					end={{ x: 1, y: 0 }}
					colors={[PROGRESS_BAR_LENT_START, PROGRESS_BAR_LENT_END]}
					style={{
						...styles.progressBar,
						...containerStyle,
						...progressStyle,
						...{
							width: `${Math.min(width, 100)}%`,
							borderBottomRightRadius:
								width === 100 && mode !== "fromLoanDetails" ? 15 : 0,
							borderBottomLeftRadius:
								width === 100 && mode !== "fromLoanDetails" ? 15 : 0,
						},
					}}
				/>
			</View>
		</LinearGradient>
	);
});

const dstyles = new DynamicStyleSheet({
	container: {
		height: 3,
		flex: 1,
	},
	progressBar: {
		height: 3,
		borderBottomLeftRadius: 15,
	},
	logoImageStyle: {
		width: 15,
		height: 15,
		marginTop: -15,
		marginLeft: -15,
		backgroundColor: new DynamicValue(
			BACKGROUND_LIGHT,
			APP_BACKGROUND_COLOUR_DARK
		),
	},
});

export default ProgressBar;
