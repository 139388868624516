export const LENT = "#45C4B9";
export const OWES = "#EF8372";

export const BACKGROUND_LIGHT = "#F3F3F3";
export const BACKGROUND_DARK = "rgb(0,0,0)";

export const MAIN_TEXT_LIGHT = "rgba(0, 0, 0, 0.85)";
export const MAIN_TEXT_DARK = "rgb(184,184,184)";

export const SUB_TEXT_LIGHT = "darkgray";
export const SUB_TEXT_DARK = "rgba(255,255,255,0.5)";

export const LOADER_LIGHT = "black";
export const LOADER_DARK = "white";

export const PROGRESS_BAR_LENT_GRADIENT_END = "#6DD400";

export const PROGRESS_BAR_OWES_GRADIENT_START = "#E02020";
export const PROGRESS_BAR_OWES_GRADIENT_END = "#FA6400";

export const BUTTON_LIGHT = "#44D7B6";
export const BUTTON_LENT_LIGHT_GRADIENT_START = "#3CD3AD";
export const BUTTON_LENT_LIGHT_GRADIENT_END = "#4CB8C4";
export const BUTTON_LENT_LIGHT_BORDER = "#44C6B9";

export const BUTTON_OWES_LIGHT_GRADIENT_START = "rgba(224, 32, 32, .7)"; //'#fa8a7d'; //'#F7A89F';
export const BUTTON_OWES_LIGHT_GRADIENT_END = "rgba(250, 100, 0, .7)"; //'#EC6F66';
export const BUTTON_OWES_LIGHT_BORDER = "#F3877B";

export const BUTTON_DISABLED = "rgba(68, 215, 182, 0.25)";
export const BUTTON_DISABLED_OWES = "rgba(239, 131, 114, 0.5)";
export const BUTTON_BORDER = "#979797";
export const BUTTON_BORDER_ACTIVE = "#3AD8B6";

export const WHITE = "white";
export const SETTINGS_BG_COLOUR = "#D8D8D8";
export const TRANSPARENT = "transparent";
export const MAIN_TITLE_TEXT = "#474747";
export const LIGHT_GRAY = "lightgray";
export const DARK_GRAY = "darkgray";
export const APP_BACKGROUND_COLOUR = "rgba(255,255,255,0.05)";
export const APP_BACKGROUND_COLOUR_DARK = "rgb(45,45,45)";
export const DARK_GREY_BLUE = "rgb(25,49,60)";
export const LIGHT_GREY_BLUE = "rgba(25,49,60,0.6)";

// Forms related constants
export const TEXT_FIELD_DEFAULT_BORDER = "#19313C";
export const TEXT_FIELD_LENT_SELECTED_BORDER = "rgba(68, 215, 182, 0.75)";
export const TEXT_FIELD_OWES_SELECTED_BORDER = "rgba(243, 135, 123,0.75)";
export const TEXT_FIELD_ERROR_BORDER = "#C70000";

//Empty Dashboard constants
export const LIGHT_GREY_TEXT_COLOR = "rgb(71,71,71)";
export const LIGHT_GREY_SUBTEXT_COLOR = "rgb(151,151,151)";
export const DASHBOARD_LENT_PIE_CHART_END = "rgb(250,100,10)";
export const DASHBOARD_LENT_PIE_CHART_MIDDLE = "rgb(224,32,32)";
export const DASHBOARD_LENT_PIE_CHART_START = "rgb(224,32,32)";

//Transactions
export const OVERDUE_RED = "rgb(233,99,99)";

export const SCHEDULED_BORDER_COLOR = "#B6B6B6";

//DarkMode Supported colours:
export const DARK_MODE_WHITE_BORDER_COLOR = "rgba(255,255,255, 0.5)";

export const NAVBAR_BACKGROUND_DARK = "rgb(32,32,32)";

export const SEGMENTED_INACTIVE_BACKGROUND_DARK = "rgb(32,32,32)";
export const SEGMENTED_INACTIVE_BACKGROUND_LIGHT = "rgb(216,216,216)";
export const SEGMENTED_INACTIVE_TEXT_LIGHT = "rgb(71,71,71)";
export const SEGMENTED_ACTIVE_TEXT_LIGHT = "rgb(25, 49, 60)";
export const CONNECTING_LINE_DARK = "rgba(255,255,255,.25)";

export const VERMILLION_COLOR = "rgb(224, 32, 32)";
export const VERMILLION_COLOR_BADLOAN = "rgba(224, 32, 32, 0.8)";

//Table colors
export const TABLE_HEADER_COLOR = "rgb(68, 215, 182)";
export const TABLE_ROW_COLOR1 = "rgb(250,250, 250)";
export const TABLE_ROW_COLOR2 = "rgb(234, 234, 234)";
export const MODAL_WHITE = "rgb(247, 247, 247)";

//ProgressBar Gradients
export const PROGRESS_BAR_LENT_START = "rgb(68, 215, 182)";
export const PROGRESS_BAR_LENT_END = "rgb(109, 212, 0)";

export const GREY_PROGRESSS_COLOUR = "rgba(255, 255, 255, 0.5)";

//Shadow Colors
export const SHADOW_BLACK50 = "rgba(0,0,0,.25)";
