import {get} from 'lodash';
import moment from 'moment';
import {auth, database} from '../../utils/firebase';

export const currentUserId = () => get(auth(), 'currentUser.uid');
const anonymousUser = () => get(auth(), 'currentUser.isAnonymous');

export const userType = () => (anonymousUser() ? 'noAuth' : 'auth');

export const userPath = () => `/v2/users/${userType()}/${currentUserId()}/`;

export const loansPath = () =>
  `/v2/transactions/${userType()}/${currentUserId()}/`;

export const remoteLoanPath = (userId, userType, transactionId) =>
  `/v2/transactions/${userType}/${userId}/${transactionId}/`;

export const buddyPath = (userId, buddyId) =>
  `/v2/userBuddies/${userId}/${buddyId}`;

export const newLoanPath = () => {
  const newLoanKey = database().ref(loansPath()).push().key;
  return {
    loanPath: `${loansPath()}${newLoanKey}`,
    loanId: newLoanKey,
  };
};

export const userPushNotificationTokensPath = () =>
  `${userPath()}pushNotificationTokens/`;

export const userSharedLoanByLoanPath = (ownerId, transactionId) =>
  `/v2/sharedLoans/byLoan/${ownerId}${
    transactionId ? `/${transactionId}` : ''
  }`;

export const userSharedLoanByUserPath = (collaboratorId) =>
  `/v2/sharedLoans/byUser/${collaboratorId}`;

export const loanInvitationsPath = () => '/v2/loanInvitations/';

export const newLoanInvitationsPath = () => {
  const newLoanInvitationKey = database().ref(loanInvitationsPath()).push().key;
  return {
    loanInvitationPath: `${loanInvitationsPath()}${newLoanInvitationKey}`,
    loanInvitationId: newLoanInvitationKey,
  };
};

export const existingLoanInvitationPath = (invitationId) => {
  const loanInvitationPath = `${loanInvitationsPath()}${invitationId}`;
  return loanInvitationPath;
};

export const existingLoanPath = (loanId) => {
  const loanPath = `${loansPath()}${loanId}`;
  return loanPath;
};

export const dispatchPath = (transactionId) =>
  `${existingLoanPath(transactionId)}/dispatchInfo/`;

export const newDispatchPath = (transactionId) => {
  const newDispatchKey = database().ref(dispatchPath(transactionId)).push().key;
  return {
    dispatchPath: `${dispatchPath(transactionId)}${newDispatchKey}`,
    dispatchId: newDispatchKey,
  };
};

export const newLoanAttachmentPath = (
  friendName,
  transactionId,
  isBadLoan = false,
) => {
  const path = `/Users/${currentUserId()}/Transactions/${transactionId}/${friendName}/${
    isBadLoan ? 'badLoanAttachments' : 'attachments'
  }/${moment().valueOf() / 1000}`;
  return path;
};

export const newPaymentAttachmentPath = (
  friendName,
  transactionId,
  isDispatch = false,
) => {
  const path = `Users/${currentUserId()}/Transactions/${transactionId}/${friendName}/${
    isDispatch ? 'dispatches' : 'payments'
  }/attachments/${moment().valueOf() / 1000}`;
  return path;
};

export const newUserPath = () => {
  const path = `/Users/${currentUserId()}/imageURL`;
  return path;
};

export const currencyConversionMatrixPath =
  '/v2/currencyConversionMatrix/dataMatrix';

//Migrated users

export const v1userPath = (userId) => {
  const userPath = `/v1/users/${userId}/`;
  return userPath;
};

export const v1TransactionPath = (userId) => {
  const transactionPath = `/v1/transactions/${userId}`;
  return transactionPath;
};

export const v2UserPath = (userId) => {
  const userPath = `/v2/users/${anonymousUser() ? 'noAuth' : 'auth'}/${userId}`;
  return userPath;
};

export const v2TransactionPath = (userId) => {
  const transPath = `/v2/transactions/${
    anonymousUser() ? 'noAuth' : 'auth'
  }/${userId}`;
  return transPath;
};

export const v2NoAuthUserPath = (userId) => {
  const userPath = `/v2/users/noAuth/${userId}`;
  return userPath;
};

export const v2NoAuthTransactionPath = (userId) => {
  const transPath = `/v2/transactions/noAuth/${userId}`;
  return transPath;
};
export const v2AuthUserPath = (userId) => {
  return `/v2/users/auth/${userId}`;
};

export const v2AuthTransactionPath = (userId) => {
  return `/v2/transactions/auth/${userId}`;
};

export const backUpUserPath = (userId) => {
  const userPath = `/v2/backup/users/${userId}`;
  return userPath;
};

export const backUpTransPath = (userId) => {
  const transPath = `/v2/backup/transactions/${userId}`;
  return transPath;
};

export const userMigrationPath = (userId) => {
  const userPath = `/v2/userMigrations/${userId}`;
  return userPath;
};

export const newUserMigrationPath = (userId) => {
  const newLoanKey = database().ref(userMigrationPath(userId)).push().key;
  return `${userMigrationPath(userId)}/${newLoanKey}`;
};

export const backUpSettleUpsPath = (userId) => {
  const settleUpPath = `/v2/backup/settleUpInfo/${userId}`;
  return settleUpPath;
};

export const v2AuthSettleUpsPath = (userId) => {
  return `/v2/settleUpInfo/auth/${userId}`;
};

export const v2NoAuthSettleUpsPath = (userId) => {
  return `/v2/settleUpInfo/noAuth/${userId}`;
};

export const v2SharedLoansByUser = (collaboratorId) => {
  return `/v2/sharedLoans/byUser/${collaboratorId}`;
};

export const v2SharedLoansByLoan = (ownerId, loanId, collaboratorId) => {
  return `/v2/sharedLoans/byLoan/${ownerId}/${loanId}/collaborators/`;
};
export const settleUpInfoPath = () => {
  const settleUpUserPath = `/v2/settleUpInfo/${
    anonymousUser() ? 'noAuth' : 'auth'
  }/${currentUserId()}/`;
  return settleUpUserPath;
};

export const sharerSettleUpInfoPath = (ownerId, userType, settleUpId) => {
  const path = `/v2/settleUpInfo/${userType}/${ownerId}/${settleUpId}`;
  return path;
};

export const newSettleUpInfoPath = () => {
  const newSettleUpKey = database().ref(settleUpInfoPath()).push().key;
  return {
    settleUpInfoPath: `${settleUpInfoPath()}${newSettleUpKey}`,
    settleUpId: newSettleUpKey,
  };
};

export const existingSettleUpInfoPath = (settleUpId) => {
  const loanPath = `${settleUpInfoPath()}${settleUpId}`;
  return loanPath;
};

export const pushNotificationsTriggerPath = () =>
  '/v2/pushNotifications/triggers/';

export const newPushNotificationsTriggerPath = () => {
  const newKey = database().ref(pushNotificationsTriggerPath()).push().key;
  return `${pushNotificationsTriggerPath()}${newKey}`;
};
