import moment from 'moment';
import {DDMMM_COMMA_YYYY} from '../constants/dateFormats';

export const formatDate = ({
  millis,
  format = DDMMM_COMMA_YYYY,
  defaultValue,
}) => {
  return millis ? moment(millis * 1000).format(format) : defaultValue || '';
};
