import {
  COMPOUND_INTEREST,
  SIMPLE_INTEREST,
  latestReferenceDate,
} from "./LoanUtils";

import moment from "moment";
import { round } from "lodash";

export const calculateInterest = (loan) => {
  const startDay = moment(latestReferenceDate(loan) * 1000).startOf("day");
  const endDay = moment(loan.dueDate && loan.dueDate * 1000).startOf("day");
  let days = endDay.diff(startDay, "days");
  let t = days < 0 ? 0 : days / 365;
  const interestRate = loan.interestRate || 0;

  switch (loan.interestMode) {
    case SIMPLE_INTEREST:
      let numberOfDaysInterest =
        ((loan.principleBalance * interestRate) / 100) * t;
      let amount =
        (numberOfDaysInterest || 0) +
        loan.principleBalance +
        (loan.lastRemainingInterest || 0);
      return round(amount, 2);

    case COMPOUND_INTEREST:
      let numberOfParts = getNumberOfPartsOfCompoundingLoan(loan.interestType);
      let interestAmount = 1 + interestRate / (100 * numberOfParts);
      let currentPrincipal =
        (loan.principleBalance || 0) + (loan.lastRemainingInterest || 0);
      let finalAmount =
        currentPrincipal * Math.pow(interestAmount, numberOfParts * t);

      // let finalInterest = finalAmount - currentPrincipal;
      // finalInterest += loan.lastRemainingInterest || 0;
      return round(finalAmount + (loan.lastRemainingInterest || 0), 2);
    default:
      break;
  }
};

function getNumberOfPartsOfCompoundingLoan(interestType) {
  return {
    Monthly: 12,
    Quarterly: 4,
    Half_yearly: 2,
    Annually: 1,
    Yearly: 1,
  }[interestType];
}

export const calculateSimpleInterest = (
  principal,
  interestRate,
  fromDate,
  toDate
) => {
  const startDay = moment(fromDate * 1000).startOf("day");
  const endDay = moment(toDate * 1000).startOf("day");

  const daysElapsed = endDay.diff(startDay, "days") || 0;
  const t = daysElapsed < 0 ? 0 : daysElapsed / 365;

  const interest = round((principal * t * interestRate) / 100, 2);
  return interest;
};

/**
 * Simple interest calculation for a month
 * @param {*} principal
 * @param {*} interestRate
 */
export const calculateSimpleInterestIntervalEnd = (principal, interestRate) => {
  const t = 1;

  const interest = round((principal * t * interestRate) / 12 / 100, 2);
  return interest;
};

export const calculateCompoundInterest = (
  principal,
  interestRate,
  fromDate,
  toDate,
  numberOfParts
) => {
  const startDay = moment(fromDate * 1000).startOf("day");
  const endDay = moment(toDate * 1000).startOf("day");

  const daysElapsed = endDay.diff(startDay, "days") || 0;
  const t = daysElapsed < 0 ? 0 : daysElapsed / 365;

  const interestAmount = 1 + interestRate / (100 * numberOfParts);
  const finalAmount = principal * Math.pow(interestAmount, numberOfParts * t);

  return finalAmount - principal;
};
