import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: "50%",
    marginTop: 20
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

export default function OutlinedCard(props) {
  const classes = useStyles();
  let details = props.user;
  return (
    <Card className={classes.root} variant="outlined">
      <h4 style={{ marginLeft: 20, marginBottom: 0 }}>User Details</h4>
      <CardContent>
        <CardActions style={{ marginTop: -10 }}>
          <Typography className={classes.pos} color="textSecondary">
            Code:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.code}
          </Typography>
          <Typography
            className={classes.pos}
            color="textSecondary"
            style={{ marginLeft: 100 }}
          >
            Currency:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.currency}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            Custom Id:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.customId === undefined ? "-" : details.customId}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            Firebase User Id::
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.firebaseUserId}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20, marginBottom: -30 }}>
          <Typography className={classes.pos} color="textSecondary">
            IAP TransactionId::
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.iapTransactionId === undefined
              ? "-"
              : details.iapTransactionId}
          </Typography>
        </CardActions>
      </CardContent>
    </Card>
  );
}
