import { mapValues } from "lodash";
import React from "react";
import "../SharedLoanV3/index.css";

export const View = ({ id, style = {}, children, className, ...rest }) => {
  const { shadowOffset, shadowColor } = style;
  let shadowStyle = {};

  if (shadowOffset) {
    shadowStyle = {
      boxShadow: `1px 1px 5px ${shadowColor}`,
    };
  }
  return (
    <div
      id={id}
      style={{ ...style, ...shadowStyle }}
      className={`view-default ${className || ""}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export const Text = ({ className, children, numberOfLines, ...rest }) => (
  <span
    className={`text-default ${className || ""} ${
      numberOfLines ? "single-line" : ""
    }`}
    {...rest}
  >
    {children}
  </span>
);

export const TouchableOpacity = ({
  style = {},
  onPress,
  className,
  children,
  ...rest
}) => (
  <button
    style={style}
    className={`button-default ${className || ""}`}
    onClick={onPress}
    {...rest}
  >
    {children}
  </button>
);

export const Image = ({
  image,
  style,
  source,
  children,
  className,
  ...rest
}) => (
  <img
    src={source || image}
    {...rest}
    alt=""
    className={className}
    style={style}
  >
    {children}
  </img>
);

export class DynamicValue {
  constructor(val1, val2) {
    this.val1 = val1;
    this.val2 = val2;
  }
}

export const useDynamicValue = (val1, val2) => {
  return val1;
};

export class DynamicStyleSheet {
  constructor(dict) {
    this.dict1 = mapValues(dict, (stylesDict) => {
      return mapValues(stylesDict, (value) =>
        value instanceof DynamicValue ? value.val1 : value
      );
    });

    this.dict2 = mapValues(dict, (stylesDict) => {
      return mapValues(stylesDict, (value) =>
        value instanceof DynamicValue ? value.val2 : value
      );
    });
  }
}

export const useDynamicStyleSheet = (styleSheet) => {
  return styleSheet.dict1;
};

export const useDarkMode = (val1, val2) => {
  return val1;
};

export const DarkModeProvider = View;

export const eventEmitter = {
  on: () => {},
};

export const SectionList = ({
  sections = [],
  keyExtractor,
  renderItem: mainRenderItem,
  renderSectionHeader = () => <View />,
}) => {
  return (
    <View style={{ width: "100%" }}>
      {sections.map((section, sectionIndex) => {
        const { title, data, renderItem } = section;
        return (
          <View>
            {renderSectionHeader({ title, data, section, index: sectionIndex })}
            <View key={sectionIndex}>
              {data.map((item, index) => {
                return (
                  <View key={!keyExtractor ? index : keyExtractor(item, index)}>
                    {!renderItem
                      ? mainRenderItem({ item, index })
                      : renderItem({ item, index })}
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
    </View>
  );
};
