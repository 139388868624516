import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./index.css";

const GIVEN = "Given";
const TAKEN = "Taken";

const DebtsBox = ({ item, mode }) => {
	return (
		<div
			className={`debts-box ${
				mode === GIVEN ? "border-given" : "border-taken"
			}`}
		>
			<div className="title">{item.count}</div>
			<div className="subtitle">
				Active Debts {mode}: <span className="amount">{item.activeCount}</span>
			</div>
		</div>
	);
};

export default ({ summary }) => {
	const state = {
		labels: ["Taken", "Given"],
		datasets: [
			{
				label: "Summary",
				backgroundColor: ["#fa6400", "#44d7b6"],
				hoverBackgroundColor: ["#fa6400", "#44d7b6"],
				data: [
					summary.takenLoans.totalAmount || 1,
					summary.givenLoans.totalAmount || 1,
				],
			},
		],
	};

	return (
		<div className="full-summary">
			<div className="full-summary-left">
				<div className="pie-chart">
					<Doughnut
						data={state}
						options={{
							animation: false,
							legend: {
								display: false,
								position: "right",
							},
							rotation: 1 * Math.PI,
							circumference: 1 * Math.PI,
							cutoutPercentage: 70,
						}}
					/>
				</div>
				<div className="full-summary-balance">
					<div className="full-summary-row-child-title">Balance</div>
					<div
						className={
							summary.takenLoans.totalAmount > summary.givenLoans.totalAmount
								? "full-summary-row-child-taken"
								: "full-summary-row-child-given"
						}
					>
						{summary.formattedNetBalance}
					</div>
				</div>
				<div className="full-summary-row">
					<div className="left-child">
						<div className="full-summary-row-child-title">Debt Taken</div>
						<div className="full-summary-row-child-taken">
							{summary.takenLoans.formattedTotalAmount}
						</div>
					</div>
					<div className="right-child">
						<div className="full-summary-row-child-title">Debt Given</div>
						<div className="full-summary-row-child-given">
							{summary.givenLoans.formattedTotalAmount}
						</div>
					</div>
				</div>
			</div>
			<div className="full-summary-right">
				<DebtsBox item={summary.givenLoans} mode={GIVEN} />
				<DebtsBox item={summary.takenLoans} mode={TAKEN} />
			</div>
		</div>
	);
};
