const backendHosts = {
  dev: "http://localhost:3002",
  prod: "http://192.168.0.148:3010",
};

const environment = "prod";

const firebaseConfigs = {
  dev: {
    apiKey: "AIzaSyDAG4gc06UAZq_gmvWl2dVXecIEJKq3LzQ",
    authDomain: "devtlloanapp-3d093.firebaseapp.com",
    databaseURL: "https://devtlloanapp-3d093.firebaseio.com",
    projectId: "devtlloanapp-3d093",
    storageBucket: "devtlloanapp-3d093.appspot.com",
    messagingSenderId: "1062454949888",
    appId: "1:1062454949888:web:f6c3ea837a82681f2a80e5",
  },

  prod: {
    apiKey: "AIzaSyCEW8a8HSP5HVCcBqn_BaBzb_cM30WT81g",
    authDomain: "tlloanapp-d0571.firebaseapp.com",
    databaseURL: "https://tlloanapp-d0571.firebaseio.com",
    projectId: "tlloanapp-d0571",
    storageBucket: "tlloanapp-d0571.appspot.com",
    messagingSenderId: "875949104053",
    appId: "1:875949104053:web:d26e0bf56fa56e53e8d7fc",
  },
};

export const HOST = backendHosts[environment];
export const FIREBASE_CONFIG = firebaseConfigs[environment];
