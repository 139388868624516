import { find, get, has, last, map, values } from "lodash";

import { DISPATCH_MODE } from "./constants";
import React from "react";
import { VERMILLION_COLOR } from "../../constants/colors";

const MODE_PAYMENTS = "PAYMENTS";
const MODE_SCHEDULE = "SCHEDULE";

const statusString = {
  Paid: "Paid",
  Default: "Default",
  scheduled: "To be paid",
  lumpsum: "Paid as lumpsum",
};

const DFStatusString = {
  paid: "Paid",
  skip: "Skipped",
  pending: "To be paid",
  partial: "Partially collected",
};

export const ItemsList = ({
  loan,
  mode,
  showRepaymentNotes,
  showLoanNotes,
  userId,
}) => {
  const hasInterest = !!loan.interestRate && loan.interestRate > 0;
  const isPaymentsMode = mode === MODE_PAYMENTS;
  const isFormalLoan = get(loan, "isFormalLoan", false);
  const isDailyFinance = get(loan, "isDailyFinance", false);
  const isDispatchMode = mode === DISPATCH_MODE;

  const isBadLoan = has(loan, "badLoanInfo") && !has(loan, "isSharedLoan");

  const dispatchItems = isDispatchMode ? get(loan, "dispatches", []) : [];

  const paymentItems = isDispatchMode
    ? []
    : isPaymentsMode
    ? loan.paidInfo
    : isDailyFinance
    ? loan.schedule
    : isFormalLoan
    ? loan.schedule
    : loan.recurringPaymentInfo.schedule;

  const isShowNotesColum =
    (isPaymentsMode &&
      showRepaymentNotes &&
      find(values(loan.paidInfo), (payment) => payment.notes)) ||
    (isDispatchMode &&
      showLoanNotes &&
      find(dispatchItems, (dispatch) => dispatch.notes));

  return (
    <div className="paymentsReceived">
      <div className="paymentData">
        <table>
          <thead>
            <tr>
              <th>
                {isFormalLoan && !isPaymentsMode ? "Installment No." : "S.No"}
              </th>
              <th>
                {isDispatchMode ? "Dispatched Date" : "Date of Repayment"}
              </th>
              {(isFormalLoan || isDailyFinance) && !isPaymentsMode ? (
                <th>Principal Before Payment</th>
              ) : null}
              <th>
                {isDispatchMode
                  ? "Dispatched Amount"
                  : isFormalLoan && !isPaymentsMode
                  ? "Total EMI Paid"
                  : "Repayment Amount"}
              </th>
              {!isDispatchMode && hasInterest && (
                <th>
                  {isPaymentsMode ? "Principal Cleared" : "Towards Principal"}
                </th>
              )}
              {!isDispatchMode && hasInterest && (
                <th>
                  {isPaymentsMode ? "Interest Cleared" : "Towards Interest"}
                </th>
              )}
              {userId === "c5UpjiokQNSMgcJUMCnBo8luhfz1" ? null : (
                <th>
                  <th>
                    {isDispatchMode
                      ? "Updated Principal"
                      : "Principal Remaining"}
                  </th>
                </th>
              )}
              {isShowNotesColum && <th>Notes</th>}
              {(isFormalLoan || isDailyFinance) && !isPaymentsMode ? (
                <th>Status</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {map(paymentItems, (payment, index) => {
              const getPaymentStatusClass = isDailyFinance
                ? {
                    paid: "paidStatus",
                    skip: "defaultStatus",
                    pending: "pendingStatus",
                    partial: "partialStatus",
                  }
                : {
                    Paid: "paidStatus",
                    Default: "defaultStatus",
                    scheduled: "pendingStatus",
                    lumpsum: "lumpSumStatus",
                  };
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className="textLeft textWhiteSpaceNowrap">
                    {payment.paidOnString}
                  </td>
                  {(isFormalLoan || isDailyFinance) && !isPaymentsMode ? (
                    <td className="amount">
                      {payment.formattedPrincipalBalBefore}
                    </td>
                  ) : null}
                  <td className="amount">{payment.formattedAmount}</td>
                  {hasInterest && (
                    <td className="amount">
                      {payment.formattedTowardsPrincipal}
                    </td>
                  )}
                  {hasInterest && (
                    <td className="amount">
                      {payment.formattedTowardsInterest}
                    </td>
                  )}
                  {userId === "c5UpjiokQNSMgcJUMCnBo8luhfz1" ? null : (
                    <td className="amount">
                      {payment.formattedPrincipalBalAfter}
                    </td>
                  )}
                  {isPaymentsMode && isShowNotesColum && (
                    <td className="notes">{payment.notes}</td>
                  )}
                  {(isFormalLoan || isDailyFinance) && !isPaymentsMode ? (
                    <td
                      className={`textWhiteSpaceNowrap ${
                        getPaymentStatusClass[payment.paidStatus] || ""
                      }`}
                      style={
                        payment?.paidStatus === "pending" && isBadLoan
                          ? { color: VERMILLION_COLOR }
                          : {}
                      }
                    >
                      {isDailyFinance
                        ? payment?.paidStatus === "pending" && isBadLoan
                          ? "Defaulted"
                          : DFStatusString[payment.paidStatus]
                        : statusString[payment.paidStatus]}
                    </td>
                  ) : null}
                </tr>
              );
            })}
            {map(dispatchItems, (dispatch, index) => (
              <tr>
                <td>{index + 1}</td>
                <td className="textLeft textWhiteSpaceNowrap">
                  {dispatch.dispatchedOnString}
                </td>
                <td className="amount">{dispatch.amountString}</td>
                <td className="amount">{dispatch.updatedPrincipalString}</td>
                {isShowNotesColum ? (
                  <td className="notes">{dispatch.notes}</td>
                ) : null}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {isDispatchMode ? (
              <tr>
                <td />
                <td className="emphasised">Total</td>
                <td className="amount emphasised">
                  {last(loan.dispatches).updatedPrincipalString}
                </td>
                <td />
                {isShowNotesColum ? <td /> : null}
              </tr>
            ) : (
              <>
                <tr>
                  <td></td>
                  <td className="emphasised">Total</td>
                  {(isFormalLoan || isDailyFinance) && !isPaymentsMode ? (
                    <td></td>
                  ) : null}
                  <td className="amount emphasised">
                    {isPaymentsMode
                      ? loan.formattedAmountPaid.value
                      : loan.formattedAmountPaidScheduled}
                  </td>
                  {hasInterest && (
                    <td className="amount emphasised">
                      {isPaymentsMode
                        ? loan.formattedPrincipalPaid
                        : loan.formattedPrincipalPaidScheduled}
                    </td>
                  )}
                  {hasInterest && (
                    <td className="amount emphasised">
                      {isPaymentsMode
                        ? loan.formattedAccruedInterest
                        : loan.formattedAccruedInterestScheduled}
                    </td>
                  )}
                  <td></td>
                  {isPaymentsMode && isShowNotesColum && <td></td>}
                  {isFormalLoan && !isPaymentsMode ? <td></td> : null}
                </tr>
                <tr>
                  <td></td>
                  <td className="emphasised">
                    {userId === "c5UpjiokQNSMgcJUMCnBo8luhfz1"
                      ? null
                      : "Remaining"}
                  </td>
                  {isFormalLoan && !isPaymentsMode ? <td></td> : null}
                  <td></td>
                  {hasInterest && <td></td>}
                  {hasInterest && <td></td>}
                  <td className="amount emphasised">
                    {userId === "c5UpjiokQNSMgcJUMCnBo8luhfz1"
                      ? null
                      : isPaymentsMode
                      ? loan.formattedPrincipalBalance.value
                      : loan.formattedPrincipalBalanceScheduled}
                  </td>
                  {isPaymentsMode && isShowNotesColum && <td></td>}
                  {isFormalLoan && !isPaymentsMode ? <td></td> : null}
                </tr>
              </>
            )}
          </tfoot>
        </table>
      </div>
    </div>
  );
};

const recurringTypeMap = {
  tenure: "Fixed Tenure",
  payment: "Fixed Payment",
  manual: "Manual Payments",
};

export default ({ loan, options, userId }) => {
  const isFormalLoan = loan.isFormalLoan;
  const isDailyFinance = loan.isDailyFinance;

  const hasRepaymentSchedule =
    isFormalLoan ||
    isDailyFinance ||
    (!loan.isSharedLoan &&
      loan.loanStatus !== "Closed" &&
      loan.recurringPaymentInfo &&
      loan.recurringPaymentInfo.schedule &&
      loan.recurringPaymentInfo.schedule.length > 0);

  const { repaymentNotes, paymentsType, includeRepayments } = options;

  const shouldShowPayments = includeRepayments && paymentsType !== "Scheduled";
  const shouldShowSchedule = paymentsType !== "History";

  return (
    <>
      {shouldShowPayments && (
        <div className="repayments-schedule">
          <div className="title">
            Repayments as part of this Debt:{" "}
            <b style={{ color: VERMILLION_COLOR }}>
              {has(loan, "badLoanInfo") && !has(loan, "isSharedLoan")
                ? "(Marked as bad loan)"
                : ""}
            </b>
          </div>
          {loan.paidInfo && values(loan.paidInfo).length > 0 ? (
            <ItemsList
              loan={loan}
              mode={MODE_PAYMENTS}
              showRepaymentNotes={repaymentNotes}
              userId={userId}
            />
          ) : (
            <div className="no-repayments">
              <div>→</div>
              <div>
                {"  "}You do not have any repayments as a part of this Debt. You
                can try out our “Repayment Scheduler” from Loan Details screen
                to repay your loan in easy installments.
              </div>
            </div>
          )}
        </div>
      )}
      {shouldShowSchedule && hasRepaymentSchedule && (
        <div className="repayments-schedule">
          <div className="title">
            {isFormalLoan
              ? "Amortization table:"
              : isDailyFinance
              ? "Schedule:"
              : `Scheduled Repayments (
            ${recurringTypeMap[loan.recurringPaymentInfo.recurringType]})`}
          </div>
          <ItemsList loan={loan} mode={MODE_SCHEDULE} userId={userId} />
        </div>
      )}
    </>
  );
};
