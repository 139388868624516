import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
	BACKGROUND_DARK,
	BUTTON_LENT_LIGHT_BORDER,
	BUTTON_OWES_LIGHT_BORDER,
	DARK_GREY_BLUE,
	LENT,
	MAIN_TEXT_DARK,
	OVERDUE_RED,
	OWES,
	SHADOW_BLACK50,
	VERMILLION_COLOR,
	WHITE,
} from "../../constants/colors";
import { REGULAR } from "../../constants/fonts";
import { TRANSACTION_TYPE_LENT } from "../../constants/LoanConstants";
import { format } from "../../utils/CurrencyFormatter";
import { formatDate } from "../../utils/DateFormatter";
import {
	DynamicStyleSheet,
	DynamicValue,
	Image,
	Text,
	useDynamicStyleSheet,
	useDynamicValue,
	View,
} from "../react-native/imports";

const Row = ({ left, right, style = {} }) => {
	return (
		<View style={{ ...style, flexDirection: "row", alignItems: "center" }}>
			<View>{left}</View>
			<View style={{ marginLeft: 12 }}>{right}</View>
		</View>
	);
};

const dStyles = new DynamicStyleSheet({
	container: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		minHeight: 40,
		width: "100%",
		backgroundColor: new DynamicValue(WHITE, BACKGROUND_DARK),
	},
	secondRowText: {
		color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
		fontFamily: REGULAR,
		fontSize: 13,
		marginTop: 6,
		opacity: 0.7,
		letterSpacing: 0.76,
		textAlign: "center",
		alignSelf: "center",
	},
	firstRowText: {
		fontFamily: REGULAR,
		fontSize: 13,
		letterSpacing: 0.76,
	},
	textContainer: {
		marginLeft: 15,
	},
	dropDownImage: {
		marginRight: 15,
	},
	borderBottom: {
		height: 1,
		backgroundColor: "#D3D3D3",
	},
	//footer styles
	settleUpView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		backgroundColor: new DynamicValue(WHITE, BACKGROUND_DARK),
	},
	settleupTextView: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	settleUpText: {
		color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
		fontFamily: REGULAR,
		fontSize: 13,
		opacity: 0.7,
		padding: 10,
	},
	accordionContainerStyles: {
		marginHorizontal: 15,
		borderRadius: 6,
		marginBottom: 10,
		overflow: "hidden",
		shadowOpacity: 0.5,
		shadowRadius: 4,
		shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,0.5)"),
		shadowOffset: { width: 0, height: 1 },
		elevation: 4,
	},
	badLoanContainer: {
		margin: 10,
	},
	badLoanTextStyle: { padding: 5 },
});

const PaymentHeader = ({
	payment,
	activeSections,
	loanType,
	currency,
	isSettleUp,
	isScheduled,
	isOverDue,
	fromModal,
	isBadLoan,
}) => {
	const styles = useDynamicStyleSheet(dStyles);

	const dropDownImage = useDynamicValue(
		require("../../assets/paymentHeader-light-black.png"),
		require("../../assets/paymentHeader-light-white.png")
	);
	const dropDownImageUp = useDynamicValue(
		require("../../assets/paymentHeader-up-black.png"),
		require("../../assets/paymentHeader-up-white.png")
	);

	const [rightImage, setRightImage] = useState(dropDownImage);

	useEffect(() => {
		if (activeSections.length > 0) {
			setRightImage(dropDownImageUp);
		} else {
			setRightImage(dropDownImage);
		}
	}, [activeSections, dropDownImage, dropDownImageUp]);

	const topTextStyle = () => {
		return {
			color: isBadLoan
				? VERMILLION_COLOR
				: isOverDue
				? OVERDUE_RED
				: loanType === TRANSACTION_TYPE_LENT
				? BUTTON_LENT_LIGHT_BORDER
				: BUTTON_OWES_LIGHT_BORDER,
			opacity: 1,
		};
	};

	const leftTextStyle =
		isBadLoan || payment.dispatchId ? { ...topTextStyle() } : {};

	return (
		<View style={styles.container}>
			<View style={styles.textContainer}>
				<Row
					left={
						<Text style={{ ...styles.firstRowText, ...topTextStyle() }}>
							{format(payment.amount, currency)}
						</Text>
					}
					right={
						<Text style={{ ...styles.firstRowText, ...topTextStyle() }}>
							{(payment.dispatchId
								? `Debt ${
										loanType === TRANSACTION_TYPE_LENT ? "given" : "taken"
								  } `
								: isBadLoan
								? "Bad Loan "
								: isScheduled
								? "To be paid "
								: "Paid ") +
								`on ${formatDate({
									millis: payment.dispatchId
										? payment.dispatchedOn
										: payment.paidOn,
								})}`}
						</Text>
					}
				/>
				<Row
					left={
						<Text
							style={{
								...styles.secondRowText,
								...{ letterSpacing: 0.76 },
								...leftTextStyle,
							}}
						>
							{format(
								!!payment.dispatchId
									? payment.principalOutStanding
									: payment.principleBalAfter < 1
									? 0
									: payment.principleBalAfter,
								currency
							)}
						</Text>
					}
					right={
						<Text style={styles.secondRowText}>
							{!!payment.dispatchId
								? "Principal Balance"
								: `Balance ${isScheduled ? "will remain" : "Remaining"}`}
						</Text>
					}
				/>
				{activeSections.length > 0 && (
					<View style={{ ...styles.borderBottom, marginTop: 6 }} />
				)}
			</View>
			{!fromModal && <Image image={rightImage} style={styles.dropDownImage} />}
		</View>
	);
};

const PaymentContent = ({
	payment,
	currency,
	isSettleUp,
	isScheduled,
	isFirstPayment,
	changeModalType,
	loanType,
	isBadLoan,
	setShowBadLoanAlert,
	navigation,
	loan,
}) => {
	const styles = useDynamicStyleSheet(dStyles);

	const paymentDocuments = isBadLoan
		? payment.loanDocuments || []
		: !!payment.dispatchId
		? payment.documents || []
		: payment.paymentDocuments || [];

	const showSecondSection = () => {
		return (
			!isScheduled &&
			!loan.isSharedLoan &&
			(payment.notes || paymentDocuments.length > 0)
		);
	};

	const leftTextStyle = isBadLoan
		? { color: VERMILLION_COLOR, opacity: 1 }
		: {};

	return (
		<View>
			<View style={styles.container}>
				<View style={styles.textContainer}>
					<Row
						left={
							<Text
								style={{
									...styles.secondRowText,
									...leftTextStyle,
								}}
							>
								{format(
									!!payment.dispatchId
										? payment.amount
										: payment.principleBalBefore - payment.principleBalAfter,
									currency
								)}
							</Text>
						}
						right={
							<Text
								style={{
									...styles.secondRowText,
									leftTextStyle,
								}}
							>{`${
								!!payment.dispatchId
									? "Added to Principal"
									: isBadLoan
									? "Principal marked as Bad Loan"
									: `${isScheduled ? "Will credit" : "Credited"} to Principal`
							}`}</Text>
						}
					/>
					<Row
						left={
							<Text
								style={{
									...styles.secondRowText,
									leftTextStyle,
								}}
							>
								{format(
									!!payment.dispatchId
										? payment.updatedPrincipal
										: payment.interestBefore - payment.interestAfter,
									currency
								)}
							</Text>
						}
						right={
							<Text style={styles.secondRowText}>
								{`${
									!!payment.dispatchId
										? "Updated Principal"
										: isBadLoan
										? "Interest marked as Bad Loan"
										: `${isScheduled ? "Will credit" : "Credited"} to Interest`
								}`}
							</Text>
						}
						style={{ marginBottom: 6 }}
					/>
				</View>
			</View>
			{(isFirstPayment || isSettleUp) && <View style={styles.borderBottom} />}
		</View>
	);
};

const Accordion = withStyles({
	root: {
		border: "1px solid rgba(0, 0, 0, .125)",
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		padding: 0,
	},
	content: {
		margin: "0px 0px",
		"&$expanded": {
			margin: "12px 0",
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: 0,
	},
	content: {
		margin: "0px",
	},
}))(MuiAccordionDetails);

export default ({
	payment,
	loanType,
	currency,
	editPayment,
	settleUpId,
	navigation,
	principleAmount,
	errorBanner,
	isScheduled,
	isFirstPayment,
	changeModalType,
	willExpand,
	fromModal,
	percentage,
	isBadLoan,
	setShowBadLoanAlert,
	loan,
}) => {
	if (!payment) return null;
	const styles = useDynamicStyleSheet(dStyles);
	const [activeSections, setActiveSections] = useState([]);
	const TRANSACTION_COLOR = loanType === TRANSACTION_TYPE_LENT ? LENT : OWES;
	const isOverDue =
		isScheduled && moment(payment.paidOn * 1000).isBefore(moment(), "day");
	const styleOverride =
		fromModal || isOverDue || !!payment.dispatchId
			? {
					borderColor: isBadLoan
						? WHITE
						: isOverDue
						? OVERDUE_RED
						: TRANSACTION_COLOR,
					borderWidth: 1,
					marginHorizontal: fromModal ? 20 : 15,
			  }
			: {};

	return (
		<Accordion
			sections={[payment]}
			containerStyle={{
				...styles.accordionContainerStyles,
				...styleOverride,
			}}
			disabled={fromModal}
			onChange={(event, newExpanded) =>
				setActiveSections(newExpanded ? [1] : [])
			}
		>
			<AccordionSummary>
				<PaymentHeader
					payment={payment}
					activeSections={activeSections}
					loanType={loanType}
					currency={currency}
					isSettleUp={!!settleUpId}
					isScheduled={isScheduled}
					isOverDue={isOverDue}
					fromModal={fromModal}
					isBadLoan={isBadLoan}
				/>
			</AccordionSummary>
			<AccordionDetails>
				<PaymentContent
					payment={payment}
					currency={currency}
					editPayment={editPayment}
					isSettleUp={!!settleUpId}
					errorBanner={errorBanner}
					isScheduled={isScheduled}
					isFirstPayment={isFirstPayment}
					loanType={loanType}
					isBadLoan={isBadLoan}
					changeModalType={changeModalType}
					setShowBadLoanAlert={setShowBadLoanAlert}
					navigation={navigation}
					loan={loan}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
