import React from "react";
import { View } from "../react-native/imports";

export default ({ style, children, colors = [] }) => {
	return (
		<View
			style={{
				...style,
				background: `linear-gradient(to right, ${colors[0]} 0%,${colors[1]} 100%`,
			}}
		>
			{children}
		</View>
	);
};
