import { DynamicValue } from "../components/react-native/imports";
import {
  APP_BACKGROUND_COLOUR_DARK,
  BACKGROUND_DARK,
  BACKGROUND_LIGHT,
  DARK_GREY_BLUE,
  LIGHT_GRAY,
  LIGHT_GREY_TEXT_COLOR,
  MAIN_TEXT_DARK,
  MAIN_TITLE_TEXT,
  NAVBAR_BACKGROUND_DARK,
  SHADOW_BLACK50,
  TRANSPARENT,
  VERMILLION_COLOR,
  WHITE,
} from "../constants/colors";
import { REGULAR } from "../constants/fonts";

export default {
  centeredContainer: {
    flex: 1,
    backgroundColor: new DynamicValue(
      BACKGROUND_LIGHT,
      APP_BACKGROUND_COLOUR_DARK
    ),
  },
  headerViewBase: {
    marginHorizontal: 15,
    marginBottom: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  segmentViewStyle: {
    marginHorizontal: 15,
    height: 32,
    flex: 1,
    flexDirection: "row",
    backgroundColor: new DynamicValue("#D8D8D8", TRANSPARENT),
    borderRadius: 7,
    shadowOpacity: 1,
    shadowRadius: 2,
    shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,0.5)"),
    shadowOffset: { width: 0, height: 1 },
    elevation: 2,
    overflow: "hidden",
  },
  segmentControllerStyle: {
    height: 32,
    flex: 1,
  },
  buttonStyle: {
    paddingHorizontal: 8,
    height: 32,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: new DynamicValue("#ffffff", BACKGROUND_DARK),
    borderRadius: 4,
    shadowOpacity: 1,
    shadowRadius: 2,
    shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,0.5)"),
    shadowOffset: { width: 0, height: 1 },
    elevation: 2,
  },
  imageStyle: {
    height: 16,
    width: 16,
  },
  buttonTextStyle: {
    fontFamily: REGULAR,
    fontSize: 14,
    color: new DynamicValue(MAIN_TITLE_TEXT, MAIN_TEXT_DARK),
    letterSpacing: 0.2,
    lineHeight: 13,
  },
  flatListaStyle: {
    flex: 1,
    paddingTop: 10,
  },

  // LoanDataCard styles

  containerStyle: {
    marginHorizontal: 15,
    marginBottom: 17,
    backgroundColor: new DynamicValue("#ffffff", BACKGROUND_DARK),
    borderRadius: 5,
    shadowOpacity: 1,
    shadowRadius: 2,
    shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,0.5)"),
    shadowOffset: { width: 0, height: 1 },
    elevation: 2,
    padding: 8,

    overflow: "hidden",
  },
  badLoanView: {
    backgroundColor: VERMILLION_COLOR,
    marginBottom: 10,
    borderBottomLeftRadius: 5,
    position: "absolute",
    top: -15,
    right: -15,
  },
  badLoanText: {
    color: WHITE,
    fontSize: 12,
    fontFamily: REGULAR,
    paddingLeft: 14,
    paddingRight: 14,
    paddingVertical: 1,
  },
  userDataBaseStyle: {
    flexDirection: "row",
    marginHorizontal: 15,
  },
  imageBaseViewStyle: {
    height: 64,
    width: 64,
    borderRadius: 32,
    marginRight: 28,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: LIGHT_GRAY,
  },
  shareeImageStyle: {
    height: 32,
    width: 32,
    borderRadius: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  dataViewStyle: {
    flex: 1,
    justifyContent: "center",
    marginRight: 0,
  },
  nameTextStyles: {
    fontSize: 16,
    fontFamily: REGULAR,
    color: new DynamicValue(LIGHT_GREY_TEXT_COLOR, MAIN_TEXT_DARK),
  },
  loanNameTextStyles: {
    fontSize: 15,
    fontFamily: REGULAR,
    color: new DynamicValue(DARK_GREY_BLUE, MAIN_TEXT_DARK),
  },
  placeholderTextStyle: {
    fontFamily: REGULAR,
    fontWeight: 500,
    fontSize: 30,
  },
  descriptionTextStyle: {
    fontSize: 12,
    fontFamily: "Lato-Light",
    color: new DynamicValue(LIGHT_GREY_TEXT_COLOR, MAIN_TEXT_DARK),
    height: 16,
  },
  dateBaseStyle: {
    flexDirection: "row",
  },
  dateTextStyle: {
    fontSize: 12,
    fontFamily: REGULAR,
    color: new DynamicValue(LIGHT_GREY_TEXT_COLOR, MAIN_TEXT_DARK),
  },
  principleTextStyle: {
    fontSize: 15,
    fontFamily: REGULAR,
    color: new DynamicValue(LIGHT_GREY_TEXT_COLOR, MAIN_TEXT_DARK),
  },
  principleAmtTextStyle: {
    fontSize: 17,
    fontFamily: REGULAR,
    fontWeight: 500,
    letterSpacing: 0.82,
  },
  loanPrincipleAmtTextStyle: {
    fontSize: 17,
    fontFamily: REGULAR,
    fontWeight: 500,
    textDecorationLine: "line-through",
    paddingHorizontal: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  remainderTextStyle: {
    fontSize: 13,
    fontFamily: REGULAR,
    color: new DynamicValue("rgba(0,0,0,0.5)", "rgba(255,255,255,0.5)"),
    marginTop: 2,
  },
  priceDataBaseStyle: {
    marginHorizontal: 15,
    marginVertical: 10,
  },
  principleBaseViewStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  progressbar: {
    height: 3,
    margin: 0,
  },

  badLoanSubText: {
    color: VERMILLION_COLOR,
    fontSize: 15,
    fontFamily: REGULAR,
    alignSelf: "center",
  },
  //Loans screen Navigation styles

  navbarStyle: {
    backgroundColor: new DynamicValue("#ffffff", NAVBAR_BACKGROUND_DARK),

    shadowColor: new DynamicValue(SHADOW_BLACK50, "white"),
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 4,
    elevation: 5,
  },
  headerRightLinearGradientStyle: {
    marginRight: 15,
    marginBottom: 0,
    height: 26,
    width: 75,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderRadius: 4,
    overflow: "hidden",
    // shadowOpacity: 1,
    // shadowRadius: 4,
    // shadowColor: new DynamicValue(SHADOW_BLACK50, 'rgba(255,255,255,0.5)'),
    // shadowOffset: {width: 0, height: 1},
    // elevation: 4,
  },
  headerRightTouchStyle: {
    flex: 1,
    height: 26,
    width: 80,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  headerRightTextStyle: {
    fontFamily: REGULAR,
    fontSize: 15,
    color: "#FFFFFF",
  },
  plusSymbolStyle: {
    fontFamily: REGULAR,
    fontSize: 21,
    color: "#FFFFFF",
  },
  headerLeftTextStyle: {
    marginLeft: 32,
    marginBottom: 0,
    fontFamily: REGULAR,
    fontSize: 21,
    color: new DynamicValue("#474747", MAIN_TEXT_DARK),
  },
};
