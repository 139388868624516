import { filter, first, get, map, uniq } from "lodash";

/*
  Conversion matrix stores as FROM_CURRENCY: { TO_CURRENCY: <CONVERSION_FACTOR> }
 */
export const convertToAppLevelCurrency = (amount, fromCurrency) => {
	const { conversionMatrix, currencyPref: appLevelCurrency } = global;
	const conversionFactor =
		!fromCurrency || !appLevelCurrency
			? 1
			: get(
					conversionMatrix,
					`${fromCurrency.code}.${appLevelCurrency.code}`,
					1
			  );

	return (amount || 0) * conversionFactor;
};

export const getConversionConfig = (totalTransactions) => {
	if (totalTransactions.length === 0) {
		return { shouldConvert: false };
	}

	let shouldConvert = true;
	let aggregationCurrency;

	const uniqueCurrencies = filter(
		uniq(
			map(
				filter(
					totalTransactions,
					(transaction) => transaction.loanStatus !== "Deleted"
				),
				(transaction) => get(transaction, "currency.code")
			)
		),
		(currencyCode) => currencyCode !== undefined
	);

	if (uniqueCurrencies.length === 1) {
		//There is only one currency used for all the users. Just ignore conversion, aggregate in the name of that currency
		shouldConvert = false;
		aggregationCurrency = first(totalTransactions).currency;
	}

	return {
		shouldConvert,
		aggregationCurrency,
	};
};
