import { DynamicValue } from "../components/react-native/imports";
import {
	APP_BACKGROUND_COLOUR,
	APP_BACKGROUND_COLOUR_DARK,
	BACKGROUND_DARK,
	BACKGROUND_LIGHT,
	CONNECTING_LINE_DARK,
	DARK_GRAY,
	DARK_GREY_BLUE,
	LENT,
	MAIN_TEXT_DARK,
	MAIN_TEXT_LIGHT,
	MAIN_TITLE_TEXT,
	OWES,
	SHADOW_BLACK50,
	TRANSPARENT,
	WHITE,
} from "../constants/colors";
import { REGULAR } from "../constants/fonts";

export default {
	centeredContainer: {
		flex: 1,
		backgroundColor: new DynamicValue(APP_BACKGROUND_COLOUR, BACKGROUND_DARK),
		justifyContent: "center",
		alignItems: "center",
	},
	container: {
		backgroundColor: new DynamicValue(TRANSPARENT, TRANSPARENT),
	},
	containerView: {
		backgroundColor: new DynamicValue(TRANSPARENT, TRANSPARENT),
		marginHorizontal: 15,
	},
	totalView: {
		flexDirection: "row",
		backgroundColor: new DynamicValue(TRANSPARENT, TRANSPARENT),
		alignItems: "center",
	},
	owesBorderColour: { borderColor: new DynamicValue(OWES, OWES) },
	lentBorderColour: { borderColor: new DynamicValue(LENT, LENT) },
	imageContainerView: {
		backgroundColor: new DynamicValue(TRANSPARENT, TRANSPARENT),
		height: 50,
		width: 50,
		justifyContent: "center",
		marginRight: 5,
	},
	imageView: {
		backgroundColor: new DynamicValue(TRANSPARENT, TRANSPARENT),
		height: 45,
		width: 45,
		borderRadius: 22,
		borderWidth: 0,
	},
	imageStyle: {
		height: 45,
		width: 45,
		alignSelf: "center",
	},
	textViewContainer: {
		flexDirection: "row",
		flex: 1,
	},
	headerTitleText: {
		fontFamily: REGULAR,
		fontWeight: 500,
		fontSize: 14,
		color: new DynamicValue(MAIN_TITLE_TEXT, MAIN_TEXT_DARK),
		textAlign: "left",
		marginHorizontal: 5,
		marginRight: 10,
		letterSpacing: 0.2,
	},
	subTitleTextStyle: {
		fontFamily: REGULAR,
		fontSize: 14,
		color: new DynamicValue(MAIN_TITLE_TEXT, BACKGROUND_LIGHT),
		opacity: 0.8,
		marginLeft: 5,
		paddingBottom: 5,
		textAlign: "left",
	},
	loansHeaderTitle: {
		fontFamily: REGULAR,
		fontSize: 14,
		color: new DynamicValue(DARK_GREY_BLUE, BACKGROUND_LIGHT),
	},

	textStyleView: {
		backgroundColor: new DynamicValue(WHITE, BACKGROUND_DARK),
		marginLeft: 0,
		alignItems: "flex-start",
		flex: 1,
		borderRadius: 5,
		justifyContent: "center",
	},
	textAndDateContainer: {
		backgroundColor: new DynamicValue(WHITE, BACKGROUND_DARK),
		flex: 1,
		justifyContent: "center",
		alignContent: "flex-end",
		borderRadius: 5,
		borderWidth: 1,
		shadowColor: SHADOW_BLACK50,
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowRadius: 4,
		shadowOpacity: 0.3,
		elevation: 5,
	},
	dateView: {
		alignContent: "center",
		justifyContent: "center",
		margin: 5,
		paddingHorizontal: 5,
		paddingVertical: 5,
		borderRadius: 5,
		width: "22%",
	},
	dateTextStyle: {
		alignSelf: "center",
		color: new DynamicValue(WHITE, BACKGROUND_DARK),
		fontFamily: REGULAR,
		fontWeight: 500,
		fontSize: 13,
	},
	text: {
		color: new DynamicValue(MAIN_TEXT_LIGHT, MAIN_TEXT_DARK),
		textAlign: "center",
	},
	segmentStyle: {
		marginHorizontal: 125,
		tintColor: "green",
		marginTop: 20,
		height: 35,
		width: "55%",
		alignSelf: "center",
		shadowColor: new DynamicValue(SHADOW_BLACK50, "rgba(255,255,255,1)"),
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowRadius: 4,
		shadowOpacity: 0.3,
		elevation: 5,
		marginBottom: 0,
	},

	cellsView: {
		marginTop: 15,
		backgroundColor: new DynamicValue(TRANSPARENT, APP_BACKGROUND_COLOUR_DARK),
	},
	connectingView: {
		backgroundColor: TRANSPARENT,
		height: 20,
	},
	connectingLine: {
		backgroundColor: new DynamicValue(DARK_GRAY, CONNECTING_LINE_DARK),
		marginLeft: 22,
		width: 2,
		flex: 1,
	},
	loanHeaderStyle: {
		alignSelf: "center",
		textDecorationLine: "line-through",
		textDecorationStyle: "solid",
		fontSize: 12,
		letterSpacing: 0.82,
	},
	loanSubTitleStyle: {
		alignSelf: "center",
		padding: 3,
		fontSize: 14,
	},
	loanAmountViewStyle: {
		marginHorizontal: 10,
		justifyContent: "center",
		alignItems: "center",
	},
};
