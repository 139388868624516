import {
  INTEREST_BREAKUP_END_OFFSET_INTERVALS,
  INTEREST_CALCULATION_STRATEGY,
  MONTHLY,
  TRANSACTION_TYPE_LENT,
  TRANSACTION_TYPE_OWES,
} from "../constants/LoanConstants";
import {
  cloneDeep,
  filter,
  findLast,
  first,
  floor,
  forEach,
  isEmpty,
  keys,
  last,
  map,
  max,
  orderBy,
  round,
  sum,
  sumBy,
} from "lodash";
import {
  convertToAppLevelCurrency,
  getConversionConfig,
} from "./CurrencyConverter";
import {
  calculateSimpleInterest as csi,
  calculateSimpleInterestIntervalEnd as csiIntervalEnd,
} from "./InterestCalculators";
import {
  generateNextEmiDateFromFrequency,
  scheduleLoanEMIS,
  scheduleManualLoans,
} from "./EMIutilManager";
import {
  getIsBadLoan,
  getLoanStatus,
  isTransactionTypeOwe,
} from "./DataManager";

import { LOAN_STATUS } from "../api/firebase/loanFields";
import { MANUAL_ENTRY } from "../constants/InstalmentPaymentMode";
import { editLoan } from "../api/firebase/methods";
import { format } from "./CurrencyFormatter";
import moment from "moment";
import { newDispatchPath } from "../api/firebase/paths";

export const compoundInterestValues = (interestType) => {
  return {
    Monthly: 12,
    Quarterly: 4,
    Half_yearly: 2,
    Annually: 1,
    Yearly: 1,
  }[interestType];
};

export const SIMPLE_INTEREST = "simpleInterest";
export const COMPOUND_INTEREST = "compoundInterest";

export const latestReferenceDate = (loan) => {
  const { createDate: createdDate, interestStartDate = createdDate } = loan;
  const lastPayment = first(orderBy(loan.paidInfo || [], "paidOn", "desc"));

  return max([
    lastPayment ? lastPayment.paidOn : interestStartDate,
    interestStartDate,
  ]);
};

const simpleInterestAmount = (
  loan,
  toDateOverride,
  fromDateOverride,
  principalOverride,
  details = false
) => {
  const pBalance =
    principalOverride !== undefined
      ? principalOverride
      : loan.principleBalance || 0;
  const iRate = loan.interestRate || 0;
  const fromDate = details
    ? loan.createDate
    : fromDateOverride || latestReferenceDate(loan);
  const toDate = toDateOverride || moment().valueOf() / 1000;
  const amount = csi(
    pBalance,
    iRate,
    fromDate,
    getIsBadLoan(loan.badLoanInfo, loan.isSharedLoan)
      ? loan.badLoanInfo.paidOn
      : toDate
  );

  return amount + (fromDateOverride ? 0 : loan.lastRemainingInterest || 0);
};

const nearestCompoundInterestIntervalDate = (
  date,
  oldIntervalDate,
  interestType
) => {
  const dateMoment = moment(date * 1000),
    oldIntervalDateMoment = moment(oldIntervalDate * 1000);

  let intervalStartDate = oldIntervalDateMoment;
  while (intervalStartDate.isBefore(dateMoment)) {
    intervalStartDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );
  }

  return intervalStartDate.isSame(dateMoment)
    ? intervalStartDate
    : generateNextEmiDateFromFrequency(intervalStartDate, interestType, true);
};

const simpleInterestMonths = (loan) => {
  const fromDate = latestReferenceDate(loan);
  return {
    fromDate: fromDate,
    toDate: moment().startOf("day").valueOf() / 1000,
  };
};

const compoundInterestMonths = (loan) => {
  const compoundIntervals = compoundInterestValues(
    loan.interestType || "Monthly"
  );
  const fromDate = latestReferenceDate(loan);
  return {
    fromDate: fromDate,
    toDate:
      moment(fromDate * 1000)
        .add(12 / compoundIntervals, "months")
        .startOf("day")
        .valueOf() / 1000,
  };
};

/**
 * Method to calculate compound interest and simple interest interval end
 */
const compoundInterestAmount = (
  loan,
  toDateOverride,
  settleUpMode = false,
  fromDate,
  principalOverride,
  details,
  ignoreLastRemainingInterest,
  ignorePayments = false
) => {
  let {
    interestMode = SIMPLE_INTEREST,
    principleBalance: principalBalance = 0,
    principleBalance: remainingPrincipal,
    lastRemainingInterest: remainingInterest = 0,
    interestRate: iRate = 0,
    interestType = MONTHLY,
    compoundInterestReferenceIntervalDate,
    simpleInterestReferenceIntervalDate,
  } = loan;

  remainingInterest = ignoreLastRemainingInterest ? 0 : remainingInterest;

  const isCompounded = interestMode === COMPOUND_INTEREST;
  interestType = !isCompounded ? MONTHLY : interestType;
  let refDate = isCompounded
    ? details
      ? loan.createDate
      : compoundInterestReferenceIntervalDate
    : simpleInterestReferenceIntervalDate;

  if (principalOverride) {
    principalBalance = principalOverride;
    remainingPrincipal = principalOverride;
  }

  let toDate = moment();
  if (toDateOverride) {
    toDate = moment(toDateOverride * 1000);
  }

  // Initialize intervals
  let intervalStartDate = !fromDate
    ? moment(refDate * 1000)
    : nearestCompoundInterestIntervalDate(fromDate, refDate, interestType);
  let intervalEndDate = generateNextEmiDateFromFrequency(
    intervalStartDate,
    interestType
  );
  let mergeInterestIntoPrincipal = isCompounded;

  /**
   * Algo:
   * 1. Start from compound interest ref date
   *
   * 2. Go forward by intervals (month, quarter etc) until intervalEndDate crosses toDate
   *
   * 3. For each interval
   *  3.1. Add the interval interest into remaining interest
   *  3.2. Merge the remaining interest into the principal balance
   *  3.3. Zero out the remaining interest
   *
   * 4. If the toDate falls within the first interval itself, the loop is not entered even once, so
   *    merge remaining interest into principal after the loop.
   */
  while (intervalEndDate.isSameOrBefore(toDate)) {
    let intervalInterest = 0;

    if (isCompounded) {
      intervalInterest = csi(
        remainingPrincipal,
        iRate,
        intervalStartDate.valueOf() / 1000,
        intervalEndDate.valueOf() / 1000
      );
    } else {
      intervalInterest = csiIntervalEnd(remainingPrincipal, iRate);
    }

    remainingInterest += intervalInterest;

    if (isCompounded) {
      if (!(settleUpMode && intervalEndDate.isSame(toDate, "day"))) {
        mergeInterestIntoPrincipal = settleUpMode ? false : true;
        remainingPrincipal += remainingInterest;
        remainingInterest = 0;
      }
    }

    // Bump the interval ranges
    intervalStartDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );
    intervalEndDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );
  }

  if (mergeInterestIntoPrincipal) {
    // Accumulating the remaining interest (mostly in case of toDate falling before the first interval completes)
    remainingPrincipal += remainingInterest;
    remainingInterest = 0;
  }

  return !settleUpMode
    ? isCompounded
      ? remainingPrincipal - principalBalance
      : remainingInterest
    : {
        remainingPrincipal,
        remainingInterest,
      };
};

export const interestAmount = (
  loan,
  toDate,
  fromDate,
  principalOverride,
  details = false,
  ignoreLastRemainingInterest = false // Should be ignored while calculating total outstanding principal
) => {
  const {
    interestMode = SIMPLE_INTEREST,
    interestCalculationStrategy = INTEREST_CALCULATION_STRATEGY.DAY_END,
  } = loan;

  const interest =
    interestMode === SIMPLE_INTEREST &&
    interestCalculationStrategy === INTEREST_CALCULATION_STRATEGY.DAY_END
      ? simpleInterestAmount(loan, toDate, fromDate, principalOverride, details)
      : compoundInterestAmount(
          loan,
          toDate,
          false,
          fromDate,
          principalOverride,
          details,
          ignoreLastRemainingInterest
        );

  return round(interest, 2);
};

export const interestBwMonths = (loan) => {
  const interestMode = loan.interestMode || SIMPLE_INTEREST;

  const months =
    interestMode === SIMPLE_INTEREST
      ? simpleInterestMonths(loan)
      : compoundInterestMonths(loan);
  return months;
};

export const totalOutstandingAmount = (loan) => {
  return getLoanStatus(loan.loanStatus, loan.badLoanInfo, loan.isSharedLoan) ===
    LOAN_STATUS.CLOSED && isEmpty(loan.badLoanInfo)
    ? 0
    : (loan.principleBalance || 0) + interestAmount(loan);
};

const recalculateCompoundInterestPaidInfo = (loan) => {
  let {
    interestMode = SIMPLE_INTEREST,
    interestType,
    createDate = 0,
    paidInfo,
    principleAmount: principalAmount = 0,
    interestStartDate = createDate,
  } = loan;

  const sortedPaidInfo = orderBy(
    paidInfo,
    ["paidOn", "amount"],
    ["asc", "asc"]
  );

  let remainingInterest = 0;
  const interestStartDateMoment = moment(interestStartDate * 1000);
  const numberOfParts = compoundInterestValues(interestType);

  interestType = interestMode === SIMPLE_INTEREST ? MONTHLY : interestType;
  let intervalStartDate = moment(interestStartDateMoment);
  let intervalEndDate;
  let refDate = moment(interestStartDateMoment);
  let grace = 0;

  loan.principleBalance = principalAmount;

  let validPayments = [];
  let processedPaymentsLength = 0;

  let firstIteration = true;

  if (sortedPaidInfo.length > 0) {
    function processPayments(payments) {
      forEach(payments, (payment) => {
        if (principalAmount + remainingInterest >= 1) {
          const validPayment = {
            ...payment,
          };

          validPayment.principleBalBefore = principalAmount;
          validPayment.interestBefore = remainingInterest;

          const totalInterestAmount = remainingInterest;
          if ((validPayment.amount || 0) > totalInterestAmount) {
            const towardsPrincipal =
              (validPayment.amount || 0) - totalInterestAmount;

            if (towardsPrincipal > principalAmount) {
              grace += towardsPrincipal - principalAmount;
            }

            remainingInterest = 0;
            principalAmount = max([principalAmount - towardsPrincipal, 0]);
            loan.principleBalance = principalAmount;
          } else {
            remainingInterest =
              totalInterestAmount - (validPayment.amount || 0);
          }

          validPayment.principleBalAfter = principalAmount;
          validPayment.interestAfter = remainingInterest;

          validPayments.push(validPayment);
        } else {
          grace = grace + (payment.amount || 0);
        }

        processedPaymentsLength++;
      });
    }

    /** Process all payments before the interest start date before continuing with the intervals */
    const paymentsBeforeInterestStartDate = filter(sortedPaidInfo, (p) =>
      moment(p.paidOn * 1000).isBefore(interestStartDateMoment)
    );
    processPayments(paymentsBeforeInterestStartDate);

    while (processedPaymentsLength < sortedPaidInfo.length) {
      /** Payments excluding the interval end date */
      let intervalPayments = [];
      intervalEndDate = generateNextEmiDateFromFrequency(
        intervalStartDate,
        interestType
      );

      intervalPayments = filter(sortedPaidInfo, (p) => {
        const paidOn = moment(p.paidOn * 1000);
        return (
          (firstIteration
            ? paidOn.isSameOrAfter(intervalStartDate)
            : paidOn.isAfter(intervalStartDate)) &&
          paidOn.isBefore(intervalEndDate)
        );
      });

      firstIteration = false;

      processPayments(intervalPayments);

      let interest = 0;

      if (interestMode === COMPOUND_INTEREST) {
        interest = csi(
          principalAmount,
          loan.interestRate || 0,
          intervalStartDate.valueOf() / 1000,
          intervalEndDate.valueOf() / 1000,
          numberOfParts
        );
      } else {
        interest = csiIntervalEnd(principalAmount, loan.interestRate || 0);
      }

      const intervalEndDatePayments = filter(sortedPaidInfo, (p) =>
        moment(p.paidOn * 1000).isSame(intervalEndDate, "day")
      );

      // Add the accumulated interest into remaining interest only if there are payments on the end of interval date
      if (
        intervalEndDatePayments.length !== 0 ||
        processedPaymentsLength < sortedPaidInfo.length
      ) {
        remainingInterest += interest;
      }

      processPayments(intervalEndDatePayments);

      // If we have payments left still, update the principal and remaining amount to proceed with the next iteration
      if (
        interestMode === COMPOUND_INTEREST &&
        processedPaymentsLength < sortedPaidInfo.length
      ) {
        principalAmount += remainingInterest;
        remainingInterest = 0;
      }
      refDate =
        intervalEndDatePayments.length !== 0
          ? intervalEndDate
          : intervalStartDate;

      intervalStartDate = intervalEndDate;
    }

    loan.grace = grace;
  } else {
    loan.grace = 0;
  }

  loan.principleBalance = principalAmount;
  loan.lastRemainingInterest = remainingInterest;
  loan.paidInfo = validPayments;
  if (interestMode === COMPOUND_INTEREST) {
    loan.compoundInterestReferenceIntervalDate = refDate.valueOf() / 1000;
  } else {
    loan.simpleInterestReferenceIntervalDate = refDate.valueOf() / 1000;
  }

  /*
   * Ignoring amount less than a rupee, which is common due to RFL schedules
   */

  if ((loan.principleBalance || 0) < 1) {
    loan.loanStatus = "Closed";
  } else {
    loan.loanStatus = "Pending";
  }

  return loan;
};

export const totalOutstandingPrinciple = (loan) => {
  return (
    (loan.principleAmount || 0) +
    interestAmount(
      loan,
      moment().valueOf() / 1000,
      loan.interestMode === COMPOUND_INTEREST
        ? loan.interestStartDate || loan.createDate
        : latestReferenceDate(loan),
      loan.principleAmount,
      true,
      true
    )
  );
};

export const recalculatePaidInfo = (l) => {
  const loan = { ...l };
  const {
    createDate,
    interestMode,
    interestStartDate = createDate,
    interestCalculationStrategy = INTEREST_CALCULATION_STRATEGY.DAY_END,
  } = loan;

  if (
    interestMode === COMPOUND_INTEREST ||
    interestCalculationStrategy === INTEREST_CALCULATION_STRATEGY.MONTH_END
  ) {
    return recalculateCompoundInterestPaidInfo(loan);
  }

  const sortedPaidInfo = orderBy(
    loan.paidInfo,
    ["paidOn", "amount"],
    ["asc", "asc"]
  );

  let totalPrincipalPaidAmount = 0;
  let principalAmount = loan.principleAmount || 0;
  let remainingInterest = 0;
  let lastPaidDate = interestStartDate;
  let grace = 0;

  loan.principleBalance = principalAmount;

  let validPayments = [];
  if (sortedPaidInfo.length > 0) {
    forEach(sortedPaidInfo, (payment) => {
      if (totalPrincipalPaidAmount < (loan.principleAmount || 0)) {
        const validPayment = { ...payment };

        let interest = 0;
        interest = csi(
          principalAmount,
          loan.interestRate || 0,
          lastPaidDate,
          payment.paidOn
        );

        validPayment.principleBalBefore = principalAmount;
        validPayment.interestBefore = interest + remainingInterest;

        const totalInterestAmount = interest + remainingInterest;
        if ((validPayment.amount || 0) > totalInterestAmount) {
          const towardsPrincipal =
            (validPayment.amount || 0) - totalInterestAmount;

          if (towardsPrincipal > principalAmount) {
            grace += towardsPrincipal - principalAmount;
          }

          remainingInterest = 0;
          principalAmount = max([principalAmount - towardsPrincipal, 0]);
          loan.principleBalance = principalAmount;

          totalPrincipalPaidAmount += towardsPrincipal;
        } else {
          remainingInterest = totalInterestAmount - (validPayment.amount || 0);
        }

        validPayment.principleBalAfter = principalAmount;
        validPayment.interestAfter = remainingInterest;

        /*
         * Below seems odd, but lastPaidDate is initialised with interest start date
         * so we'll update the lastPaidDate only when the current paidOn crosses it
         */
        if (payment.paidOn > lastPaidDate) {
          lastPaidDate = payment.paidOn;
        }

        validPayments.push(validPayment);
      } else {
        grace = grace + (payment.amount || 0);
      }
    });

    loan.grace = grace;
  } else {
    loan.principleBalance = principalAmount;
    loan.grace = 0;
  }

  loan.lastRemainingInterest = remainingInterest;
  loan.paidInfo = validPayments;

  /*
   * Ignoring amount less than a rupee, which is common due to RFL schedules
   */

  if ((loan.principleBalance || 0) < 1) {
    loan.loanStatus = "Closed";
  } else {
    loan.loanStatus = "Pending";
  }

  return loan;
};

export const recordPayment = (loan, payment) => {
  let clone = { ...loan };

  if (!loan.paidInfo) {
    clone.paidInfo = [];
  }

  clone.paidInfo.push(payment);
  clone = recalculatePaidInfo(clone);

  //TODO: Upload payment image

  return clone;
};

export const checkPaymentsInfo = (newLoan, oldLoan) => {
  let updatedLoan = { ...newLoan };

  const changedInputs = [];
  if (
    newLoan.createDate !== oldLoan.createDate ||
    newLoan.dueDate !== oldLoan.dueDate
  ) {
    changedInputs.push("date");
  }

  if (newLoan.principleAmount !== oldLoan.principleAmount) {
    changedInputs.push("amount");
  }

  if (
    newLoan.interestRate !== oldLoan.interestRate ||
    newLoan.interestMode !== oldLoan.interestMode ||
    newLoan.interestType !== oldLoan.interestType
  ) {
    changedInputs.push("rateOfInterest");
  }

  // If date has changed, recalculate the valid and invalid payments
  if (changedInputs.indexOf("date") !== -1) {
    const invalidPayments = filter(
      newLoan.paidInfo || [],
      (payment) => payment.paidOn < newLoan.createDate
    );
    const validPayments = filter(
      newLoan.paidInfo || [],
      (payment) => payment.paidOn >= newLoan.createDate
    );

    updatedLoan.paidInfo = validPayments;
    updatedLoan.grace = sum(
      map(invalidPayments, (payment) => payment.amount || 0)
    );
  }

  updatedLoan = recalculatePaidInfo(updatedLoan);

  return { changedInputs, updatedLoan };
};

export const effectivePrincipalBalanceTillNearestCompoundInterval = (loan) => {
  let {
    interestType,
    compoundInterestReferenceIntervalDate,
    principleBalance: remainingPrincipal,
    lastRemainingInterest: remainingInterest = 0,
    interestRate,
  } = loan;

  let intervalStartDate = moment(compoundInterestReferenceIntervalDate * 1000),
    intervalEndDate = generateNextEmiDateFromFrequency(
      intervalStartDate,
      interestType
    );
  const startOfThisMonth = moment().startOf("month");

  while (intervalEndDate.isSameOrBefore(startOfThisMonth)) {
    const interest = csi(
      remainingPrincipal,
      interestRate,
      intervalStartDate.valueOf() / 1000,
      intervalEndDate.valueOf() / 1000
    );
    remainingInterest += interest;

    remainingPrincipal += remainingInterest;
    remainingInterest = 0;

    intervalStartDate = intervalEndDate;
    intervalEndDate = generateNextEmiDateFromFrequency(
      intervalEndDate,
      interestType
    );
  }

  /*
   * Special case: if the interval start date is the same as the start of this month,
   * add any remaining interest as we didn't accommodate in the loop
   */
  if (intervalStartDate.isSame(startOfThisMonth)) {
    remainingPrincipal += remainingInterest;
    remainingInterest = 0;
  }

  return remainingPrincipal;
};

export const getMonthlyInterestPayables = (allLoans, mode) => {
  const loans =
    mode === "fromLoanDetails"
      ? filter(
          allLoans,
          (loan) =>
            getLoanStatus(
              loan.loanStatus,
              loan.badLoanInfo,
              loan.isSharedLoan
            ) === LOAN_STATUS.PENDING
        )
      : filter(
          allLoans,
          (loan) =>
            loan.shouldExcludeFromSummary !== true &&
            getLoanStatus(
              loan.loanStatus,
              loan.badLoanInfo,
              loan.isSharedLoan
            ) === LOAN_STATUS.PENDING
        );

  let owedMonthlyInterest = 0;
  let lentMonthlyInterest = 0;

  const owedLoans = [];
  const lentLoans = [];
  /*
   * We first filter all lent and owes loans and individually find out if we need to convert
   * the loans into app level currency
   * i.e. if all the lent/owes loans are of single currency, we skip conversion while aggreating
   */
  const {
    shouldConvert: shouldConvertLentLoans,
    aggregationCurrency: lentLoansAggregationCurrency,
  } = getConversionConfig(
    filter(
      loans,
      (loan) =>
        !isTransactionTypeOwe(
          loan.transectionType,
          loan.isSharedLoan,
          loan.mode
        )
    )
  );
  const {
    shouldConvert: shouldConvertOwedLoans,
    aggregationCurrency: owedLoansAggregationCurrency,
  } = getConversionConfig(
    filter(loans, (loan) =>
      isTransactionTypeOwe(loan.transectionType, loan.isSharedLoan, loan.mode)
    )
  );

  forEach(loans, (loan) => {
    let {
      interestRate = 0,
      interestMode,
      principleBalance,
      interestCalculationStrategy,
    } = loan;
    const transactionTypeOwes = isTransactionTypeOwe(
      loan.transectionType,
      loan.isSharedLoan,
      loan.mode
    );
    if (interestRate > 0) {
      /*
       * In case of compound interest, use the effective principal balance till the
       * nearest interval date for interest calculation
       */
      let principal =
        interestMode === SIMPLE_INTEREST
          ? principleBalance || 0
          : effectivePrincipalBalanceTillNearestCompoundInterval(loan);
      let startOfThisMonth = moment().startOf("month").valueOf() / 1000;
      let endOfThisMonth =
        moment().endOf("month").add(1, "day").valueOf() / 1000;

      let interest = 0;
      if (
        interestCalculationStrategy !== INTEREST_CALCULATION_STRATEGY.MONTH_END
      ) {
        interest = csi(
          principal,
          interestRate,
          startOfThisMonth,
          endOfThisMonth
        );
      } else {
        interest = csiIntervalEnd(principal, interestRate);
      }

      if (!transactionTypeOwes) {
        const convertedInterest = !shouldConvertLentLoans
          ? interest
          : convertToAppLevelCurrency(interest, loan.currency);

        lentMonthlyInterest += convertedInterest;

        const updatedLoan = {
          ...loan,
          monthlyInterest: convertedInterest,
          monthlyInterestWithoutConversion: interest,
        };

        lentLoans.push(updatedLoan);
      } else if (transactionTypeOwes) {
        const convertedInterest = !shouldConvertOwedLoans
          ? interest
          : convertToAppLevelCurrency(interest, loan.currency);

        owedMonthlyInterest += convertedInterest;

        const updatedLoan = {
          ...loan,
          monthlyInterest: convertedInterest,
          monthlyInterestWithoutConversion: interest,
        };

        owedLoans.push(updatedLoan);
      }
    }
  });

  return {
    owedLoans,
    owedMonthlyInterest: format(
      round(owedMonthlyInterest, 2),
      owedLoansAggregationCurrency
    ),
    unFormattedOwedMonthlyInterest: owedMonthlyInterest,
    lentLoans,
    lentMonthlyInterest: format(
      round(lentMonthlyInterest, 2),
      lentLoansAggregationCurrency
    ),
    unFormattedLentMonthlyInterest: lentMonthlyInterest,
  };
};

const updateLoan = async (
  loan,
  onLoanChange,
  modalType,
  selectedDate,
  formTypeEdit,
  paymentInfo,
  oldPayment
) => {
  let latestLoan = cloneDeep(loan);
  if (latestLoan.recurringPaymentInfo) {
    let schedules = [];
    let invalidMessage;
    try {
      if (selectedDate && modalType !== "deletePayment") {
        let installments = latestLoan.recurringPaymentInfo.installments;
        latestLoan.recurringPaymentInfo.schedule = map(
          latestLoan.recurringPaymentInfo.schedule,
          (item) => {
            const date = item.paidOn;
            if (date < selectedDate && item.status === "None") {
              item.status = "SKIPPED";
              installments = installments
                ? installments === 0
                  ? 0
                  : installments - 1
                : undefined;
            }
            return item;
          }
        );
        latestLoan.recurringPaymentInfo.installments = installments;
      }
      if (latestLoan.recurringPaymentInfo.recurringType === MANUAL_ENTRY) {
        schedules = scheduleManualLoans(latestLoan);
      } else {
        if (formTypeEdit || selectedDate) {
          schedules = scheduleLoanEMIS(latestLoan, true, true).schedule;
        } else if (modalType === "record new payment") {
          schedules = scheduleLoanEMIS(latestLoan, false, false).schedule;
        } else if (modalType === "deletePayment") {
          schedules = scheduleLoanEMIS(latestLoan).schedule;
        }
      }
    } catch (err) {
      console.log("Schedule generation error: ", err);
      invalidMessage = err.message;
    }
    latestLoan = {
      ...latestLoan,
      recurringPaymentInfo: {
        ...latestLoan.recurringPaymentInfo,
        schedule:
          schedules.length === 0
            ? latestLoan.recurringPaymentInfo.schedule
            : schedules,
        installments:
          latestLoan.recurringPaymentInfo.recurringType === "tenure"
            ? schedules.length
            : undefined,
      },
    };

    latestLoan.recurringPaymentInfo.invalid = invalidMessage ? true : false;
    latestLoan.recurringPaymentInfo.invalidationReason = invalidMessage || null;
  }
  await editLoan(latestLoan, onLoanChange);
};

export const addNewPayment = (
  transaction,
  paymentInfo,
  setIsLoading,
  onLoanChange,
  modalType,
  selectedDate,
  formtype,
  oldPayment
) => {
  return new Promise((resolve, reject) => {
    const clone = {
      ...transaction,
      paidInfo: paymentInfo
        ? [...(transaction.paidInfo || []), paymentInfo]
        : transaction.paidInfo,
    };

    const recalculatedLoan = recalculatePaidInfo(clone);

    const continueFlow = async () => {
      try {
        //TODO: Phase2: Upload image and then update payment
        setIsLoading(true);
        await updateLoan(
          recalculatedLoan,
          onLoanChange,
          modalType,
          selectedDate,
          formtype,
          modalType === "deletePayment" ? oldPayment : paymentInfo,
          oldPayment
        );
      } catch (err) {
        setIsLoading(false);
        throw err;
      }
    };

    if (recalculatedLoan.grace && recalculatedLoan.grace >= 1) {
      setIsLoading(false);
    } else {
      continueFlow()
        .then(() => resolve(recalculatedLoan))
        .catch(reject);
    }
  });
};

export const calculateSettleUpSummary = (userLoans, date) => {
  const { shouldConvert, aggregationCurrency } = getConversionConfig(userLoans);

  let totalOutstanding = 0;
  let totalInterest = 0;
  let totalLentAmount = 0;
  let totalOwesAmount = 0;
  let totalLentInterest = 0;
  let totalOwesInterest = 0;
  let minimumDate = 0.0;
  let dueDate = date;
  let transactionType = "";
  let friendObj = {};
  let isHavingBothLoans = false;
  let currency = aggregationCurrency || global.currencyPref;

  forEach(userLoans, (loan) => {
    const latestDate = latestReferenceDate(loan);
    const transactionTypeOwe = isTransactionTypeOwe(
      loan.transectionType,
      loan.isSharedLoan,
      loan.mode
    );

    if (!transactionTypeOwe) {
      let i = calculateSettleupInterest(loan, latestDate, date);
      totalLentAmount += !shouldConvert
        ? i.remainingPrincipal * 1
        : convertToAppLevelCurrency(i.remainingPrincipal * 1, loan.currency);
      totalLentInterest += !shouldConvert
        ? i.remainingInterest * 1
        : convertToAppLevelCurrency(i.remainingInterest * 1, loan.currency);
    } else if (transactionTypeOwe) {
      let i = calculateSettleupInterest(loan, latestDate, date);
      totalOwesAmount += !shouldConvert
        ? i.remainingPrincipal * -1
        : convertToAppLevelCurrency(i.remainingPrincipal * -1, loan.currency);
      totalOwesInterest += !shouldConvert
        ? i.remainingInterest * -1
        : convertToAppLevelCurrency(i.remainingInterest * -1, loan.currency);
    }

    if (minimumDate < latestDate) {
      minimumDate = latestDate;
    }

    friendObj = Object(loan.friend);
  });

  transactionType =
    totalLentAmount + totalLentInterest <
    Math.abs(totalOwesAmount + totalOwesInterest)
      ? TRANSACTION_TYPE_OWES
      : TRANSACTION_TYPE_LENT;

  isHavingBothLoans =
    totalLentAmount !== 0 && totalOwesAmount !== 0 ? true : false;

  totalOutstanding = Math.abs(
    totalLentAmount + totalLentInterest + (totalOwesAmount + totalOwesInterest)
  );

  totalInterest = totalLentInterest + totalOwesInterest;

  return {
    totalLentAmount,
    totalLentInterest,
    totalOwesAmount,
    totalOwesInterest,
    transactionType,
    minimumDate,
    dueDate,
    friend: friendObj,
    isHavingBothLoans,
    totalOutstanding,
    totalInterest,
    currency,
  };
};

export const calculateSettleupInterest = (loan, fromDate, toDate) => {
  let iAmount = 0;

  if (loan.interestMode === SIMPLE_INTEREST) {
    iAmount = csi(
      loan.principalBalance || 0,
      loan.interestRate || 0,
      fromDate,
      toDate
    );
    iAmount += loan.lastRemainingInterest || 0;
    iAmount = {
      remainingInterest: iAmount,
      remainingPrincipal: loan.principalBalance,
    };
  } else {
    iAmount = compoundInterestAmount(loan, toDate, true);
  }

  return iAmount;
};

export const getPercent = (loan) => {
  const totalPayedAmount = sum(
    map(loan && loan.paidInfo ? loan.paidInfo : [], (item) => item.amount)
  );

  const percentage =
    totalPayedAmount / (totalPayedAmount + totalOutstandingAmount(loan));

  return floor(percentage * 100);
};

export const checkAndUpdateDispatches = (loan) => {
  const {
    dispatchInfo = {},
    transactionId,
    principleAmount,
    createDate,
  } = loan;

  const clonedLoan = { ...loan };
  const dispatchesCount = keys(dispatchInfo).length;

  // Replicate loan edits onto the dispatch if there's only one or none
  if (dispatchesCount <= 1) {
    const dispatchId =
      dispatchesCount === 0
        ? newDispatchPath(transactionId).dispatchId
        : first(keys(dispatchInfo));

    const newDispatch = {
      dispatchId,
      amount: principleAmount,
      dispatchedOn: createDate,
      notes: "",
    };

    clonedLoan.dispatchInfo = {
      [dispatchId]: newDispatch,
    };
  }

  return clonedLoan;
};

/**
 * Generates interest break up (for interval end loans, for now)
 * Algo:
 * - Go till the interval exceeds/equals last payment date
 * - Generate interval interest breakup based on:
 *  interval interest = (principal balance till the interval start - 1 day) * monthly interest rate /100
 * - Generate 2 extra interval interest breakups if exists & loan is not settled
 */
export const getInterestBreakup = (loan) => {
  const {
    interestCalculationStrategy = INTEREST_CALCULATION_STRATEGY.DAY_END,
    interestRate = 0,
  } = loan;

  if (
    interestCalculationStrategy === INTEREST_CALCULATION_STRATEGY.DAY_END ||
    interestRate === 0
  ) {
    return [];
  }

  const {
    principleAmount: principalAmount,
    createDate: createdAt,
    interestStartDate = createdAt,
    paidInfo = [],
    loanStatus,
  } = loan;

  const lastPaymentDate = moment((last(paidInfo)?.paidOn || createdAt) * 1000);
  let intervalStartDate = moment(interestStartDate * 1000);
  let intervalEndDate = generateNextEmiDateFromFrequency(
    intervalStartDate,
    MONTHLY
  );
  let effectivePrincipalBalance = principalAmount;

  const principalBalanceTill = (date) => {
    return (
      findLast(paidInfo, (p) => p.paidOn <= date)?.principleBalAfter ||
      principalAmount
    );
  };

  let breakupArray = [];

  // Generate interval breakups till the last payment
  while (intervalStartDate.isBefore(lastPaymentDate)) {
    effectivePrincipalBalance = principalBalanceTill(
      moment(intervalEndDate).subtract(1, "day").valueOf() / 1000
    );
    const interest = csiIntervalEnd(effectivePrincipalBalance, interestRate);

    breakupArray.push({
      interest,
      intervalStartDate: moment(intervalStartDate),
      intervalEndDate: moment(intervalEndDate),
    });

    intervalStartDate = intervalEndDate;
    intervalEndDate = generateNextEmiDateFromFrequency(
      intervalEndDate,
      MONTHLY
    );
  }

  if (
    getLoanStatus(loanStatus, loan.badLoanInfo, loan.isSharedLoan) ===
    LOAN_STATUS.PENDING
  ) {
    for (let i = 0; i < INTEREST_BREAKUP_END_OFFSET_INTERVALS; i++) {
      effectivePrincipalBalance = principalBalanceTill(
        moment(intervalEndDate).subtract(1, "day").valueOf() / 1000
      );

      breakupArray.push({
        interest: csiIntervalEnd(effectivePrincipalBalance, interestRate),
        intervalStartDate: moment(intervalStartDate),
        intervalEndDate: moment(intervalEndDate),
        interestPaid: 0,
      });

      intervalStartDate = intervalEndDate;
      intervalEndDate = generateNextEmiDateFromFrequency(
        intervalEndDate,
        MONTHLY
      );
    }
  }

  const totalInterestPaid = sumBy(
    paidInfo,
    (p) => p.interestBefore - p.interestAfter
  );
  let interestRemaining = totalInterestPaid;

  breakupArray = map(breakupArray, (element) => {
    let interestPaid = 0;
    if (interestRemaining < element.interest) {
      interestPaid = interestRemaining;
    } else {
      interestPaid = element.interest;
    }
    element.interestPaid = interestPaid;

    interestRemaining -= interestPaid;

    return element;
  });

  return breakupArray;
};
