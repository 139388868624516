// import firebaseNative from '@react-native-firebase/app';
import * as firebase from "firebase";
import { firebaseConfig } from "../env";

// Initialize Firebase
firebase.initializeApp(firebaseConfig());

export default firebase;

export const auth = firebase.auth;

export const database = firebase.database;

export const storage = firebase.storage;

export const analytics = firebase.analytics;
