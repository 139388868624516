

const PAYMENT_STATUS = {
  PAYMENT_PENDING:"pending",
  PAYMENT_PAID:"paid",
  PAYMENT_SKIP:"skip",
  PAYMENT_PARTIAL:"partial",
};

module.exports = {
  PAYMENT_STATUS,
};

