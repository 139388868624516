import axios from "axios";
import { HOST } from "../constants/config";

const defaultParams = () => ({
  fb_session_cookie: window.localStorage.getItem("fb_session_cookie"),
});

const req = (method, url, paramsOrData = {}) => {
  return new Promise((resolve, reject) => {
    const params =
      method === "get" ? { ...defaultParams(), ...paramsOrData } : paramsOrData;
    const data =
      method === "post"
        ? { ...defaultParams(), ...paramsOrData }
        : paramsOrData;

    axios({
      method,
      url,
      params,
      data,
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
};

export const fetchAllStats = () => {
  return new Promise((resolve, reject) => {
    console.log(
      "fb_session_cookie",
      window.localStorage.getItem("fb_session_cookie")
    );

    req("get", `${HOST}/admin/stats/fetchAllStats`).then(resolve).catch(reject);
  });
};

export const fetchUserData = (params) => {
  return new Promise((resolve, reject) => {
    req("get", `${HOST}/admin/migration/fetchUserData`, params)
      .then(resolve)
      .catch(reject);
  });
};
