import React from "react";
import "./index.css";

export default ({ friendName, summary }) => {
  const { givenLoans, takenLoans, netBalance, formattedNetBalance } = summary;

  return (
    <div>
      <h1>Debts Summary with {friendName}</h1>
      <div className="summary-row">
        <div className="child user-summary">
          <h1>Debt Balance with {friendName}</h1>
          <div className="data-row">
            <div className="child first">
              <div className="given">{givenLoans.formattedTotalAmount}</div>
              <div className="data-row-title">Debt Given</div>
            </div>
            <div className="child">-</div>
            <div className="child">
              <div className="taken">{takenLoans.formattedTotalAmount}</div>
              <div className="data-row-title">Debt Taken</div>
            </div>
            <div className="child">=</div>
            <div className="child">
              <div className={netBalance >= 0 ? "given" : "taken"}>
                {formattedNetBalance}
              </div>
              <div className="data-row-title">Balance</div>
            </div>
          </div>
        </div>
        <div className="child loans-count">
          <div className="total-loans">
            {givenLoans.count + takenLoans.count}
          </div>
          <div className="data-row-title">
            Loan{givenLoans.count + takenLoans.count === 1 ? "" : "s"}
          </div>
        </div>
      </div>
    </div>
  );
};
