import currency from "currency.js";
import { round } from "lodash";
// import Localize from "../helpers/Localize";

export const CF_TLC_COMMA_DOT = "TLC,.";
export const CF_TMB_COMMA_DOT = "TMB,.";
export const CF_TMB_SPACE_DOT = "TMB .";
export const CF_TMB_DOT_COMMA = "TMB.,";

const configMap = {
	[CF_TLC_COMMA_DOT]: {
		separator: ",",
		decimal: ".",
		useVedic: true,
	},
	[CF_TMB_COMMA_DOT]: {
		separator: ",",
		decimal: ".",
	},
	[CF_TMB_SPACE_DOT]: {
		separator: " ",
		decimal: ".",
	},
	[CF_TMB_DOT_COMMA]: {
		separator: ".",
		decimal: ",",
	},
};

// To be called whenever user the preferences change
export const initializeCurrencyPreferences = async () => {
	// let crncy = await Localize.getUserCurrencyDetails();
	// let numberFormat = await Localize.getUserNubFormat();
	// global.currencyPref = crncy;
	// global.numberFormatPref = numberFormat;
};

const getConfig = (crncy) => {
	let config = {
		formatWithSymbol: true,
		symbol: crncy.symbol_native,
	};

	if (global.numberFormatPref) {
		config = { ...config, ...configMap[global.numberFormatPref.identifier] };
	}
	return config;
};

export const format = (value, crncy) => {
	return !crncy ? value : currency(round(value, 2), getConfig(crncy)).format();
};

export const formatWithOutSymbol = (value) => {
	return currency(
		round(value, 2),
		global.numberFormatPref
			? { ...configMap[global.numberFormatPref.identifier] }
			: {}
	).format();
};

export const unformat = (formattedValue, crncy) => {
	return currency(formattedValue, getConfig(crncy)).value;
};
