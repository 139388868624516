import * as LF from "./loanFields";

import { BADLOAN, DISPATCH } from "../../constants/LoanConstants";
import {
  currentUserId,
  existingLoanInvitationPath,
  existingLoanPath,
  existingSettleUpInfoPath,
  loanInvitationsPath,
  newLoanAttachmentPath,
  newLoanInvitationsPath,
  newLoanPath,
  newPaymentAttachmentPath,
  newSettleUpInfoPath,
  newUserPath,
  settleUpInfoPath,
  userPath,
  userPushNotificationTokensPath,
  userSharedLoanByLoanPath,
  userSharedLoanByUserPath,
} from "./paths";
import { database, storage } from "../../utils/firebase";
import { forEach, get, isEmpty, map, omit, pick } from "lodash";

import { DEFAULT_FIELDS_FOR_SHAREE_LOAN } from "../../constants/shareConstants";
import { Netinfo } from "../../utils/Netinfo";

export const newTransactionId = () => {
  const loanPathObject = newLoanPath();
  const { loanId } = loanPathObject;

  return loanId;
};

export const newSettleUpId = () => {
  const settleUpObject = newSettleUpInfoPath();
  const { settleUpId } = settleUpObject;

  return settleUpId;
};

export const newLoanInvitationId = () => {
  const LoanInvitation = newLoanInvitationsPath();
  const { loanInvitationId } = LoanInvitation;

  return loanInvitationId;
};

const NoInternetError = new Error(
  "Internet is unavailable. Please try after enabling it"
);

export const addLoan = async (object, onLoanAdd) => {
  try {
    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }
    await database().ref(existingLoanPath(object.transactionId)).set(object);

    console.log("Loan added successfully! ", " object: ", object);
    onLoanAdd(object);
    return object;
  } catch (error) {
    throw error;
  }
};

export const editLoan = async (object, onLoanChange, onLoanDelete) => {
  try {
    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }

    if (object.isSharedLoan) {
      const loanPath = `${userSharedLoanByUserPath(currentUserId())}/${
        object.transactionId
      }/`;

      await database()
        .ref(loanPath)
        .set(pick(object, DEFAULT_FIELDS_FOR_SHAREE_LOAN));
    } else {
      const loanPath = existingLoanPath(object.transactionId);
      await database().ref(loanPath).set(omit(object, "sharingInfo"));
    }

    if (object.loanStatus !== "Deleted") {
      onLoanChange(object);
    } else {
      //TODO: delete shared loan details from byLoan and byUser
      await deleteSharedLoanCollaborators(object.transactionId);
      onLoanDelete(object);
    }

    console.log("Loan saved successfully");
    return object;
  } catch (error) {
    console.log("Loan save error: ", error, " Loan: ", object);
    throw error;
  }
};

export const deleteLoan = async (object, onLoanDelete) => {
  //NOTE: For now delete loan is nothing but updating loan with loanStatus: "Deleted"
  // and calling editLoan method.

  const { loanDocuments, paidInfo } = object;

  if (loanDocuments && loanDocuments.length > 0) {
    await deleteLoanImage(object);
  }

  if (paidInfo && paidInfo.length > 0) {
    forEach(paidInfo, async (PInfo) => {
      const { paymentDocuments } = PInfo;
      if (paymentDocuments && paymentDocuments.length > 0) {
        await deletePaymentImage(PInfo);
      }
    });
  }

  return editLoan(
    {
      ...object,
      loanStatus: "Deleted",
    },
    null,
    onLoanDelete
  );
};

export const updateLoanPinStatus = (object, pinStatus, onLoanChange) => {
  const modifiedLoan = {
    ...object,
    isPinned: pinStatus,
  };

  return editLoan(modifiedLoan, onLoanChange);
};

export const uploadLoanImage = async (
  friendName,
  transactionId,
  image,
  dimensions,
  mode
) => {
  try {
    if (!image) {
      return;
    }

    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }
    const imagePath = newLoanAttachmentPath(
      friendName || "unknown",
      transactionId,
      mode === BADLOAN
    );
    await storage()
      .ref(imagePath)
      .putFile(image.path, { contentType: "image/jpeg" });
    const downloadURL = await storage().ref(imagePath).getDownloadURL();
    console.log("Image uploaded successfully: ", downloadURL);
    return [
      {
        imageUrl: downloadURL,
        imagePath,
        dimensions,
      },
    ];
  } catch (error) {
    console.log("Image upload error: ", error);
    throw error;
  }
};

export const uploadPaymentImage = async (
  friendName,
  transactionId,
  image,
  dimensions,
  mode
) => {
  try {
    if (!image) {
      return;
    }

    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }
    const imagePath = newPaymentAttachmentPath(
      friendName || "unknown",
      transactionId,
      mode === DISPATCH
    );
    await storage()
      .ref(imagePath)
      .putFile(image.path, { contentType: "image/jpeg" });
    const downloadURL = await storage().ref(imagePath).getDownloadURL();
    console.log("Image uploaded successfully: ", downloadURL);
    return [
      {
        imageUrl: downloadURL,
        imagePath,
        dimensions,
      },
    ];
  } catch (error) {
    console.log("Image upload error: ", error);
    throw error;
  }
};

export const deleteLoanImage = async (loan) => {
  const networkInfo = await Netinfo.fetch();
  if (!networkInfo.isInternetReachable) {
    throw NoInternetError;
  }

  try {
    const { loanDocuments } = loan;

    if (loanDocuments && loanDocuments.length > 0) {
      //Extract imageUrl from the first loan document
      const [{ imagePath }] = loanDocuments;

      if (imagePath) {
        await storage().ref(imagePath).delete();
        console.log("Deleted loan image successfully");
      }
    }
  } catch (err) {
    console.log("deleteLoanImage error: ", err);
  }
};

export const deletePaymentImage = async (payment, mode) => {
  const networkInfo = await Netinfo.fetch();
  if (!networkInfo.isInternetReachable) {
    throw NoInternetError;
  }

  const paymentDocuments =
    mode === DISPATCH ? payment.documents : payment.paymentDocuments;

  if (paymentDocuments && paymentDocuments.length > 0) {
    //Extract imageUrl from the first loan document
    const [{ imagePath }] = paymentDocuments;

    if (imagePath) {
      await storage().ref(imagePath).delete();
      console.log("Payment image deleted successfully");
    }
  }
};

export const deleteImage = async (payment) => {
  const networkInfo = await Netinfo.fetch();
  if (!networkInfo.isInternetReachable) {
    throw NoInternetError;
  }

  const { documents } = payment;

  if (documents && documents.length > 0) {
    //Extract imageUrl from the first loan document
    const [{ imagePath }] = documents;

    if (imagePath) {
      await storage().ref(imagePath).delete();
      console.log("Payment image deleted successfully");
    }
  }
};

export const getFirebaseUser = async () => {
  const path = userPath();
  return (await database().ref(path).once("value")).val();
};

export const getNotificationTokens = async () => {
  let path = userPushNotificationTokensPath();
  return await (await database().ref(path).once("value")).val();
};

export const setNotificationToken = async (token) => {
  let path = userPushNotificationTokensPath();
  await database().ref(path).push(token);
  console.log("token added successfully! ", " token: ", token);
};

export const updateOrSaveUserInFirebase = async (userUpdates) => {
  const networkInfo = await Netinfo.fetch();
  if (!networkInfo.isInternetReachable) {
    throw NoInternetError;
  }
  const path = userPath();
  const firebaseUser = await getFirebaseUser();

  const update = {
    ...firebaseUser,
    ...JSON.parse(JSON.stringify(userUpdates)),
  };

  await database().ref(path).set(update);

  return update;
};

export const addFirebaseUserUpdateEvent = async (onUserDetailsChange) => {
  const path = userPath();
  database()
    .ref(path)
    .on("value", (snapshot) => onUserDetailsChange(snapshot.val()));
};

export const removeFirebaseUserUpdateEvent = async () => {
  const path = userPath();
  database().ref(path).off();
};

export const uploadUserImage = async (imagePath) => {
  try {
    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }
    const storageImagePath = newUserPath();
    await storage()
      .ref(storageImagePath)
      .putFile(imagePath, { contentType: "image/jpeg" });
    const downloadURL = await storage().ref(storageImagePath).getDownloadURL();
    console.log("Image uploaded successfully: ", downloadURL);
    return downloadURL;
  } catch (error) {
    console.log("Image upload error: ", error);
    throw error;
  }
};

export const addOrEditSettleUp = async (
  object,
  mode,
  settleUpLoans,
  settleUps,
  updateSettleUpInfo,
  onLoanChangeMultiple
) => {
  try {
    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }

    let settleUpInfoUpdate = {};
    let updatedSettleUpLoans = [];

    forEach(settleUpLoans, async (loan) => {
      let updatedLoan = {
        ...loan,
        loanStatus: LF.LOAN_STATUS.CLOSED,
        settleUpId: object.summary.settleUpId,
      };

      settleUpInfoUpdate[existingLoanPath(loan.transactionId)] = updatedLoan;
      updatedSettleUpLoans.push(updatedLoan);
    });

    settleUpInfoUpdate[existingSettleUpInfoPath(object.summary.settleUpId)] =
      object;

    await database().ref().update(settleUpInfoUpdate);

    updateSettleUpInfo(
      {
        [object.summary.settleUpId]: object,
      },
      false
    );

    console.log("settleUp added successfully! ", object);

    onLoanChangeMultiple(updatedSettleUpLoans);

    return object;
  } catch (error) {
    throw error;
  }
};

export const deleteSettleUp = async (
  object,
  settleUpLoans,
  settleUps,
  updateSettleUpInfo,
  onLoanChangeMultiple
) => {
  try {
    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }

    let settleUpInfoUpdate = {};
    let updatedSettleUpLoans = [];

    forEach(settleUpLoans, (loan) => {
      let updatedLoan = {
        ...loan,
        loanStatus: LF.LOAN_STATUS.PENDING,
        settleUpId: null,
      };

      settleUpInfoUpdate[existingLoanPath(loan.transactionId)] = updatedLoan;
      updatedSettleUpLoans.push(updatedLoan);
    });

    settleUpInfoUpdate[existingSettleUpInfoPath(object.summary.settleUpId)] =
      null;

    await database().ref().update(settleUpInfoUpdate);

    updateSettleUpInfo({ [object.summary.settleUpId]: null }, false);

    console.log("settleUp deleted successfully! ");

    onLoanChangeMultiple(updatedSettleUpLoans);

    return;
  } catch (error) {
    throw error;
  }
};

export const listenToOwnSettleUpsChanges = async (updateSettleUpInfo) => {
  const path = settleUpInfoPath();
  database()
    .ref(path)
    .on("value", (snapshot) => updateSettleUpInfo(snapshot.val(), false));
};

export const removeSettleUpInfoUpdateEvent = async () => {
  const path = settleUpInfoPath();
  database().ref(path).off();
};

export const getOwnSettleUpInfos = async () => {
  const path = settleUpInfoPath();
  return (await database().ref(path).once("value")).val();
};

export const deleteLoanInvitation = async (oldInvitationInfo, loan) => {
  try {
    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }
    const ownerId = currentUserId();
    let oldInvitationId = oldInvitationInfo.invitationId;
    let transactionId = loan.transactionId;

    let loanInvitationUpdates = {};

    loanInvitationUpdates[existingLoanInvitationPath(oldInvitationId)] = null;

    loanInvitationUpdates[
      `${userSharedLoanByLoanPath(ownerId, transactionId)}/info/lastInvitation`
    ] = null;

    await database().ref().update(loanInvitationUpdates);
  } catch (error) {
    console.log("error: ", error);
    throw error;
  }
};

export const getUserByLoanInfo = async (transactionId) => {
  const ownerId = currentUserId();
  const path = userSharedLoanByLoanPath(ownerId, transactionId);
  return (await database().ref(path).once("value")).val();
};

export const getLoanInvitationInfo = async (invitationId) => {
  const path = existingLoanInvitationPath(invitationId);
  return (await database().ref(path).once("value")).val();
};

export const deleteCollaborator = async (
  collaboratorId,
  transactionId,
  ownerId
) => {
  try {
    const networkInfo = await Netinfo.fetch();
    if (!networkInfo.isInternetReachable) {
      throw NoInternetError;
    }

    let collaboratorUpdate = {};

    collaboratorUpdate[
      `${userSharedLoanByLoanPath(
        ownerId,
        transactionId
      )}/collaborators/${collaboratorId}`
    ] = null;

    collaboratorUpdate[
      `${userSharedLoanByUserPath(collaboratorId)}/${transactionId}`
    ] = null;

    await database().ref().update(collaboratorUpdate);
  } catch (error) {
    console.log("error: ", error);
    throw error;
  }
};

export const deleteSharedLoanCollaborators = async (transactionId) => {
  const networkInfo = await Netinfo.fetch();
  if (!networkInfo.isInternetReachable) {
    throw NoInternetError;
  }

  const collaboratorUpdate = {};
  const sharedByLoanInfo = await getUserByLoanInfo(transactionId);
  if (!sharedByLoanInfo) return;

  const collaborators = get(sharedByLoanInfo, "collaborators", null);
  if (!isEmpty(collaborators)) {
    await Promise.all(
      map(collaborators, ({ collaborator }) => {
        collaboratorUpdate[
          `${userSharedLoanByUserPath(collaborator.id)}/${transactionId}`
        ] = null;
      })
    );
  }

  collaboratorUpdate[
    `${userSharedLoanByLoanPath(currentUserId(), transactionId)}`
  ] = null;

  if (!isEmpty(sharedByLoanInfo.info.lastInvitation)) {
    collaboratorUpdate[
      `${loanInvitationsPath()}${
        sharedByLoanInfo.info.lastInvitation.invitationId
      }`
    ] = null;
  }
  await database().ref().update(collaboratorUpdate);
};

export const updateUserProfileToSharedLoans = async (name, photoURL) => {
  const generateCollaboratorNameUpdates = {};

  const allSharedLoans = await database()
    .ref(userSharedLoanByLoanPath(currentUserId()))
    .once("value");

  const allShareeLoans = await database()
    .ref(userSharedLoanByUserPath(currentUserId()))
    .once("value");

  const byUserOwnerProfilePath = (collaboratorId, transactionId) =>
    `${userSharedLoanByUserPath(collaboratorId)}/${transactionId}/owner`;

  const byLoanCollaboraterProfilePath = (ownerId, transactionId) =>
    `${userSharedLoanByLoanPath(
      ownerId,
      transactionId
    )}/collaborators/${currentUserId()}/collaborator`;

  forEach(allSharedLoans.val(), ({ collaborators, info }, transactionId) => {
    forEach(collaborators, ({ collaborator }) => {
      generateCollaboratorNameUpdates[
        `${byUserOwnerProfilePath(collaborator.id, transactionId)}/name`
      ] = name;

      generateCollaboratorNameUpdates[
        `${byUserOwnerProfilePath(collaborator.id, transactionId)}/photoURL`
      ] = photoURL;

      if (get(info, "lastInvitation", false)) {
        generateCollaboratorNameUpdates[
          `${loanInvitationsPath()}/${
            info.lastInvitation.invitationId
          }/owner/name`
        ] = name;
        generateCollaboratorNameUpdates[
          `${loanInvitationsPath()}/${
            info.lastInvitation.invitationId
          }/owner/photoUrl`
        ] = photoURL;
      }
    });
  });

  forEach(allShareeLoans.val(), ({ transactionId, owner }) => {
    generateCollaboratorNameUpdates[
      `${byLoanCollaboraterProfilePath(owner.id, transactionId)}/name`
    ] = name;

    generateCollaboratorNameUpdates[
      `${byLoanCollaboraterProfilePath(owner.id, transactionId)}/photoURL`
    ] = photoURL;
  });
  if (!isEmpty(generateCollaboratorNameUpdates)) {
    database().ref().update(generateCollaboratorNameUpdates);
  }
};

export const addSharedLoanInfoUpdateEvent = async (
  transactionId,
  updateSharedLoan
) => {
  const ownerId = currentUserId();
  const path = userSharedLoanByLoanPath(ownerId, transactionId);
  database()
    .ref(path)
    .on("value", (snapshot) => updateSharedLoan(snapshot.val()));
};

export const removeSharedLoanInfoUpdateEvent = async (transactionId) => {
  const ownerId = currentUserId();
  const path = userSharedLoanByLoanPath(ownerId, transactionId);
  database().ref(path).off();
};
