const TRANSACTION_TYPE_LENT = "Lent";
const TRANSACTION_TYPE_OWES = "Owes";
const SETTLE_UP = "SETTLE_UP";

const WEEKLY = "Weekly";
const BI_WEEKLY = "Bi_Weekly";
const BI_WEEKLY_IOS_KEY = "Bi-Weekly";
const MONTHLY = "Monthly";
const BI_MONTHLY = "Bi_Monthly";
const QUARTERLY = "Quarterly";
const HALF_YEARLY = "Half_yearly";
const YEARLY = "Yearly";
const ANNUALLY = "Annually";

const COMPOUNDING_INTERVALS = [
  WEEKLY,
  BI_WEEKLY,
  MONTHLY,
  QUARTERLY,
  HALF_YEARLY,
  ANNUALLY,
];

const RFL_MAX_INSTALLMENTS = 1560; /* This corresponds to 30 years of weekly installments */

const DEFAULT_PAYMENT = "default payment";
const RECORD_NEW_PAYMENT = "record new payment";
const PAY_REPAYMENT = "REPAID";
const EDIT_AND_PAY_REPAYMENT = "PARTIAL";
const SKIP_REPAYMENT = "SKIPPED";
const NOT_REPAID = "None";

const DISPATCH = "Dispatch";
const EDIT = "Edit";
const EXPORT = "Export";
const SHARE = "Share";
const BADLOAN = "Bad Loan";
const FORMAL_LOAN_DARK_BLUE = "Formal Loan Dark Blue";

const INTEREST_CALCULATION_STRATEGY = Object.freeze({
  DAY_END: "DAY_END",
  MONTH_END: "MONTH_END",
  INTERVAL_END: "INTERVAL_END",
});

const INTEREST_BREAKUP_END_OFFSET_INTERVALS = 1;

const SUCCESS = "SUCCESS";

//For Notifications
const ADD_PAYMENT = "ADD_PAYMENT";
const EDIT_PAYMENT = "EDIT_PAYMENT";
const REMOVE_PAYMENT = "REMOVE_PAYMENT";
const SHAREE_ACCESS_SELF_REVOKE = "SHAREE_ACCESS_SELF_REVOKE";
const SHAREE_ADDED = "SHAREE_ADDED";
const SHARER_ACCESS_REVOKE = "SHARER_ACCESS_REVOKE";
const LOAN_DELETE = "LOAN_DELETE";
const EDIT_LOAN = "EDIT_LOAN";
const ADD_DISPATCH = "ADD_DISPATCH";
const EDIT_DISPATCH = "EDIT_DISPATCH";
const REMOVE_DISPATCH = "REMOVE_DISPATCH";

// Formal Loans
const FLOATING_INTEREST = "FLOATING_INTEREST";
const FIXED_INTEREST = "FIXED_INTEREST";
const LOAN_OPENING_CHARGES = "LOAN_OPENING_CHARGES";
const FORMAL_LOAN_DISPATCHES = "FORMAL_LOAN_DISPATCHES";
const EDIT_FORMAL_LOAN = "EDIT_FORMAL_LOAN";
const FORMAL_LOAN = "FORMAL_LOAN";
const FORMAL = "formal";
const DEFAULT = "Default";
const RECURRING = "recurring";
const PAID = "Paid";
const LUMPSUM = "lumpsum";
const PENDING = "Pending";
const CLOSED = "Closed";
const SCHEDULED = "scheduled";

const SIMPLE_INTEREST = "simpleInterest";
const COMPOUND_INTEREST = "compoundInterest";

const CHANGE_DUE_DATE = "changeDueDate";
const CHANGE_PAYMENT_PLAN = "changePaymentPlan";
const SET_PAYMENT_PLAN = "setPaymentPlan";
const MARK_AS_BAD_LOAN = "markAsBadLoan";

module.exports = {
  TRANSACTION_TYPE_LENT,
  TRANSACTION_TYPE_OWES,
  SETTLE_UP,
  WEEKLY,
  BI_WEEKLY,
  BI_WEEKLY_IOS_KEY,
  MONTHLY,
  BI_MONTHLY,
  QUARTERLY,
  HALF_YEARLY,
  YEARLY,
  ANNUALLY,
  COMPOUNDING_INTERVALS,
  RFL_MAX_INSTALLMENTS,
  DEFAULT_PAYMENT,
  RECORD_NEW_PAYMENT,
  PAY_REPAYMENT,
  EDIT_AND_PAY_REPAYMENT,
  SKIP_REPAYMENT,
  NOT_REPAID,
  DISPATCH,
  EDIT,
  EXPORT,
  SHARE,
  BADLOAN,
  FORMAL_LOAN_DARK_BLUE,
  INTEREST_CALCULATION_STRATEGY,
  INTEREST_BREAKUP_END_OFFSET_INTERVALS,
  SUCCESS,
  ADD_PAYMENT,
  EDIT_PAYMENT,
  REMOVE_PAYMENT,
  SHAREE_ACCESS_SELF_REVOKE,
  SHAREE_ADDED,
  SHARER_ACCESS_REVOKE,
  LOAN_DELETE,
  EDIT_LOAN,
  ADD_DISPATCH,
  EDIT_DISPATCH,
  REMOVE_DISPATCH,
  FLOATING_INTEREST,
  FIXED_INTEREST,
  LOAN_OPENING_CHARGES,
  FORMAL_LOAN_DISPATCHES,
  EDIT_FORMAL_LOAN,
  FORMAL_LOAN,
  FORMAL,
  DEFAULT,
  RECURRING,
  PAID,
  LUMPSUM,
  PENDING,
  CLOSED,
  SCHEDULED,
  SIMPLE_INTEREST,
  COMPOUND_INTEREST,
  CHANGE_DUE_DATE,
  CHANGE_PAYMENT_PLAN,
  SET_PAYMENT_PLAN,
  MARK_AS_BAD_LOAN,
};
