import axios from "axios";
import "firebase/auth";
import "firebase/database";
import { HOST } from "../../constants/config";

class Firebase {
	signInWithMobile = (user) => {
		return new Promise((resolve, reject) => {
			return user.getIdToken().then((idToken) => {
				axios
					.post(`${HOST}/admin/auth/login/`, {
						params: { idToken },
					})
					.then((res) => {
						console.log("GetIdToken response: ", res);
						if (res.data.fb_session_cookie) {
							window.localStorage.setItem(
								"fb_session_cookie",
								res.data.fb_session_cookie
							);
						}
						resolve();
					})
					.catch((error) => {
						console.log("error", error);
						reject();
					});
			});
		});
	};

	signout = () => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${HOST}/admin/auth/logout/`, {
					params: {
						sessionCookie: window.localStorage.getItem("fb_session_cookie"),
					},
				})
				.then((res) => {
					console.log("Signout response: ", res);
					window.localStorage.clear();
					resolve();
				})
				.catch((error) => {
					console.log("error", error);
					reject();
				});
		});
	};

	v1users = () => this.db.ref("v1/users");
	v1UniqueId = () => this.db.ref("v1/uniqueIdFromcustomIdInfo");
	v1CustomId = () => this.db.ref("v1/customIdFromUniqueIdInfo");
	v2UniqueId = () => this.db.ref("v1/users");

	v2users = () => this.db.ref("v2users");
}

export default Firebase;
