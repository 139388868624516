import React, { useEffect } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FriendsTransactionCardComponent from "../Migration/FriendsTransactionCardComponent";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    marginTop: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function FriendsExpansionPanels(props) {
  const classes = useStyles();
  const [friendList, setFriendList] = React.useState([]);

  useEffect(() => {
    // Update the document title using the browser API
    let transactions = props.transactions;
    let list = [];
    let friendsListGrouped = _.groupBy(_.values(transactions), "friend.name");
    _.forIn(friendsListGrouped, (transactions, friendName) => {
      list.push({
        friendName,
        transactions,
      });
    });

    setFriendList(list);
  }, [props.transactions]);

  return (
    <div className={classes.root}>
      <h4 style={{ marginLeft: 20, marginBottom: 0 }}>Transactions</h4>
      {friendList.map((friend) => {
        return (
          <ExpansionPanel key={friend.friendName}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id={friend.friendName}
            >
              <Typography className={classes.heading}>
                {friend.friendName}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Transactions: {friend.transactions.length}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              <h4 style={{ marginBottom: 0 }}>Transaction Details</h4>
              {friend.transactions.map((transaction) => {
                return (
                  <FriendsTransactionCardComponent
                    key={transaction.transactionId}
                    transactionDetails={transaction}
                    userDetails={props.user}
                  />
                );
              })}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
}
