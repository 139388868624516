import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
let moment = require("moment");

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export const numberFormat = (value, code, localeId) =>
  new Intl.NumberFormat(localeId, {
    style: "currency",
    currency: "INR",
  }).format(value);

export default function FriendsTransactionCardComponent(props) {
  const classes = useStyles();
  let details = props.transactionDetails;
  let userDetails = props.userDetails;
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <CardActions style={{ marginTop: -10 }}>
          <Typography className={classes.pos} color="textSecondary">
            Transaction Id:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.transactionId}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            Principal Amount:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {numberFormat(
              details.principleAmount,
              details.currency ? details.currency.code : userDetails.code,
              userDetails.localeId
            )}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            Principle Balance:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {numberFormat(
              details.principleBalance,
              details.currency ? details.currency.code : userDetails.code,
              userDetails.localeId
            )}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            CreateDate:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {moment(details.createDate * 1000).format("Do MMM, YYYY")}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            DueDate:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.dueDate === undefined
              ? "-"
              : moment(details.dueDate * 1000).format("Do MMM, YYYY")}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            Mobile number:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.dueDate === undefined ? "-" : details.friend.phoneNumber}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography
            className={classes.pos}
            color={details.loanStatus === "Deleted" ? "error" : "textSecondary"}
          >
            Loan status:
          </Typography>
          <Typography
            className={classes.pos}
            color={details.loanStatus === "Deleted" ? "error" : "textPrimary"}
          >
            {details.loanStatus}
          </Typography>
        </CardActions>

        <CardActions style={{ marginTop: -20 }}>
          <Typography className={classes.pos} color="textSecondary">
            Notes:
          </Typography>
          <Typography className={classes.pos} color="textPrimary">
            {details.notes === undefined || details.notes === ""
              ? "-"
              : details.notes}
          </Typography>
        </CardActions>
      </CardContent>
    </Card>
  );
}
