import "./index.css";

import React, { useEffect, useState } from "react";
import { map, round, sumBy } from "lodash";

import { PAYMENT_STATUS } from "../../constants/collectionListStatus";
import { ReportHeader } from "../ReportHeader";
import axios from "axios";
import { collectionListUrlV3 } from "../../env";
import qs from "query-string";

export const V3CollectionListPDF = () => {
  const [data, setData] = useState({
    isLoaded: false,
    result: {},
  });

  const params = qs.parseUrl(window.location.search).query;
  const { localeId = "en-IN" } = params;

  useEffect(() => {
    axios
      .get(collectionListUrlV3(), {
        params,
      })
      .then((result) => {
        setData({
          isLoaded: true,
          result: result.data.data || {},
          error: null,
        });
      })
      .catch((err) => {
        console.log(err);
        setData({
          isLoaded: true,
          result: {},
          error: new Error(err?.response?.data?.message),
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data.isLoaded) {
    return <></>;
  }

  const leftAlign = {
    textAlign: "left",
  };
  const rightAlign = {
    textAlign: "right",
  };
  const centerAlign = {
    textAlign: "center",
  };
  let tableLines = {
    border: "1px solid black",
    borderCollapse: "collapse",
  };
  let titleColor = {
    color: "green",
  };

  const headersAlign = {
    verticalAlign: "middle",
  };

  const tableMargins = {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  };

  const V3CollectionList = ({ preparedData, user, organization, localeId }) => {
    console.log({ localeId });
    return (
      <>
        <ReportHeader {...{ user, organization }} />
        <div className="collection">
          {map(preparedData, ({ title, data }) => {
            return data.length ? (
              <>
                <table
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  className="collection_list_table"
                >
                  <caption>
                    <h1
                      style={{
                        ...leftAlign,
                        color: "green",
                        paddingBottom: 10,
                      }}
                    >
                      {title}
                    </h1>
                  </caption>
                  <tr>
                    <th>S. No.</th>
                    <th>Date</th>
                    <th>
                      Name
                      <br />
                      Mobile
                    </th>
                    <th>
                      Principal <br /> Interest
                    </th>
                    <th>Amount To Be Collected</th>
                    <th>Amount Collected</th>
                    <th>Pending Amount</th>
                    <th>Outstanding Loan Amount</th>
                    <th>Status</th>
                  </tr>
                  {map(
                    data,
                    (
                      {
                        date,
                        amount = 0,
                        mobile,
                        email,
                        name,
                        towardsPrincipal = 0,
                        towardsInterest = 0,
                        status,
                        extraData,
                        paidAmount = 0,
                        pendingAmount = 0,
                        outstandingAmount = 0,
                      },
                      index
                    ) => {
                      return (
                        <>
                          <tr>
                            <td style={centerAlign} colspan="1" rowspan="2">
                              <div>{index + 1}</div>
                            </td>
                            <td style={leftAlign} colspan="1" rowspan="2">
                              <div>{date}</div>
                            </td>
                            <td style={leftAlign}>{name}</td>
                            <td style={rightAlign}>
                              {towardsPrincipal.toLocaleString(localeId) || 0}
                            </td>
                            <td style={rightAlign} colspan="1" rowspan="2">
                              <div>{amount.toLocaleString(localeId)}</div>
                            </td>
                            <td style={rightAlign} colspan="1" rowspan="2">
                              <div>{paidAmount.toLocaleString(localeId)}</div>
                            </td>
                            <td
                              style={{ ...rightAlign }}
                              colspan="1"
                              rowspan="2"
                            >
                              {pendingAmount === 0 ? (
                                0
                              ) : (
                                <div style={{ color: "red" }}>
                                  {pendingAmount.toLocaleString(localeId)}
                                </div>
                              )}
                            </td>
                            <td style={rightAlign} colspan="1" rowspan="2">
                              <div>
                                {outstandingAmount.toLocaleString(localeId)}
                              </div>
                            </td>
                            <td style={centerAlign} colspan="1" rowspan="2">
                              {paidAmount === 0 &&
                              amount ===
                                pendingAmount ? null : pendingAmount === 0 &&
                                status === PAYMENT_STATUS.PAYMENT_PAID ? (
                                <span style={{ color: "green" }}>
                                  Collected
                                </span>
                              ) : (
                                <span style={{ color: "orange" }}>
                                  Partially Collected
                                </span>
                              )}
                              {status === PAYMENT_STATUS.PAYMENT_SKIP ? (
                                <span style={{ color: "red" }}>Skipped</span>
                              ) : null}
                              {extraData.isSettledPayment ? (
                                <>
                                  <br />
                                  <span>Settled</span>
                                </>
                              ) : null}
                              {!!extraData.badLoanInfo ? (
                                <>
                                  {status ===
                                  PAYMENT_STATUS.PAYMENT_PENDING ? null : (
                                    <br />
                                  )}
                                  <span style={{ color: "red" }}>Bad Loan</span>
                                </>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td style={leftAlign}>{mobile || email || ""}</td>
                            <td style={rightAlign}>
                              {towardsInterest.toLocaleString(localeId)}
                            </td>
                          </tr>
                        </>
                      );
                    }
                  )}
                  <tr>
                    <td colspan="4" rowspan="2">
                      <b>
                        <div style={centerAlign}>Total</div>
                      </b>
                    </td>
                    <td style={rightAlign}>
                      <b>
                        {round(sumBy(data, "amount", 0), 2).toLocaleString(
                          localeId
                        )}
                      </b>
                    </td>
                    <td style={rightAlign}>
                      <b>
                        {round(sumBy(data, "paidAmount", 0), 2).toLocaleString(
                          localeId
                        )}
                      </b>
                    </td>
                    <td style={rightAlign}>
                      <b>
                        {round(
                          sumBy(data, "pendingAmount", 0),
                          2
                        ).toLocaleString(localeId)}
                      </b>
                    </td>
                    <td colspan="2" rowspan="2"></td>
                  </tr>
                </table>
              </>
            ) : null;
          })}
        </div>
      </>
    );
  };

  const ErrorView = ({ error }) => {
    return <div className=""> {error.message} </div>;
  };

  return (
    <div id="export-content">
      {data.error ? (
        <ErrorView error={data.error} />
      ) : (
        <V3CollectionList
          preparedData={data.result?.preparedData}
          user={data.result?.user}
          organization={data.result?.organization}
          localeId={localeId}
        />
      )}
    </div>
  );
};
