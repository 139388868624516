import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Route, withRouter, Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Migration from "../Migration";
import Statistics from "../Statistics";
import Login from "../Login/";
import { matchesRoute } from "../../utils/pathUtils";
import Button from "@material-ui/core/Button";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const DEFAULT_TITLE = "FreeBird Admin Dashboard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  appBar: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  signoutContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 5,
  },
}));

export default compose(
  withRouter,
  withFirebase
)(function Navigation({ history, firebase }) {
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);

  const handleChange = (event, newValue) => {
    const route = [ROUTES.STATISTICS, ROUTES.MIGRATION][newValue];
    history.push(route);
  };

  const firebaseLoginSuccessful = (result) => {
    setIsLoggedIn(true);
  };

  const logout = async () => {
    await firebase.signout();
    setIsLoggedIn(false);
  };

  useEffect(() => {
    if (window.localStorage.getItem("fb_session_cookie")) {
      // Show loader
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    const titlePrefix = matchesRoute(ROUTES.STATISTICS)
      ? "Statistics"
      : matchesRoute(ROUTES.MIGRATION)
      ? "Migration"
      : DEFAULT_TITLE;

    document.title = `${titlePrefix} - ${DEFAULT_TITLE}`;
  });

  return !isLoggedIn ? (
    <Login loginSuccessful={firebaseLoginSuccessful} />
  ) : (
    <div className={classes.root}>
      <Redirect from='/' to='/statistics' />
      <AppBar position='static' className={classes.appBar}>
        <Tabs
          value={
            matchesRoute(ROUTES.STATISTICS)
              ? 0
              : matchesRoute(ROUTES.MIGRATION)
              ? 1
              : 0
          }
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <Tab label='Statistics' {...a11yProps(0)} />
          <Tab label='Migration' {...a11yProps(1)} />
        </Tabs>
        <div className={classes.signoutContainer}>
          <Button
            color='inherit'
            onClick={logout}
            startIcon={<ExitToAppIcon />}
          >
            Signout
          </Button>
        </div>
      </AppBar>
      <TabPanel value={matchesRoute(ROUTES.MIGRATION) ? 0 : 0} index={0}>
        <Route exact path={ROUTES.STATISTICS} component={Statistics} />
      </TabPanel>
      <TabPanel value={matchesRoute(ROUTES.MIGRATION) ? 1 : 0} index={1}>
        <Route exact path={ROUTES.MIGRATION} component={Migration} />
      </TabPanel>
    </div>
  );
});
