import React, { useMemo } from "react";
import RepaymentsAndSchedule, { ItemsList } from "./RepaymentsAndSchedule";
import { get, has, map, values } from "lodash";

import { DISPATCH_MODE } from "./constants";
import DebtRepaymentProgress from "./DebtRepaymentProgress";

export default ({ loan, options = {}, organization = {}, userId }) => {
  const { loanNotes, includeRepayments } = options;
  let {
    buddy,
    isSharedLoan,
    transactionType,
    isFormalLoan = false,
    isDailyFinance = false,
    interestStartDateString,
    customFields = {},
  } = loan;

  let { name, bankName, photoURL, phoneNumber, email, address } = buddy;

  const friendName = get(
    buddy,
    "name",
    get(loan, isSharedLoan ? "info.owner.name" : "friend.name")
  );

  function customSort(a, b) {
    const aIndexMatch = a.name.match(/^\d+(\.\d+)+/);
    const bIndexMatch = b.name.match(/^\d+(\.\d+)+/);

    // Handle cases where the 'name' property might not start with a valid index
    if (!aIndexMatch || !bIndexMatch) {
      return a.name.localeCompare(b.name);
    }

    const aIndex = aIndexMatch[0];
    const bIndex = bIndexMatch[0];

    if (aIndex !== bIndex) {
      return aIndex.localeCompare(bIndex);
    } else {
      // Remove the index part from the name for further comparison
      const aRest = a.name.replace(aIndex, "").trim();
      const bRest = b.name.replace(bIndex, "").trim();

      const aFirstLetter = aRest ? aRest[0].toLowerCase() : "";
      const bFirstLetter = bRest ? bRest[0].toLowerCase() : "";

      return aFirstLetter.localeCompare(bFirstLetter);
    }
  }

  // Convert the object to an array for sorting
  const customFieldsArray =
    Object.values(organization?.customFields || {}) || [];

  // Sort the array based on the customSort function
  const sortedCustomFieldsArray = customFieldsArray.sort(customSort);

  // Create a new object with the sorted values and their original keys
  const sortedCustomFields = {};
  sortedCustomFieldsArray.forEach((field) => {
    sortedCustomFields[field.id] = field;
  });

  organization.customFields = sortedCustomFields;

  const childLeft = useMemo(() => {
    return isDailyFinance ? (
      <div className="child left">
        <div className="summary-table-row">
          <p className="title">{loan.createdAtString.title}</p>
          <p className="data">{loan.createdAtString.value}</p>
        </div>
        <div className="summary-table-row">
          <p className="title">{loan.amountPaidOut.title}</p>
          <p className="data">{loan.amountPaidOut.value}</p>
        </div>
        <div className="summary-table-row">
          <p className="title">{loan.interestRate.title}</p>
          <p className="data">
            {loan?.interestRate?.value ? loan?.interestRate?.value : "N/A"}
          </p>
        </div>
        <div className="summary-table-row">
          <p className="title">{loan.installments.title}</p>
          <p className="data">{loan.installments.value}</p>
        </div>
        <div className="summary-table-row">
          <p className="title">{loan.closedBy.title}</p>
          <p className="data">{loan.closedBy.value}</p>
        </div>
      </div>
    ) : (
      <div className="child left">
        <div className="summary-table-row">
          <p className="title">{loan.createdAtString.title}</p>
          <p className="data">{loan.createdAtString.value}</p>
        </div>
        {isFormalLoan ? (
          <div className="summary-table-row">
            <p className="title">{loan.disbursedAtString.title}</p>
            <p className="data">{loan.disbursedAtString.value}</p>
          </div>
        ) : null}
        <div className="summary-table-row">
          <p className="title">
            {loan && loan.formattedPrincipalAmount
              ? loan.formattedPrincipalAmount.title
              : loan.formattedPrincipleAmount.title}
          </p>
          <p className="data">
            {loan && loan.formattedPrincipalAmount
              ? loan.formattedPrincipalAmount.value
              : loan.formattedPrincipleAmount.value}
          </p>
        </div>
        <div className="summary-table-row">
          <p className="title">{loan.interestRateString.title}</p>
          <p className="data">
            {loan?.interestRateString?.value
              ? loan?.interestRateString?.value
              : "N/A"}
          </p>
        </div>
        {isFormalLoan ? (
          <div className="summary-table-row">
            <p className="title">
              {loan.formattedInstallmentAmountString.title}
            </p>
            <p className="data">
              {loan.formattedInstallmentAmountString.value}
            </p>
          </div>
        ) : null}
        <div className="summary-table-row bold">
          <p className="title">
            {get(
              loan,
              `${
                isFormalLoan
                  ? "firstInstallmentDateString"
                  : "formattedTotalRemaining"
              }.title`
            )}
          </p>
          <p className="data">
            {get(
              loan,
              `${
                isFormalLoan
                  ? "firstInstallmentDateString"
                  : "formattedTotalRemaining"
              }.value`
            )}
          </p>
        </div>
        {interestStartDateString ? (
          <div className="summary-table-row">
            <p className="title">{interestStartDateString.title}</p>
            <p className="data">{interestStartDateString.value}</p>
          </div>
        ) : null}
        <div className="summary-table-row">
          <p className="title">{loan.dueDateString.title}</p>
          <p className="data">{loan.dueDateString.value}</p>
        </div>
      </div>
    );
  }, [interestStartDateString, isFormalLoan, isDailyFinance, loan]);

  const childRight = useMemo(() => {
    return isDailyFinance ? (
      <div className="child">
        <div className="summary-table-row">
          <p className="title">{loan.paymentValueType.title}</p>
          <p className="data">{loan.paymentValueType.value}</p>
        </div>
        <div className="summary-table-row">
          <p className="title">{loan.interestAmount.title}</p>
          <p className="data">{loan.interestAmount.value}</p>
        </div>
        <div className="summary-table-row">
          <p className="title">{loan.pendingInstallments.title}</p>
          <p className="data">{loan.pendingInstallments.value}</p>
        </div>
        {includeRepayments && (
          <div className="summary-table-row">
            <p className="title">{loan.CollectedSoFar.title}</p>
            <p className="data">{loan.CollectedSoFar.value}</p>
          </div>
        )}
      </div>
    ) : (
      <div className="child">
        {isFormalLoan ? (
          <div className="summary-table-row">
            <p className="title">{loan.tenureString.title}</p>
            <p className="data">{loan.tenureString.value}</p>
          </div>
        ) : null}
        <div className="summary-table-row">
          <p className="title">
            {get(
              loan,
              `${
                isFormalLoan
                  ? "formattedAmountPaid"
                  : "formattedPrincipalBalance"
              }.title`
            )}
          </p>
          <p className="data">
            {get(
              loan,
              `${
                isFormalLoan
                  ? "formattedAmountPaid"
                  : "formattedPrincipalBalance"
              }.value`
            )}
          </p>
        </div>
        {isFormalLoan ? (
          <div className="summary-table-row">
            <p className="title">{loan.formattedTotalRemaining.title}</p>
            <p className="data">{loan.formattedTotalRemaining.value}</p>
          </div>
        ) : null}
        {includeRepayments ? (
          <div className="summary-table-row">
            <p className="title">{loan.repaymentsString.title}</p>
            <p className="data">{loan.repaymentsString.value}</p>
          </div>
        ) : null}
        {isFormalLoan ? (
          <div className="summary-table-row bold">
            <p className="title">
              {get(loan, "nextInstallmentDateString.title")}
            </p>
            <p className="data">
              {get(loan, "nextInstallmentDateString.value")}
            </p>
          </div>
        ) : null}
        {!isFormalLoan && includeRepayments ? (
          <div className="summary-table-row bold">
            <p className="title">{get(loan, "formattedAmountPaid.title")}</p>
            <p className="data">{get(loan, "formattedAmountPaid.value")}</p>
          </div>
        ) : null}
        {has(loan, "formattedInterestDue") ? (
          <div className="summary-table-row bold">
            <p className="title">{get(loan, "formattedInterestDue.title")}</p>
            <p className="data">{get(loan, "formattedInterestDue.value")}</p>
          </div>
        ) : null}
        {has(loan, "formattedThisMonthInterest") ? (
          <div className="summary-table-row bold">
            <p className="title">
              {get(loan, "formattedThisMonthInterest.title")}
            </p>
            <p className="data">
              {get(loan, "formattedThisMonthInterest.value")}
            </p>
          </div>
        ) : null}
        {interestStartDateString ? (
          <div className="summary-table-row">
            <p className="title">No of Dispatches</p>
            <p className="data">{loan.dispatchesCount}</p>
          </div>
        ) : null}
      </div>
    );
  }, [
    includeRepayments,
    interestStartDateString,
    isFormalLoan,
    isDailyFinance,
    loan,
  ]);

  return (
    <div className="loan">
      {isFormalLoan ? (
        <div className="name-title">Name of the loan: {name}</div>
      ) : null}
      <div className={isFormalLoan ? "subtitle" : "name-title"}>
        Debt {transactionType === "Lent" ? "given to" : "taken from"}
        {": "}
        {isFormalLoan ? bankName : ""}
        {/* {has(loan, "badLoanInfo") ? " (Bad loan)" : ""} */}
      </div>
      {!isFormalLoan ? (
        <table className="buddy-info-container">
          <tr>
            {/* {photoURL ? (
              <td className="buddy-base-view">
                <img className="buddy-photo" src={photoURL} alt={photoURL} />
              </td>
            ) : null} */}
            <td className="buddy-info">
              <p className="title">Name: {friendName}</p>
              {phoneNumber ? (
                <p className="title">
                  Phone Number:{" "}
                  <a className="link" href={`tel: ${phoneNumber}`}>
                    {phoneNumber}
                  </a>
                </p>
              ) : null}
              {email ? (
                <p className="title">
                  Email:{" "}
                  <a className="link" href={`mailto: ${email}`}>
                    {email}
                  </a>
                </p>
              ) : null}
              {address ? <p className="title">Address: {address}</p> : null}
            </td>
          </tr>
        </table>
      ) : null}
      <div>
        {organization?.customFields &&
        values(organization?.customFields).length > 0 &&
        values(customFields).length > 0 ? (
          <div className="summary-title">
            Additional Details
            <div className="content">
              <div className="split-2">
                <div className="child left">
                  {organization?.customFields &&
                    values(customFields).length > 0 &&
                    map(organization?.customFields, (value, key) =>
                      value ? (
                        <div className="summary-table-row">
                          <p className="title">{value?.name}</p>
                          <p className="data">
                            {customFields[key]?.value || "NA"}
                          </p>
                        </div>
                      ) : null
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="summary-title">Debt Details (Summary):</div>
      <div className="content">
        <div className="split-2">
          {childLeft}
          {childRight}
        </div>
        {loanNotes && !!loan.notes && (
          <div className="notes-box">
            <div className="notes">Notes: {loan.notes}</div>
          </div>
        )}
        {get(loan, "dispatches", []).length > 1 ? (
          <>
            <div className="title" style={{ marginTop: 10 }}>
              Dispatches as part of this Debt
            </div>
            <ItemsList
              loan={loan}
              mode={DISPATCH_MODE}
              showLoanNotes={loanNotes}
            />
          </>
        ) : null}
        {/* Debt Repayment Progress */}
        <DebtRepaymentProgress loan={loan} />
        <RepaymentsAndSchedule loan={loan} options={options} userId={userId} />
      </div>
    </div>
  );
};
