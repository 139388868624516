import React from "react";
import {
	BUTTON_LENT_LIGHT_GRADIENT_END,
	BUTTON_LENT_LIGHT_GRADIENT_START,
	BUTTON_OWES_LIGHT_GRADIENT_END,
	BUTTON_OWES_LIGHT_GRADIENT_START,
	TRANSPARENT,
} from "../../constants/colors";
import { Image } from "../react-native/imports";
import LinearGradient from "./LinearGradient";
import PlaceholderText from "./PlaceholderText";

export default ({
	containerStyle,
	image,
	friendName,
	placeholderTextStyle,
	isBackgroundTransparent,
	transactionTypeOWE,
}) => {
	return (
		<LinearGradient
			start={{ x: 0, y: 0 }}
			end={{ x: 1, y: 0 }}
			colors={
				isBackgroundTransparent
					? [TRANSPARENT, TRANSPARENT]
					: !transactionTypeOWE
					? [BUTTON_LENT_LIGHT_GRADIENT_START, BUTTON_LENT_LIGHT_GRADIENT_END]
					: [BUTTON_OWES_LIGHT_GRADIENT_END, BUTTON_OWES_LIGHT_GRADIENT_START]
			}
			style={containerStyle}
		>
			{image ? (
				<Image source={image} />
			) : (
				<PlaceholderText name={friendName} style={placeholderTextStyle} />
			)}
		</LinearGradient>
	);
};
