import React from "react";
import { Text } from "../react-native/imports";

export default ({ name, style }) => {
	const words = name.split(" ");
	const placeholderText =
		words.length >= 2
			? words[0].charAt(0) + words[1].charAt(0)
			: words[0].charAt(0);
	return <Text style={style}>{placeholderText.toUpperCase()}</Text>;
};
