import {
  GENERATE_COLLECTION_LIST,
  GENERATE_PAYOFF_PLANNER_PDF,
  GENERATE_PDF,
  SHARED_LOAN,
  V3_GENERATE_COLLECTION_LIST,
  V3_GENERATE_PAYOFF_PLANNER_PDF,
  V3_GENERATE_PDF,
  V3_SHARED_LOAN,
} from "../../constants/routes";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

import { GeneratePdfV2 } from "../GeneratePDFV2";
import { GeneratePdfV3 } from "../GeneratePdfV3";
import Navigation from "../Navigation";
import React from "react";
import { SharedLoanV2 } from "../SharedLoanV2";
import { SharedLoanV3 } from "../SharedLoanV3";
import { V2CollectionListPDF } from "../CollectionListV2";
import { V2PayoffPlannerPDF } from "../PayoffPlannerV2";
import { V3CollectionListPDF } from "../CollectionListV3";
import { V3PayoffPlannerPDF } from "../PayoffPlannerV3";
import green from "@material-ui/core/colors/green";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: green[500],
    },
  },
});
const App = () => {
  return (
    <Router>
      <Switch>
        {/* Route to generate pdf if it exactly matches, else, to the others */}
        <Route exact path={GENERATE_PDF}>
          <GeneratePdfV2 />
        </Route>
        <Route exact path={V3_GENERATE_PDF}>
          <GeneratePdfV3 />
        </Route>
        <Route path={SHARED_LOAN}>
          <ThemeProvider theme={theme}>
            <SharedLoanV2 />
          </ThemeProvider>
        </Route>

        <Route path={V3_SHARED_LOAN}>
          <ThemeProvider theme={theme}>
            <SharedLoanV3 />
          </ThemeProvider>
        </Route>

        <Route exact path={GENERATE_PAYOFF_PLANNER_PDF}>
          <V2PayoffPlannerPDF />
        </Route>

        <Route exact path={V3_GENERATE_PAYOFF_PLANNER_PDF}>
          <V3PayoffPlannerPDF />
        </Route>

        <Route exact path={GENERATE_COLLECTION_LIST}>
          <V2CollectionListPDF />
        </Route>
        <Route exact path={V3_GENERATE_COLLECTION_LIST}>
          <V3CollectionListPDF />
        </Route>
        <Route path="/admin">
          <div>
            <Navigation />
          </div>
        </Route>
        <Route
          path="/"
          component={() => {
            window.location = "https://www.getfreebird.app";
            return null;
          }}
        />
      </Switch>
    </Router>
  );
};

export default App;
