import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  contentStyle: {
    justifyContent: "center",
    alignItems: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function OutlinedCard(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Typography variant="h6" component="h2">
            {props.items[0].title}
          </Typography>
          <Typography variant="h4" className={classes.pos} color="secondary">
            {props.items[0].value}
          </Typography>
        </Paper>
      </Grid>
      {props.items[1] && (
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h2">
              {props.items[1].title}
            </Typography>
            <Typography variant="h4" className={classes.pos} color="secondary">
              {props.items[1].value}
            </Typography>
          </Paper>
        </Grid>
      )}
      {props.items[2] && (
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h2">
              {props.items[2].title}
            </Typography>
            <Typography variant="h4" className={classes.pos} color="secondary">
              {props.items[2].value}
            </Typography>
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  );
}
