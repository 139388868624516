import "../../styles/payoffPlanner/payoffPlanMonthTable.css";

import React from "react";
import { map } from "lodash";

const renderTableHeader = () => {
  return (
    <tr>
      <th>Check</th>
      <th>Loan Name</th>
      <th>Amount Paid</th>
      <th>Towards Principal</th>
      <th>Towards Interest</th>
      <th>Status</th>
    </tr>
  );
};

const renderRow = ({ payment, index }) => {
  return (
    <tr key={index}>
      <td style={{ width: "5%" }}>
        <input type="checkbox" checked={payment.isPaid} className="checkbox" />
      </td>
      <td className="textLeft" style={{ width: "35%" }}>
        {payment.name}
      </td>
      <td className="amount" style={{ width: "15%" }}>
        {payment.formattedAmount}
      </td>

      <td className="amount" style={{ width: "15%" }}>
        {payment.towardsPrincipal}
      </td>
      <td className="amount" style={{ width: "15%" }}>
        {payment.towardsInterest}
      </td>
      <td
        className={`${payment.isPaid ? "paidStatus" : "defaultStatus"}`}
        style={{ width: "15%" }}
      >
        {payment.isPaid ? "Paid" : "To be paid"}
      </td>
    </tr>
  );
};

export const PayoffPlanMonthTable = ({
  section: { data, title, monthTotalAmount },
}) => {
  return (
    <div>
      <div className="paymentsReceived">
        <div className="paymentData">
          <table>
            <caption>{title}</caption>
            <thead>{renderTableHeader()}</thead>
            <tbody>
              {map(data, (payment, index) => {
                return renderRow({ payment, index });
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={6}>Total Payable = {monthTotalAmount}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};
